import * as actionTypes from "../types/charity";

const initialState = {
  transactions: [],
  transactions_error: "",
  share_analytics: null,
  share_analytics_error: false,
  post_share_link: null,
  post_share_link_error: null,
  all_email_admin_data: [],
  all_email_admin_data_error: false,
  post_email_admin_data: {},
  post_email_admin_data_error: false,
  post_contact: [],
  post_contact_error: false,
  email_address_domain: {},
  email_address_domain_error: false,
  organization_by_state: [],
  deleted_contact_info: {},
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.TRANSACTION_FULFILLED:
      return {
        ...state,
        transactions: action.payload,
      };
    case actionTypes.TRANSACTION_ERROR:
      return {
        ...state,
        transactions_error: action.payload,
      };
    case actionTypes.SHARE_ANALYTICS_FULFILLED:
      return {
        ...state,
        share_analytics: action.payload,
      };
    case actionTypes.SHARE_ANALYTICS_ERROR:
      return {
        ...state,
        share_analytics_error: action.payload,
      };
    case actionTypes.POST_SHARE_LINK_FULFILLED:
      return {
        ...state,
        post_share_link: action.payload,
      };
    case actionTypes.POST_SHARE_LINK_CLEARED:
      return {
        ...state,
        post_share_link: null,
      };
    case actionTypes.POST_SHARE_LINK_ERROR:
      return {
        ...state,
        post_share_link_error: action.payload,
      };
    case actionTypes.GET_EMAIL_ANALYTICS_FULFILLED:
      return {
        ...state,
        all_email_admin_data: action.payload,
      };
    case actionTypes.GET_EMAIL_ANALYTICS_ERROR:
      return {
        ...state,
        all_email_admin_data_error: true,
      };

    case actionTypes.POST_EMAIL_ANALYTICS_FULFILLED:
      return {
        ...state,
        post_email_admin_data: action.payload,
      };
    case actionTypes.POST_EMAIL_ANALYTICS_ERROR:
      return {
        ...state,
        post_email_admin_data_error: true,
      };
    case actionTypes.POST_CONTACT_FULFILLED:
      return {
        ...state,
        post_contact: action.payload,
      };
    case actionTypes.POST_CONTACT_ERROR:
      return {
        ...state,
        post_contact_error: true,
      };
    case actionTypes.CLEAR_POST_CONTACT_FULFILLED:
      return {
        ...state,
        post_contact: {},
      };
    case actionTypes.GET_EMAIL_ADDRESSES_FULFILLED:
      return {
        ...state,
        email_address_domain: action.payload,
      };
    case actionTypes.GET_EMAIL_ADDRESSES__ERROR:
      return {
        ...state,
        email_address_domain_error: true,
      };
    case actionTypes.GET_ORGANIZATION_BY_STATE_FULFILLED:
      return {
        ...state,
        organization_by_state: action.payload,
      };
    case actionTypes.DELETE_CONTACT_FULFILLED:
      return {
        ...state,
        deleted_contact_info: action.payload,
      };
    case actionTypes.DELETE_CONTACT_FULFILLED:
      return {
        ...state,
        deleted_contact_info_error: true,
      };
    default:
      return state;
  }
};
