import "./styles/home.css";

import Coupon from "../components/coupon/Coupon";
import H1 from "../components/text/H1";
import Hoc from "../hoc/Hoc";
import React from "react";

function Coupons() {
  return (
    <>
      <div className="about-container">
        <div className="home-child-container about-text">
          <H1 text={`Sale Discounts & Deals`} text_color={"#fff"} />
        </div>
      </div>
      <Coupon />
    </>
  );
}

export default Hoc(Coupons);
