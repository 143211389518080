import "./Component.css";

import React from "react";

interface IContainer {
  class_name: string;
  children: React.ReactNode;
}

const ChildContainer: React.FC<IContainer> = ({ class_name, children }) => {
  return <div className={`child-container ${class_name}`}>{children}</div>;
};

export default ChildContainer;
