export const createEmailTemplate = (link, organizationName, platform) => {
  let greeting;
  switch (platform) {
    case "MailChimp":
      greeting = "*|IF:FNAME|*Hello *|FNAME|*,*|ELSE:|*Hello there,*|END:IF|*";
      break;
    case "ConstantContact":
      greeting = "Hello [Contact.FirstName:Fallback=there],";
      break;
    case "SendGrid":
      greeting =
        "{{#if FirstName}}Hello {{FirstName}},{{else}}Hello there,{{/if}}";
      break;
    case "Gmail":
      greeting = "Hello,";
      break;
    default:
      greeting = "Hello,";
  }

  return `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style>
        body {
            font-family: Arial, sans-serif;
        }
        table {
            width: 100%;
            max-width: 600px;
            margin: auto;
            border-collapse: collapse;
        }
        td, th {
            padding: 10px;
            text-align: left;
        }
        .header {
            background-color: #f8f8f8;
            text-align: center;
            padding: 20px;
        }
        .content {
            padding: 20px;
        }
        h3 {
            font-size: 20px;
        }
        p {
            font-size: 16px;
        }
        .footer {
            text-align: center;
            padding: 20px;
        }
        .button {
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            width: 200px;
            padding: 7px;
            background-color: #6b21a8;
            border-radius: 10px;
            text-align: center;
            display: block;
            margin: 10px auto;
            text-decoration: none;
        }
        li {
            margin: 5px 0px;
            font-size: 16px;
        }
    </style>
</head>
<body>
    <table>
        <tr>
            <td class="content">
                <h3 style="font-size: 20px">How would you like to save money while generating revenue for our fundraiser?</h3>
                <p style="font-size: 16px">${greeting}</p>
                <p style="font-size: 16px">
                    We are excited to introduce <strong>Many Hands</strong>, an AI-driven
                    Chrome extension that turns your everyday online shopping into a
                    powerful fundraising tool for our cause. Simply by shopping, you help
                    generate funds for our organization while enjoying savings.
                </p>
                <h3 style="font-size: 20px">Why Many Hands?</h3>
                <ul>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>AI-Driven Savings:</strong> Access the best deals and
                        discounts with our AI-powered search tools.
                    </li>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>Support Our Cause:</strong> Automatically direct 80% of
                        affiliate revenue from your purchases to us.
                    </li>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>Privacy and Security:</strong> Shop with confidence—your
                        personal information is safe; Many Hands neither sells nor stores your
                        data.
                    </li>
                </ul>
                <h3 style="font-size: 20px">Get Started Now:</h3>
                <ul>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>Install the Extension:</strong> Easily add Many Hands to
                        Chrome <a href="${link}" class="button">here</a>.
                    </li>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>Select Us as Your Charity:</strong> We're pre-selected as your
                        beneficiary to ensure you start supporting us right away.
                    </li>
                    <li  style="margin: 5px 0px; font-size: 16px">
                        <strong>Shop and Make a Difference:</strong> Each purchase or
                        extension link click supports our initiatives.
                    </li>
                </ul>
                <p style="font-size: 16px">
                    Thank you for making a significant impact just by shopping online!
                </p>
                <p style="font-size: 16px">Best regards,</p>
                <p style="font-size: 16px">
                    <strong>${organizationName}</strong>
                </p>
            </td>
        </tr>
        <tr>
            <td class="footer">
                © 2024 Many Hands. All rights reserved.
            </td>
        </tr>
    </table>
</body>
</html>

  `;
};
