import "./Sections.css";

import Download from "../../static/icons/download.svg";
import H2 from "../text/H2";
import Heart from "../../static/icons/charity-how-to.svg";
import React from "react";
import Shopping from "../../static/icons/shopping.svg";

function HowTo() {
  const arr = [
    {
      title: "DownLoad Extension",
      image: Download,
      description:
        "Download the Many Hands Chrome extension from the Google Chrome store",
    },
    {
      title: "Shop Normally",
      image: Shopping,
      description:
        "Shop normally and the extension will alert you to discounts and deals",
    },
    {
      title: "Money To Charities",
      image: Heart,
      description:
        "80% of the total commission goes directly to the charities of your choice",
    },
  ];

  const textHandler = (ele: any, idx: number) => (
    <div key={idx} className="how-to-icon-container">
      <img src={ele.image} alt={ele.title.split(" ")[0]} className="how-to-icon-svg" />
      <H2
        className="how-to-icon-container-text top"
        text={ele.title.split(" ")[0]}
        text_main={"var(--dark-green)"}
        text_secondary={"var(--color-secondary)"}
      />
      <H2
        className="how-to-icon-container-text bottom"
        text={
          ele.title.split(" ")[2] !== undefined
            ? `${ele.title.split(" ")[1]} ${ele.title.split(" ")[2]}`
            : ele.title.split(" ")[1]
        }
        text_main={"var(--dark-green)"}
        text_secondary={"var(--color-secondary)"}
      />
      <p style={{ color: "#fff", textAlign: "center" }}>{ele.description}</p>
    </div>
  );

  return (
    <div className="how-to-wrapper">
      <H2
        className="how-to-title-text"
        text={`How it works`}
        text_main={"var(--dark-green)"}
        text_secondary={"var(--color-secondary)"}
      />
      <div className="how-to-container">
        {arr.map((ele: any, idx: number) => textHandler(ele, idx))}
      </div>
    </div>
  );
}
export default HowTo;
