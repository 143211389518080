import * as React from "react"
const SvgComponent = ({graph}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    style={{
      boxShadow: "beige",
    }}
    viewBox="0 0 52.917 52.917"
    // {...props}
  >
    <g transform="translate(-37.724 -113.232)">
      <rect
        width={52.917}
        height={52.917}
        x={37.724}
        y={113.232}
        rx={7.889}
        ry={7.889}
        style={{
          fill: graph === "bar" ? "#BBBBBB" : "#e6e6e6",
          strokeWidth: 0.144,
        }}
      />
      <rect
        width={1.111}
        height={44.979}
        x={43.084}
        y={117.201}
        rx={0}
        ry={0}
        style={{
          fill: "#4d4d4d",
          strokeWidth: 0.540076,
        }}
      />
      <rect
        width={1.111}
        height={44.979}
        x={161.07}
        y={-88.063}
        rx={0}
        ry={0}
        style={{
          fill: "#4d4d4d",
          strokeWidth: 0.540076,
        }}
        transform="rotate(90)"
      />
      <g transform="translate(126.911 256.698)">
        <rect
          width={3.012}
          height={19.791}
          x={-81.689}
          y={-115.42}
          rx={0}
          ry={0}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 0.589943,
          }}
        />
        <rect
          width={3.012}
          height={25.019}
          x={-77.985}
          y={-120.647}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 0.663299,
          }}
        />
      </g>
      <g transform="translate(127.088 256.698)">
        <rect
          width={3.012}
          height={16.939}
          x={-72.693}
          y={-112.568}
          rx={0}
          ry={0}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 0.545791,
          }}
        />
        <rect
          width={3.012}
          height={18.84}
          x={-68.989}
          y={-114.469}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 0.575602,
          }}
        />
      </g>
      <g transform="translate(127.264 256.698)">
        <rect
          width={3.012}
          height={30.009}
          x={-63.697}
          y={-125.638}
          rx={0}
          ry={0}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 0.726443,
          }}
        />
        <rect
          width={3.012}
          height={22.405}
          x={-59.993}
          y={-118.034}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 0.627694,
          }}
        />
      </g>
      <g transform="translate(126.911 256.698)">
        <rect
          width={3.012}
          height={12.425}
          x={-54.172}
          y={-108.053}
          rx={0}
          ry={0}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 0.467431,
          }}
        />
        <rect
          width={3.012}
          height={36.662}
          x={-50.468}
          y={-132.291}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 0.802948,
          }}
        />
      </g>
    </g>
  </svg>
)
export default SvgComponent
