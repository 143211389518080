import React, { useEffect, useState } from "react";

import EmailBuilder from "./EmailShareBuilder";
import EmailCreator from "../../static/EmailCreatorSvg";
import Hoc from "../../hoc/Hoc";
import LinkBuilder from "./SocialShareLinkBuilder";
import LinkCreator from "../../static/linkCreatorSvg";
import Links from "./Links";
import LinksSvg from "../../static/LinkSvg";

interface ILink {
  postCharityShareAnalyticsClear: Function;
}
const Link: React.FC<ILink> = ({ postCharityShareAnalyticsClear }) => {
  const [createTab, setCreateTab] = useState("create");

  useEffect(() => {
    postCharityShareAnalyticsClear();
  }, [createTab]);

  return (
    <>
      <div className="organization-link-container">
        <div className="link-child--graph-container">
          {createTab === "create" && <LinkBuilder />}
          {createTab === "email" && <EmailBuilder />}
          {createTab === "view" && <Links />}
        </div>

        <div className="link-child--buttons-container">
          <div
            className="link-type-button"
            style={{
              boxShadow:
                createTab === "line"
                  ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                  : "none",
            }}
            onClick={() => setCreateTab("create")}
          >
            <LinkCreator createTab={createTab} />
            Create Link
          </div>
          <div
            className="link-type-button"
            style={{
              boxShadow:
                createTab === "line"
                  ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                  : "none",
            }}
            onClick={() => setCreateTab("email")}
          >
            <EmailCreator createTab={createTab} />
            Create Email Template
          </div>
          <div
            className="link-type-button"
            style={{
              boxShadow:
                createTab === "circle"
                  ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                  : "none",
            }}
            onClick={() => setCreateTab("view")}
          >
            <LinksSvg createTab={createTab} />
            View All Links
          </div>
        </div>
      </div>
    </>
  );
};

export default Hoc(Link);
