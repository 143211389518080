import "./new_animation.css";

import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

import ReactPlayer from "react-player";

interface IAISection {
  style: any;
  video: string;
  idx: number;
  speed: number;
  nextVideoHandler: Function;
}
const AISection: React.FC<IAISection> = ({
  video,
  style,
  idx,
  speed,
  nextVideoHandler,
}) => {
  const [isMounted, setIsMounted] = useState<string | null>(null);
  const [styles, setStyles] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef: any = useRef(null);

  const handleRestart = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(true);
      videoRef.current.play();
    }
  };

  useEffect(() => {
    const play_stop = idx === 0;
    setIsPlaying(play_stop);

    if (!play_stop) {
      handleRestart();
    } else {
      handleRestart();
    }
  }, [idx]);

  useEffect(() => {
    if (idx === 2) {
      setIsMounted("mounted_2");
    } else {
      setIsMounted("mounted");
    }
  }, []);

  useEffect(() => {
    if (idx === 2 && isMounted !== null) {
      setStyles([
        {
          position: style.position,
          width: style.width,
          top: style.top,
          left: "140%",
          zIndex: style.zIndex,
          transform: style.transform,
        },
        {
          position: style.position,
          width: style.width,
          top: style.top,
          left: style.left,
          zIndex: style.zIndex,
          transform: style.transform,
        },
      ]);
      setIsMounted("mounted");
    } else {
      setStyles([style]);
    }
  }, [idx]);

  const animation: any = useSpring({
    from: style,
    to: styles,
  });

  return (
    <animated.div style={animation}>
      <ReactPlayer
        className="ai-video"
        playing={isPlaying}
        muted={true}
        url={video}
        height="100%"
        width="auto"
        playbackRate={speed}
        onEnded={() => nextVideoHandler()}
      />
    </animated.div>
  );
};

export default AISection;
