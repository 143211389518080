import { CSSProperties } from "react";
import React from "react";

class MovingText extends React.Component {
  render() {
    const textStyle: CSSProperties = {
      whiteSpace: "nowrap",
      display: "flex",
      animation: "marquee 20s linear infinite",
      color: "rgb(194 200 210)",
    };

    const keyframesStyle = `
      @keyframes marquee {
        from { transform: translateX(0%); }
        to { transform: translateX(-100%); }
      }
    `;
    const elements = new Array(50).fill(null);
    return (
      <>
        <style>{keyframesStyle}</style>
        <div
          style={{ overflowX: "hidden", width: "100%", padding: "15px 0px" }}
        >
          <span style={textStyle}>
            {elements.map((_, index) => {
              if (index % 2 === 0) {
                return <h2 key={index}>We don't sell your data.&nbsp;</h2>;
              } else {
                return <h2 key={index}>We don't store your data.&nbsp;</h2>;
              }
            })}
          </span>
        </div>
      </>
    );
  }
}

export default MovingText;
