import "./styles/home.css";

import React, { useEffect } from "react";

import { apiRequest } from "../utils/fetch";

function Email() {
  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);

    const id = urlParams.get("id");
    const contact_id = urlParams.get("contact_id");
    // const redirect = urlParams.get("redirect");

    // const extension = `https://chrome.google.com/webstore/detail/many-hands-charity-extens/nhenigdkimmdialnedpnckefaioddald`;
    // const website = `https://www.manyhands.charity?contact_id=${contact_id}`;
    const website = `https://www.manyhands.charity/charity_landing_page?contact_id=${contact_id}`;

    // const url = redirect === "website" ? website : extension;

    apiRequest({
      method: "PATCH",
      url: `/analytics/email/website/${id}`,
    })
      .then(() => window.location.replace(website))
      .catch((error) => window.location.replace(website));
      
    window.location.replace(website);
  }, []);
  return <div></div>;
}

export default Email;
