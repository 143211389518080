import * as React from "react";
const SvgComponent = ({graph}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
    // {...props}
  >
    <g transform="translate(-290.677 103.225)">
      <circle
        cx={317.136}
        cy={-76.767}
        r={0.661}
        style={{
          fill: "red",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0372265,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <rect
        width={52.917}
        height={52.917}
        x={290.677}
        y={-103.225}
        rx={7.889}
        ry={7.889}
        style={{
          fill: graph === "circle" ? "#BBBBBB" : "#e6e6e6",
          strokeWidth: 0.144,
        }}
      />
      <circle
        cx={317.136}
        cy={-76.767}
        r={19.301}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1.08623,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M315.459-57.705a19.592 19.592 0 0 1-8.965-3.07c-.856-.56-1.833-1.317-1.806-1.4.013-.038 2.872-3.257 6.355-7.154l6.332-7.086 9.024-3.222c4.963-1.772 9.033-3.212 9.044-3.2.012.01.103.308.205.661.815 2.846.92 6.12.296 9.175-.541 2.649-1.78 5.375-3.452 7.603a19.397 19.397 0 0 1-11.005 7.231c-1.886.452-4.083.62-6.028.462z"
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.233675,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
    </g>
  </svg>
);
export default SvgComponent;
