import "./new_animation.css";

import React, { useEffect, useState } from "react";

import AIHeader from "./AI-Header";
import AISection from "./AI-Animation-Section";
import ChildContainer from "../../containers/ChildContainer";

const AIContainer = () => {
  const [index, setIndex] = useState(0);
  const [positions, setPositions] = useState({
    one: 0,
    two: 1,
    three: 2,
  });

  const handlerUserIndexSelect = (idx: number) => {
    setIndex(idx);
  };

  const nextVideoHandler = () => {
    const idx = index === 2 ? 0 : index + 1;
    setIndex(idx);
  };

  useEffect(() => {
    const newPositions = [
      { one: 0, two: 1, three: 2 },
      { one: 2, two: 0, three: 1 },
      { one: 1, two: 2, three: 0 },
    ][index];
    setPositions(newPositions);
  }, [index]);

  const styleArray = [
    {
      position: "absolute",
      zIndex: "20",
      width: "auto",
      top: "50%",
      left: "42%",
      transform: "translate(-50%, -50%)",
    },
    {
      position: "absolute",
      zIndex: "19",
      width: "auto",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    {
      position: "absolute",
      zIndex: "18",
      width: "auto",
      top: "50%",
      left: "58%",
      transform: "translate(-50%, -50%)",
    },
  ];

  const sections = [
    {
      video: "https://media.manyhands.charity/Brand.mp4",
      style: styleArray[positions.one],
      idx: positions.one,
      speed: 0.5,
      prompt: "Brand",
      h2: "Search",
      h2_second: "By Brand",
      h3: "Input the brand name in the Chrome extension to find discounts. Instantly apply discounts at checkout for immediate savings.",
    },
    {
      video: "https://media.manyhands.charity/Product.mp4",
      style: styleArray[positions.two],
      idx: positions.two,
      speed: 2,
      prompt: "Product",
      h2: "Search",
      h2_second: "By product",
      h3: "Search for discounts by product name or type. It lists available deals and coupons for specific items. Instantly apply discounts at checkout for immediate savings.",
    },
    {
      video: "https://media.manyhands.charity/Similar.mp4",
      style: styleArray[positions.three],
      idx: positions.three,
      speed: 1.5,
      prompt: "Similar",
      h2: "Search",
      h2_second: "By similar products",
      h3: "Search for discounts by product name or type. It lists available deals and coupons for specific items. Instantly apply discounts at checkout for immediate savings.",
    },
  ];

  const headerPrompts = sections.map((section) => section.prompt);

  return (
    <div className="ai-container" style={{ background: "#f8fafc" }}>
      <div className="ai-content-container">
        <div className="ai-text-container">
          <AIHeader
            prompts={headerPrompts}
            index={index}
            setIndex={handlerUserIndexSelect}
          />
        </div>

        <ChildContainer class_name="ai-video-container">
          {sections.map((section, key) => (
            <AISection
              key={key}
              {...section}
              nextVideoHandler={nextVideoHandler}
            />
          ))}
        </ChildContainer>
      </div>
    </div>
  );
};

export default AIContainer;
