import * as actionTypes from "../types/coupons";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const getStoreNames = () => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/coupons/store-names`,
      method: "get",
      successType: actionTypes.GET_STORE_NAMES,
      errorType: actionTypes.GET_STORE_NAMES_ERROR,
      dispatch,
    });
  };
};

export const getAllCouponsForBrand = (brand: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/coupons/store-coupons/${brand}`,
      method: "get",
      successType: actionTypes.GET_STORE_DISCOUNTS,
      errorType: actionTypes.GET_STORE_DISCOUNTS_ERROR,
      dispatch,
    });
  };
};

export const getTrendingStores = (brand: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/coupons/mystore`,
      method: "post",
      data: ["amazon.com", "walmart.com", "bestbuy.com", "macys.com"],
      successType: actionTypes.GET_TRENDING_STORE,
      errorType: actionTypes.GET_TRENDING_STORE_ERROR,
      dispatch,
    });
  };
};

export const setValue = (value: string) => ({
  type: actionTypes.SET_VALUE,
  payload: value,
});
