import "./styles/home.css";

import React, { useEffect, useState } from "react";

function Redirect() {
  const [link, setLink] = useState("");

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);

    const u = urlParams.get("url")?.split("u=")[1];
    const k = urlParams.get("key");
    const cuid = urlParams.get("cuid");

    const encoded = encodeURIComponent(u);
    const complete_url = `https://redirect.viglink.com/?u=${encoded}&key=${k}&cuid=${cuid}`;

    setLink(complete_url);
  }, []);

  useEffect(() => {
    redirect();
  }, [link]);

  // const redirect = () =>
  //   setTimeout(() => {
  //     document.getElementById("redirect_link").click();
  //   }, 2500);

  const redirect = () => document.getElementById("redirect_link").click();

  return <a id="redirect_link" href={link}></a>;
}

export default Redirect;
