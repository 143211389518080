import "./tutorials.css";

import React, { useEffect, useState } from "react";

import Bar from "../../../static/bar.jsx";
import DemoVideo from "../DemoVideo";
import LinksSvg from "../../../static/LinkSvg";
import PartnershipSvg from "../../../static/PartnershipSVG";

const OrganizationSignUp = () => {
  const [createTab, setCreateTab] = useState("bar");
  const [text, setText] = useState("bar");

  useEffect(() => {
    switch (createTab) {
      case "bar":
        setText("Analytics Tutorial");
        break;

      case "view":
        setText("Links Tutorial");
        break;

      case "partnership":
        setText("Partnerships Tutorial");
        break;

      default:
        setText("Analytics Tutorial");
    }
  }, [createTab]);

  return (
    <div className="organization-link-container">
      <div className="tutorial-video-text-container">
        <h2>{text}</h2>
        <div className="tutorial-container">
          {createTab === "bar" && (
            <DemoVideo
              autoPlay={false}
              loop={false}
              video={`videos/analytics_rakzpm`}
            />
          )}
          {createTab === "view" && (
            <DemoVideo
              autoPlay={false}
              loop={false}
              video={`videos/links_email_rfri6l`}
            />
          )}
          {createTab === "partnership" && (
            <DemoVideo
              autoPlay={false}
              loop={false}
              video={`videos/partnership_qwkscv`}
            />
          )}
        </div>
      </div>

      <div className="link-child--buttons-container">
        <div
          className="link-type-button"
          style={{
            boxShadow:
              createTab === "line"
                ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                : "none",
          }}
          onClick={() => setCreateTab("bar")}
        >
          <Bar graph={createTab} />
          Analytics Tutorial
        </div>
        <div
          className="link-type-button"
          style={{
            boxShadow:
              createTab === "line"
                ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                : "none",
          }}
          onClick={() => setCreateTab("view")}
        >
          <LinksSvg createTab={createTab} />
          Links Tutorial
        </div>
        <div
          className="link-type-button"
          style={{
            boxShadow:
              createTab === "circle"
                ? "0px 0px 5px 4px rgba(0, 0, 0, 0.31)"
                : "none",
          }}
          onClick={() => setCreateTab("partnership")}
        >
          <PartnershipSvg createTab={createTab} />
          Partnerships Tutorial
        </div>
      </div>
    </div>
  );
};

export default OrganizationSignUp;
