import "../style.css";
import "./estimate.css";

import React, { useEffect, useState } from "react";

import Button from "../../../button/SignUp";
import Slider from "@mui/material/Slider";

const RevenueEstimate = () => {
  const [purchaseAmount, setPurchaseAmount] = useState(100); // Starting amount is 3 purchases of $125
  const [supporters, setSupporters] = useState(10); // Initial number of supporters
  const [partnerSupporters, setPartnerSupporters] = useState(0); // Initial number of supporters from partner
  const [partnerPercentage, setPartnerPercentage] = useState(0); // Initial partner percentage
  const [partnerCount, setPartnerCount] = useState(0); // Track number of partners
  const [partnerAdded, setPartnerAdded] = useState(false); // Track if partner is added

  useEffect(() => {
    if (partnerCount === 0) {
      setPartnerAdded(false);
    }
  }, [partnerCount]);

  const handleSliderChange = (event: any, newValue: any) => {
    setPurchaseAmount(newValue);
  };

  const handlePartnerPercentageChange = (event: any, newValue: any) => {
    setPartnerPercentage(newValue);
  };

  const incrementPartner = () => {
    const newPartnerSupporters = (partnerCount + 1) * supporters;
    setPartnerSupporters(newPartnerSupporters);
    setPartnerCount((prevCount) => prevCount + 1);
    setPartnerAdded(true);
  };

  const decrementPartner = () => {
    if (partnerCount > 0) {
      const newPartnerSupporters = (partnerCount - 1) * supporters;
      setPartnerSupporters(newPartnerSupporters);
      setPartnerCount((prevCount) => prevCount - 1);
    }
  };

  const calculateAnnualContribution = (supporters: any) => {
    const annualContribution = supporters * (purchaseAmount * 12) * 0.05 * 0.8; // 5% contribution rate and 80% for nonprofits
    return annualContribution;
  };

  const calculatePartnerShare = (
    supporters: any,
    partnerPercentage: number
  ) => {
    const contribution = calculateAnnualContribution(supporters);
    const partnerShare = contribution * (partnerPercentage / 100);
    return partnerShare;
  };

  const supporterLevels = [10, 50, 100, 500, 1000, 10000];

  return (
    <div className="revenue-parent primary-component-container">
      <div className="secondary-flex-50 top-secondary-flex revenue-text-container">
        <div className="text-wrapper tertiary-text">
          <h3 style={{ color: "var(--dark-green)" }}>PROJECTED ANNUAL</h3>
          <h2 style={{ color: "var(--color-primary)" }}>
            Revenue for organizations
          </h2>
          <p style={{ color: "var(--dark-dark-gray)" }}>
            Base on number of supports.
          </p>

          {/* <div style={{ marginTop: "20px" }}>
            <Slider
              value={purchaseAmount}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={0}
              max={1000}
              step={25}
              valueLabelDisplay="auto"
              sx={{ width: 300 }}
            />
          </div> */}

          {/* <div style={{ height: "120px", position: "relative" }}>
            {partnerAdded && (
              <div style={{ marginTop: "20px", position: "absolute" }}>
                <h4>Partner Percentage: {partnerPercentage}%</h4>
                <Slider
                  value={partnerPercentage}
                  onChange={handlePartnerPercentageChange}
                  aria-labelledby="partner-percentage-slider"
                  min={0}
                  max={100}
                  step={5}
                  valueLabelDisplay="auto"
                  sx={{
                    width: 300,
                    color: "#6b21a8", // Change the color of the slider (track + thumb)
                    "& .MuiSlider-track": {
                      bgcolor: "#6b21a8", // Change the color of the track
                    },
                    "& .MuiSlider-thumb": {
                      bgcolor: "#6b21a8", // Change the color of the thumb
                    },
                    "& .MuiSlider-rail": {
                      bgcolor: "#6b21a8", // Change the color of the rail
                    },
                  }}
                />
              </div>
            )}
          </div> */}

          {/* <div
            style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            <button
              onClick={decrementPartner}
              style={{
                padding: "10px 20px",
                backgroundColor: "var(--color-secondary)",
                color: "white",
                border: "none",
                borderRadius: "50%",
                marginRight: "10px",
                fontSize: "25px",
              }}
            >
              -
            </button>
            <span style={{ fontSize: "25px" }}>{partnerCount}</span>
            <button
              onClick={incrementPartner}
              style={{
                padding: "10px 20px",
                backgroundColor: "var(--color-secondary)",
                color: "white",
                border: "none",
                borderRadius: "50%",
                marginLeft: "10px",
                fontSize: "25px",
              }}
            >
              +
            </button>
          </div> */}
          <Button
            text={`Start Fundraising`}
            link={"/fundraiser-sign-up"}
            main_color={"var(--color-primary)"}
            secondary_color={"var(--dark-green)"}
          />
        </div>
      </div>

      <div className="secondary-flex-50 revenue-container-picture-wrapper">
        <div>
          <table className="contribution-table">
            <thead>
              <tr>
                <th>Supporters</th>
                {partnerAdded && (
                  <th className="partner-supporters">Partner Supporters</th>
                )}
                {partnerAdded && (
                  <th className="partner-share">
                    Partner Share ({partnerPercentage}%)
                  </th>
                )}
                <th>Annual Contribution</th>
              </tr>
            </thead>
            <tbody>
              {supporterLevels.map((level) => {
                const totalSupporters = level + partnerCount * level;
                const partnerShare = partnerAdded
                  ? calculatePartnerShare(
                      partnerCount * level,
                      partnerPercentage
                    )
                  : 0;
                const annualContribution =
                  calculateAnnualContribution(totalSupporters) - partnerShare;
                return (
                  <tr key={level}>
                    <td>{totalSupporters} Supporters</td>
                    {partnerAdded && (
                      <td className="partner-supporters">
                        {partnerCount * level} Supporters
                      </td>
                    )}
                    {partnerAdded && (
                      <td className="partner-share">
                        {partnerShare.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    )}
                    <td>
                      {annualContribution.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="note">
            Annual contribution is based on active supporters making one
            purchases per month, averaging ${purchaseAmount.toFixed(2)} per
            transaction with a 5% contribution rate. Nonprofits receive 80% of
            the total contribution, as shown in the table. Results may vary.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueEstimate;
