import Click from "./click";
import Purchase from "./purchase";
import React from "react";
const Container = () => {
  return (
    <div className="revenue-generated-container">
      <Click
        h3={"EARN BY USER"}
        h2={"Link clicks"}
        p={` When a user clicks a link provided by Many Hands Extension, the
            click commission average is 21¢, but it can be as high as $1.`}
        image={
          "https://media.manyhands.charity/static/many-hands-assets/click_layout.svg"
        }
      />
      <Purchase
        h3={"EARN BY USER"}
        h2={"Purchases"}
        p={` After a user clicks a link and makes a purchase, the average
        commission is 8%, but it can be as high as 20%.`}
        image={
          "https://media.manyhands.charity/static/many-hands-assets/shoe.png"
        }
      />
    </div>
  );
};

export default Container;
