import "../../pages/styles/home.css";

import React, { useEffect, useState } from "react";

import Autocomplete from "../../components/autocomplete/Autocomplete";
import Close from "../close/Close";
import FullWidthCard from "../../components/card/FullWidthCard";
import Hoc from "../../hoc/Hoc";
import TrendingStores from "../../components/sections/TrendingStores";

function Coupons(Props: any) {
  const [category, setCategory] = useState<any>(null);

  const { getStoreNames, getAllCouponsForBrand, coupons, setValue } = Props;
  const { store_names, store_discounts, value } = coupons;

  useEffect(() => {
    getStoreNames();
  }, []);

  useEffect(() => {
    if (value !== "" && value !== null) {
      const formattedValue = `${value.toLowerCase()}.com`;
      getAllCouponsForBrand(formattedValue);
    }
  }, [value]);

  const formatStoreName = (name: string) =>
    name.charAt(0).toUpperCase() + name.slice(1);

  const categoriesFilter = store_discounts.map((ele: any) => ele.categories);

  const brandCategories = [
    ...new Set(
      categoriesFilter.filter((category: string) => category.length > 0)
    ),
  ];

  const selectedCategory = store_discounts.filter((deal: any) =>
    category === null ? deal : deal.categories === category
  );

  const valueHandler = (value: string | null) => {
    setValue(value);
    setCategory(null);
  };

  return (
    <>
      <div className="faq-container">
        <div
          style={{
            color: "var(--white)",
            background: "var(--dark-green)",
            border: "4px solid var(--white)",
            boxShadow: "-10px -10px 0px 0px var(--color-primary)",
            borderRadius: "20px",
            padding: "20px 20px 10px 20px",
          }}
        >
          {value === null ? (
            <div style={{ backgroundColor: "var(--white)" }}>
              <Autocomplete
                data={store_names}
                value={
                  !value ? value : formatStoreName(value).replace(".com", "")
                }
                setValue={valueHandler}
                label={"Type Store Name"}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: "#ff0000",
                  color: "#fff",
                  padding: "4px 8px",
                  marginBottom: "10px",
                }}
                onClick={() => valueHandler(null)}
              >
                <Close />
                &nbsp;&nbsp;{value.toUpperCase()}
              </div>

              {category !== null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    backgroundColor: "#ff0000",
                    color: "#fff",
                    padding: "4px 8px",
                    marginBottom: "20px",
                  }}
                  onClick={() => setCategory(null)}
                >
                  <Close />
                  &nbsp;&nbsp;{category}
                </div>
              )}
              <div style={{ backgroundColor: "var(--white)" }}>
                <Autocomplete
                  data={brandCategories}
                  value={category}
                  setValue={setCategory}
                  label={"Select Category"}
                />
              </div>
              <br />
            </>
          )}
          <div
            style={{
              minHeight: " 44vh",
              maxHeight: " 54vh",
              overflow: "scroll",
            }}
          >
            {selectedCategory.length > 0 && value !== null ? (
              selectedCategory.map((deal: any, idx: number) => (
                <FullWidthCard key={idx} data={deal} />
              ))
            ) : (
              <TrendingStores />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Hoc(Coupons);
