import React from "react";

interface Webpage {
  className?: string;
  text_main: string;
  text_secondary?: string;
  text: string;
  margin_top?: string;
}

const H2: React.FC<Webpage> = ({
  className,
  text_main,
  text_secondary = `transparent`,
  text,
}) => {
  const testHandler = (text: string) => (
    <div className={className}>
      <h2
        style={{
          color: text_main,
          textShadow: `-3px -4px 0px ${text_secondary}`,
        }}
      >
        {text}
      </h2>
    </div>
  );
  return testHandler(text);
};

export default H2;
