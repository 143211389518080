import "./organizationSignUp.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Hoc from "../../../../hoc/OrganizationSignUpHoc";
import { getCookie } from "../../../../utils/cookie";
import { states } from "../../../../utils/charity";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "transparent !important", // Ensures the background is transparent
    "& fieldset": {
      borderColor: "#00ffa4",
    },
    "&:hover fieldset": {
      borderColor: "#00ffa4",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#00ffa4",
    },
  },
  "& .MuiInputBase-input": {
    color: "#fff",
    backgroundColor: "transparent", // Ensures input background remains transparent
  },
  "& .MuiInputLabel-root": {
    color: "#fff",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: theme.palette.error.main,
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#fff",
  },
  "& .MuiFormHelperText-root": {
    color: "#fff",
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#00ffa4",
  color: "#000",
  "&:hover": {
    backgroundColor: "#00e594",
  },
  "&:disabled": {
    backgroundColor: "rgba(0, 199, 164, 0.2)",
    color: "var(--color-primary)",
  },
}));

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00ffa4",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00ffa4",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00ffa4",
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    color: "#fff",
  },
}));

interface IOrganizationSignUp {
  createOrganizationUsernamePassword: Function;
  createOrganizationInformationNoUuid: Function;
  OrganizationSignUp: IUsername;
}

const OrganizationSignUp: React.FC<IOrganizationSignUp> = ({
  createOrganizationUsernamePassword,
  createOrganizationInformationNoUuid,
  OrganizationSignUp,
}) => {
  const {
    uuid,
    signup_error,
    signup_error_message,
    organization_error,
    organization_information,
    organization_error_message,
  } = OrganizationSignUp;

  const [cookie, setCookie] = useState<string>("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState<string | null>(null);
  const [organizationType, setOrganizationType] = useState("");
  const [state, setState] = useState("");
  const [step, setStep] = useState(1);
  const [imageError, setImageError] = useState<boolean>(false);

  const steps = ["Email & Password", "Additional Information"];

  useEffect(() => {
    if (organization_information?.user_organization_id?.length > 0) {
      setStep(3);
    } else if (cookie) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [organization_information, cookie]);

  useEffect(() => {
    if (signup_error) {
      if (signup_error_message === "Email address already exists.") {
        resetStep1Fields();
      } else if (organization_information?.user_organization_id?.length > 0) {
        setStep(3);
      }
    }
  }, [signup_error, organization_information]);

  const resetStep1Fields = () => {
    setStep(1);
    setEmail("");
    setPassword("");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file: any = event.target.files?.[0];

      if (!file) {
        throw new Error("No file selected.");
      }

      if (!file.type.startsWith("image")) {
        throw new Error("Please select an image file.");
      }

      if (file.size >= 75966) {
        setImageError(true);
        throw new Error("Logo Image File Too Large");
      }

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result && typeof e.target.result === "string") {
          setLogo(e.target.result);
          setImageError(false);
        } else {
          throw new Error("File could not be read.");
        }
      };

      reader.onerror = () => {
        throw new Error("An error occurred while reading the file.");
      };

      reader.readAsDataURL(file);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleNext = () => {
    if (step === 1 && isValidEmail(email) && isValidPassword(password)) {
      setStep(2);
      createOrganizationUsernamePassword({
        email,
        password,
        account_type: "charity",
      });
    } else if (step === 2 && isValidFormStep2()) {
      handleOrganizationSubmit();
    }
  };

  const handleOrganizationSubmit = () => {
    const data = { title, description, organizationType, state, logo };
    const id = cookie || uuid;
    createOrganizationInformationNoUuid(id, data);
  };

  const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
  const isValidPassword = (password: string) => password.length >= 8;
  const isValidFormStep2 = () =>
    title.trim() !== "" &&
    description.trim() !== "" &&
    organizationType !== "" &&
    state !== "" &&
    !imageError &&
    logo !== null;

  const formFieldsStep1 = [
    {
      id: "email",
      label: "Email",
      type: "email",
      value: email,
      error: !isValidEmail(email) && email.length > 0,
      helperText:
        !isValidEmail(email) && email.length > 5
          ? "Invalid email (e.g., address@gmail.com)"
          : "",
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setEmail(e.target.value),
    },
    {
      id: "password",
      label: "Password",
      type: "password",
      value: password,
      error: !isValidPassword(password) && password.length > 3,
      helperText: "Password length must be greater than 8",
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(e.target.value),
    },
  ];

  const formFieldsStep2 = [
    {
      id: "title",
      label: "Organization Name",
      type: "text",
      value: title,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setTitle(e.target.value),
    },
    {
      id: "description",
      label: "Organization Description",
      type: "text",
      multiline: true,
      rows: 4,
      value: description,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setDescription(e.target.value),
    },
  ];

  return (
    <div className="organization-sign-up-container">
      <div className="organization-sign-up-wrapper secondary-flex-50">
        {step == 1 && (
          <>
            <img
              className="organization-welcome-logo"
              src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/logo.svg"
              alt="logo"
            />
            <p style={{ color: "#fff", fontWeight: "600" }}>
              Get Started with Many Hands Fundraiser
            </p>
          </>
        )}
        {signup_error || organization_error || imageError ? (
          <div className="organization-sign-up-error">
            {signup_error && (
              <>
                <h3>{signup_error_message}</h3>
                <a
                  href={
                    signup_error_message === "Email address already exists."
                      ? "/login"
                      : "mailto:info@manyhands.charity?subject=Sign%20in%20error&body=I'm%20experiencing%20a%20sign%20in%20error,%20Name:%20,%20Email:%20"
                  }
                >
                  <h3>
                    {signup_error_message === "Email address already exists."
                      ? "Login"
                      : "Report"}
                  </h3>
                </a>
              </>
            )}
            {(organization_error || imageError) && (
              <h5 style={{ fontWeight: "400" }}>
                {imageError
                  ? "Logo image file size too large"
                  : organization_error_message}
              </h5>
            )}
          </div>
        ) : step === 3 ? (
          <div className="organization-welcome-login">
            <img
              className="organization-welcome-logo"
              src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/logo.svg"
              alt="logo"
            />
            <div className="organization-welcome-login-text">
              <h3 style={{ color: "var(--dark-green)", marginBottom: "0px" }}>
                Welcome to
              </h3>
              <h3 style={{ color: "var(--dark-green)" }}>
                Many Hands Fundraiser
              </h3>
              <a
                className="button-ext-link-container"
                href="/login"
                style={{ borderColor: "var(--dark-green)", color: "#fff" }}
              >
                Log In
              </a>
            </div>
          </div>
        ) : null}

        {step === 1 && (
          <>
            {formFieldsStep1.map((field) => (
              <div
                key={field.id}
                className="organization-sign-up-textfield-wrapper"
              >
                <CustomTextField
                  type={field.type}
                  label={field.label}
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={field.error}
                  helperText={field.helperText}
                />
              </div>
            ))}
          </>
        )}

        {step === 2 && (
          <>
            {formFieldsStep2.map((field) => (
              <div
                key={field.id}
                className="organization-sign-up-textfield-wrapper"
              >
                <CustomTextField
                  type={field.type}
                  label={field.label}
                  multiline={field.multiline ?? false}
                  rows={field.rows ?? 1}
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              </div>
            ))}
            <div className="secondary-flex-50 select-signup-wrapper">
              <div className="secondary-flex-100">
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                    id="organization-type-label"
                    style={{ color: "#fff" }}
                  >
                    Organization Type
                  </InputLabel>
                  <CustomSelect
                    input={<CustomOutlinedInput label="Organization Type" />}
                    labelId="organization-type-label"
                    id="organization-type"
                    value={organizationType}
                    onChange={(event: any) =>
                      setOrganizationType(event.target.value)
                    }
                    IconComponent={ArrowDropDownIcon}
                  >
                    <MenuItem value="nonprofit">Non-Profit</MenuItem>
                    <MenuItem value="forprofit">For-Profit</MenuItem>
                    <MenuItem value="government">Government</MenuItem>
                  </CustomSelect>
                </FormControl>
              </div>
              <div className="secondary-flex-100">
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel
                    id="organization-state-label"
                    style={{ color: "#fff" }}
                  >
                    Organization Location
                  </InputLabel>
                  <CustomSelect
                    input={
                      <CustomOutlinedInput label="Organization Location" />
                    }
                    labelId="organization-state-label"
                    id="organization-state"
                    value={state}
                    onChange={(event: any) => setState(event.target.value)}
                    IconComponent={ArrowDropDownIcon}
                  >
                    {states.map((stateName) => (
                      <MenuItem key={stateName} value={stateName.toLowerCase()}>
                        {stateName}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </div>
            </div>
            <div className="upload-logo-container">
              <Input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }} // Ensure the input is hidden
              />
              <label htmlFor="contained-button-file">
                <CustomButton
                  variant="contained"
                  as="span"
                  style={{ padding: "10px", borderRadius: "4px" }}
                >
                  Upload Logo
                </CustomButton>
              </label>
              {logo ? (
                <img
                  src={logo}
                  alt="Uploaded"
                  className="organization-logo-upload"
                />
              ) : (
                <div className="organization-logo-upload-placeholder"></div>
              )}
            </div>
          </>
        )}

        {step !== 3 && (
          <CustomButton
            variant="contained"
            disabled={
              step === 1
                ? !isValidEmail(email) || !isValidPassword(password)
                : !isValidFormStep2()
            }
            onClick={handleNext}
            style={{
              padding: "10px",
              borderRadius: "4px",
              width: "50%",
              marginTop: "20px",
            }}
          >
            {step === 1 ? "Create Account" : "Submit"}
          </CustomButton>
        )}
      </div>
      <div className="organization-text-picture-wrapper secondary-flex-50">
        <img
          className="organization-heart-one"
          src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/hearts-signup-one.svg"
          alt="logo"
        />
        <div>
          <h2>"Doing something great</h2>
          <h2>&nbsp;&nbsp;with something small"</h2>
          <h3>&nbsp;&nbsp;&nbsp;-Many Hands Fundraiser</h3>
        </div>
        <img
          className="organization-heart-two"
          src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/hearts-signup-two.svg"
          alt="logo"
        />
      </div>
    </div>
  );
};

export default Hoc(OrganizationSignUp);
