import "./Header.css";

import React, { useEffect, useState } from "react";

import H4 from "../text/H4";

interface IHeader {
  setToggle: Function;
  path: string;
}
const Header: React.FC<IHeader> = ({ setToggle, path }) => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction: any = scrollY > lastScrollY ? "down" : "up";
      setScrollDirection(scrollY < 120 ? "up" : direction);
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  const pathName = window.location.pathname;
  const landingPage = pathName !== "/charity_landing_page";
  return (
    <header className="header">
      <div className="header-wrapper-desktop">
        <div className="header-logo-text">
          <a href="/">
            <img
              className="navbar-logo"
              src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/logo.svg"
              alt="logo"
            />
          </a>
          <a href="/">
            <h4
              style={{
                color:
                  path === "/"
                    ? "var(--color-secondary)"
                    : "var( --dark-green)",
              }}
            >
              Many Hands
            </h4>
          </a>
        </div>
        <nav>
          <ul className="ul">
            <li>
              <a
                className="a"
                href="/"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/" ? "var(--color-secondary)" : "transparent"
                  }`,
                }}
              >
                Extension
              </a>
            </li>
            <li>
              <a
                className="a"
                href="/charity_landing_page"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/charity_landing_page"
                      ? "var(--color-secondary)"
                      : "transparent"
                  }`,
                }}
              >
                Fundraising
              </a>
            </li>
            <li className="li">
              <a
                className="a"
                href="/about"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/about" ? "var(--color-secondary)" : "transparent"
                  }`,
                }}
              >
                About
              </a>
            </li>
            <li className="li">
              <a
                className="a"
                href="/faq"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/faq" ? "var(--color-secondary)" : "transparent"
                  }`,
                }}
              >
                FAQ
              </a>
            </li>
            <li className="li">
              <a
                className="a"
                href="/contact"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/contact"
                      ? "var(--color-secondary)"
                      : "transparent"
                  }`,
                }}
              >
                Contact
              </a>
            </li>
            {/* {landingPage && (
              <li className="li">
                <a
                  className="extensionLink"
                  target="_blank"
                  rel="noreferrer"
                  href="https://chrome.google.com/webstore/detail/many-hands-charity-extens/nhenigdkimmdialnedpnckefaioddald"
                >
                  Get Extension
                </a>
              </li>
            )} */}
            <li className="li">
              <a
                className="a"
                href="/login"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: `2px solid ${
                    path === "/login" ? "var(--color-secondary)" : "transparent"
                  }`,
                }}
              >
                Login
              </a>
            </li>
            <li className="li">
              <a className="extensionLink" href="/fundraiser-sign-up">
                Start Fundraising
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div
        className="header-wrapper-mobile"
        onClick={() => setToggle((prevState: boolean) => !prevState)}
      >
        <img
          className="navbar-logo"
          src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/menu-logo.svg"
          alt="logo"
        />
      </div>
    </header>
  );
};
export default Header;
