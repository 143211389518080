import "./new_animation.css";

import Button from "../../../components/button/Button";
import React from "react";

interface IAIHeader {
  prompts: string[];
  index: number;
  setIndex: Function;
}

const AIHeader: React.FC<IAIHeader> = ({ prompts, index, setIndex }) => {
  const tabStyle = (idx: number) => ({
    cursor: "pointer",
    borderRadius:
      idx === 0
        ? "25px 0 0 25px"
        : idx === prompts.length - 1
        ? "0 25px 25px 0"
        : "0",
    color: index === idx ? "var(--color-primary)" : "rgb(194, 200, 210)",
  });

  return (
    <div className="ai-header-container">
      <h3 style={{ color: "var(--dark-green)" }}>SEARCH FOR DISCOUNTS</h3>

      {prompts.map((prompt: string, idx: number) => (
        <div
          onClick={() => setIndex(idx)}
          style={tabStyle(idx)}
          className={`${
            idx === index ? "ai-header-tab-select" : "ai-header-tab-unselected"
          }`}
          key={idx}
        >
          <div className="ai-header-prompt-container">
            <h2>{prompt}</h2>
            {idx === index && (
              <img
                className="slide-in-left-to-right"
                src="https://foodisgood.com/icons/arrow-right.svg"
              />
            )}
          </div>
        </div>
      ))}
      <Button
        main_color={"var(--dark-green)"}
        secondary_color={"var(--color-secondary)"}
      />
    </div>
  );
};

export default AIHeader;
