import React, { useEffect } from "react";

import axios from "axios";

function Share() {
  useEffect(() => {
    const location = window.location;
    const urlParams: any = new URLSearchParams(location.search);
    const prefix = urlParams.get("prefix");
    const cuid = urlParams.get("cuid");
    const user_cuid = urlParams.get("user_id");

    const domain = "manyhands.charity";

    const url =
      "https://chrome.google.com/webstore/detail/many-hands-charity-extens/nhenigdkimmdialnedpnckefaioddald";

    console.log("LOGGER", { prefix, cuid, user_cuid });

    if (urlParams.size === 0) {
      window.location.replace(url);
    } else {
      if (cuid === null && user_cuid !== null) {
        console.log("LOGGER  IF");
        document.cookie = `affiliate_id_prefix=${user_cuid}; domain=${domain}`;
        window.location.replace(url);
      } else {
        axios
          .patch(
            `https://api.manyhands.charity/analytics/share/${prefix}/${cuid}`
          )
          .then(() => {
            document.cookie = `cuid=${cuid};  domain=${domain}`;
            document.cookie = `influencer_id_prefix=${prefix};  domain=${domain}`;
            window.location.replace(url);
          })
          .catch(function (error) {
            window.location.replace(url);
            console.log(error);
          });
      }
    }
  }, []);

  return <div></div>;
}

export default Share;
