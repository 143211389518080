import React, { useEffect } from "react";

import Hoc from "../../hoc/Hoc";
import ShareCard from "../card/ShareCard";

interface ILinks {
  login: any;
  getCharityShareAnalytics: Function;
  charity: any;
}
const Links: React.FC<ILinks> = ({
  login,
  getCharityShareAnalytics,
  charity: analytics,
}) => {
  const {
    user_info: { charity },
  } = login;

  useEffect(() => {
    getCharityShareAnalytics(charity.affiliate_id_prefix);
  }, []);

  const data = analytics && analytics.share_analytics;

  const today =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

  return (
    <div style={{ height: "100%" }}>
      {(data ?? []).map((ele: any, idx: number) => (
        <div key={idx} style={{ margin: "10px" }}>
          <ShareCard data={ele} border={ele.created_at === today} />
        </div>
      ))}
    </div>
  );
};

export default Hoc(Links);
