import "./organization-admin-container.css";

import { Box, Tab, Tabs } from "@mui/material";

import Analytics from "../../../components/graph/GraphTab";
import Hoc from "../../../hoc/Hoc";
import Links from "../LinkTab";
import Partnership from "../update-organization/Partnership";
import PaymentsParent from "../update-organization/payments/PaymentsParent";
import React from "react";
import Tutorials from "../tutorial/Tutorial";
import Update from "../update-organization/UpdateOrganization";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ITab {
  login: any;
}
const CharityAdminPage: React.FC<ITab> = ({ login }) => {
  const charity = login.user_info?.charity;
  const { logo = "https://media.manyhands.charity/static/assets/NoImage.png", title = "No organization details" } = charity || {};

  const [value, setValue] = React.useState(charity ? 0 : 2);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (charity || newValue === 2) {
      setValue(newValue);
    }
  };

  return (
    <div className="organization-admin-container">
      <div className="organization-admin-header">
        <div className="organization-child-container">
          <img className="organization-admin-logo" src={logo} alt="Logo" />
          <h4 style={{ color: "var(--color-secondary)" }}>{title}</h4>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{
              fontFamily: "var(--font)",
              color: "var(--color-primary)",
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                height: "3.5px",
                backgroundColor: "var(--color-secondary)",
              },
            }}
          >
            <Tab
              label="Analytics"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(0)}
              disabled={!charity}
            />
            <Tab
              label="Create Links"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(1)}
              disabled={!charity}
            />
            <Tab
              label="Update"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(2)}
            />
            <Tab
              label="Partnerships"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(3)}
              disabled={!charity}
            />
            <Tab
              label="Payments"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(4)}
              disabled={!charity}
            />
            <Tab
              label="Tutorials"
              style={{ fontSize: "16px", fontFamily: "var(--font)" }}
              {...a11yProps(5)}
              disabled={!charity}
            />
          </Tabs>
        </Box>
        <div
          style={{
            backgroundColor: "#f8fafc",
            padding: "0px 60px",
          }}
        >
          <div style={{ backgroundColor: "rgb(232 236 240)", height: "100%" }}>
            <CustomTabPanel value={value} index={0}>
              <Analytics />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Links />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Update />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Partnership />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <PaymentsParent />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <Tutorials />
            </CustomTabPanel>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Hoc(CharityAdminPage);