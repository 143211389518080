import * as actionTypes from "../types/contact";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const postContactMessage = (info: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/contact/site_contact_form`,
      method: "post",
      data: info,
      successType: actionTypes.CONTACT_MESSAGE_FULFILLED,
      errorType: actionTypes.CONTACT_MESSAGE_ERROR,
      dispatch,
    });
  };
};
