import OrganizationSignUp from "./OrganizationSignUp";
import charity from "./charity";
import { combineReducers } from "redux";
import contact_message_success from "./contact";
import coupons from "./coupons";
import login from "./login";
export default combineReducers({
  login,
  coupons,
  charity,
  contact_message_success,
  OrganizationSignUp,
});
