import "./header-background.css";

import React, { useEffect, useState } from "react";

import DomesticHeader from "../../header-background/Domestic";
import EndHeader from "../../header-background/End";
import ExtensionHeader from "../../header-background/Extension";
import Heart from "../../../static/logo.png";
import VideoPlayer from "react-background-video-player";
import { newCharityArr as arr } from "../../../utils/charity";

const HeaderIntro = () => {
  const [time, setTime] = useState(0);
  const [onReady, setOnReady] = useState(false);
  const [videoSrc, setVideoSrc] = useState([]);

  useEffect(() => {
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    const speed = connection ? connection.downlink : null;
    if (speed >= 15) {
      setVideoSrc([
        {
          src: "https://media.manyhands.charity/bg_top_quality.mp4",
          type: "video/mp4",
        },
      ]);
    } else {
      setVideoSrc([
        {
          src: "https://media.manyhands.charity/bg_medium_quality.mp4",
          type: "video/mp4",
        },
      ]);
    }
  }, []);

  const environmentCharities = arr.filter(
    (charity) =>
      charity.category === "Environmental/ Animal" ||
      charity.category === "Environmental"
  );

  const domesticCharities = arr.filter(
    (charity) => charity.category === "Domestic Needs"
  );

  const internationalNeedsCharities = arr.filter(
    (charity) => charity.category === "International Needs"
  );

  const textHandler = (time) => {
    if (time <= 6.8) {
      return (
        <ExtensionHeader time={time} duration={time > 0.6 && time < 7.3} />
      );
    } else if (time > 6.8 && time < 12) {
      return (
        <DomesticHeader
          duration={time > 8.2 && time < 12.6}
          charities={environmentCharities}
          text={"environmental Organizations"}
        />
      );
    } else if (time > 12 && time <= 17) {
      return (
        <DomesticHeader
          duration={time > 13.4 && time <= 17.5}
          charities={domesticCharities}
          text={"Domestic Organizations"}
        />
      );
    } else if (time > 17 && time <= 23) {
      return (
        <DomesticHeader
          duration={time > 18.4 && time <= 22.5}
          charities={internationalNeedsCharities}
          text={"International Needs Organizations"}
        />
      );
    } else if (time > 22 && time <= 29) {
      return <EndHeader duration={time > 23.4 && time <= 28.5} />;
    }
  };

  const timeHandler = (videoPlayBackTime) => {
    if (time !== videoPlayBackTime) {
      setTime(videoPlayBackTime);
    }
  };

  const onReadyHandler = (videoPlayBackTime) => {
    if (videoPlayBackTime > 0) {
      setOnReady(true);
    }
  };

  const handleClick = () => {
    console.log("Handle CLick");
    const screenHeight = window.innerHeight;
    window.scrollBy({
      top: screenHeight - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="home-container">
      <div className="video-info-two">{textHandler(time)}</div>
      <VideoPlayer
        style={{ height: "83vh" }}
        playsInline={true}
        disableBackgroundCover={false}
        className={onReady ? "video" : "video-block-non"}
        src={videoSrc}
        autoPlay={true}
        muted={true}
        loop={true}
        onReady={(ready) => onReadyHandler(ready)}
        onTimeUpdate={(videoPlayBackTime) => timeHandler(videoPlayBackTime)}
      />

      <div className={!onReady ? "video video-not-ready " : "video-block-none"}>
        <div>
          <div className="heart-animation">
            <img
              src={Heart}
              alt={"Many Hands Logo"}
              style={{ height: "120px" }}
            />
          </div>
          <h3 style={{ color: "#fff" }}>Video Loading</h3>
          <h3 style={{ color: "#fff" }}>Scroll Below</h3>
          <img
            onClick={() => handleClick()}
            className="white-arrow-down"
            src="https://media.manyhands.charity/arrow-down-white.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderIntro;
