import { AdvancedVideo, lazyload } from "@cloudinary/react";

import { Cloudinary } from "@cloudinary/url-gen";
import React from "react";

interface IDemoVideo {
  video: string;
  autoPlay: boolean;
  loop: boolean;
}

const DemoVideo: React.FC<IDemoVideo> = ({ video, autoPlay, loop }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: "dulzg3swq",
    },
  });

  return (
    <div className="secondary-flex-100">
      <AdvancedVideo
        style={{ borderRadius: "20px", width: "100%", height: "auto" }}
        cldVid={cld.video(video).quality("auto")}
        controls
        autoPlay={autoPlay}
        loop={loop}
        plugins={[
          lazyload({ rootMargin: "10px 20px 10px 30px", threshold: 0.25 }),
        ]}
      />
    </div>
  );
};

export default DemoVideo;
