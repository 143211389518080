import "./description.css";

import React from "react";

interface IDescription {
  text_one: string;
  text_two?: string;
  style?: any;
}
const HeaderIntro: React.FC<IDescription> = ({ text_one, text_two, style }) => {
  return (
    <div className="description-container" style={style}>
      <h3>{text_one}</h3>
      {text_two && (
        <div className="description-text-logo-wrapper">
          <span>
            <h3>{text_two}</h3>
          </span>
        </div>
      )}
      <hr className="custom-hr" />
    </div>
  );
};

export default HeaderIntro;
