import * as actionTypes from "../types/contact";

const initialState = {
  contact_message_success: null,
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.CONTACT_MESSAGE_FULFILLED:
      return {
        ...state,
        contact_message_success: action.payload,
      };
    case actionTypes.CONTACT_MESSAGE_ERROR:
      return {
        ...state,
        contact_message_success: false,
      };
    default:
      return state;
  }
};
