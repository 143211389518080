import './NonProfitSlider.css'

import React, { Fragment } from "react";
import { animated, useSpring } from "react-spring";

import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

interface ICard {
  charity: any;
  flipped?: boolean;
}

const Card: React.FC<ICard> = ({ charity, flipped }) => {
  const navigate = useNavigate();

  const { transform, opacity } = useSpring({
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { duration: 800, mass: 5, tension: 500, friction: 80 },
  });

  const back_styles_logo: any = {
    opacity: opacity.to((o) => 1 - o),
    transform,
    backgroundColor: charity.background_color,
    boxShadow: `-10px -6px 0px 0px ${charity.shadow}`,
  };

  const clickHandler = () => {
    const to = `/charity/${charity.title.split(" ").join("-")}/${charity.id}`;
    navigate(to);
  };

  return (
    <Fragment>
      <animated.div
        className={`${styles.card} ${styles.back}`}
        style={back_styles_logo}
      >
        <img
          src={charity.logo}
          className="non-profit-card-logo"
          alt={charity.title}
        />
      </animated.div>

      <animated.div
        onClick={() => clickHandler()}
        className={`${styles.card} ${styles.front}`}
        style={{
          opacity,
          transform,
          rotateY: "180deg",
          width: "350px",
          height: "350px",
          backgroundImage: `url(${charity.front_image})`,
        }}
      >
        <div className={`${styles.charityCardInfo}`}>More info</div>
      </animated.div>
    </Fragment>
  );
};

export default Card;
