import "./styles/charity.css";

import React, { Fragment } from "react";

import H3 from "../components/text/H3";
import H4 from "../components/text/H4";
import Hoc from "../hoc/Hoc";
import P from "../components/text/P";
import { newCharityArr as charity_array } from "../utils/charity";
import { useParams } from "react-router-dom";

function CharityPage(Props: any) {
  let { id } = useParams();

  const index = charity_array.findIndex((object: any) => {
    return object.id.toString() === id;
  });

  const charity = charity_array[index];

  return (
    <Fragment>
      {charity.background_color === "#fff" ? (
        <div className="charity-header-container">
          <div className="charity-child-container">
            <img
              src={charity.logo}
              alt={`Charity logo ${charity.title}`}
              className="charity-page-logo"
            />
          </div>
        </div>
      ) : (
        <div
          className="charity-header-container-color"
          style={{ backgroundColor: charity.background_color }}
        >
          <div className="charity-child-container">
            <img
              src={charity.logo}
              alt={`Charity logo ${charity.title}`}
              style={
                charity.title === "Salvation Army" ||
                charity.title === "Goodwill Industries International" ||
                charity.title === "World Wildlife Fund"
                  ? { width: "170px" }
                  : { width: "280px" }
              }
            />
          </div>
        </div>
      )}

      <div className="charity-container">
        <div className="quote-description-container">
          <div className="charity-container-child secondary">
            <div className="start">
              <h2>"</h2>
            </div>
            <div className="quote-wrapper">
              <H3
                margin_top="10px"
                text={`${charity.quote} `}
                text_color={"#383838"}
              />
            </div>
            <div className="end">
              <h2>"</h2>
            </div>
          </div>

          <div className="charity-container-child dark">
            <H3
              text={"Description :"}
              text_color={"#383838"}
              margin_top={"0px"}
            />
            <H4 text={charity.description} text_color={"#383838"} />
          </div>
        </div>

        <div className="charity-data-container">
          <div className="charity-data-container-child">
            <H4
              text={"Category: "}
              text_color={"#434343"}
              margin={"15px 0px"}
            />
            <P text={charity.category} text_color={"#434343"} />
          </div>
          <div className="charity-data-container-child">
            <H4
              text={"Private Donation:"}
              text_color={"#434343"}
              margin={"15px 0px"}
            />
            <P text={charity.private_donation} text_color={"#434343"} />
          </div>
          <div className="charity-data-container-child">
            <H4
              text={"Total Revenue:"}
              text_color={"#434343"}
              margin={"15px 0px"}
            />
            <P text={charity.total_revenue} text_color={"#434343"} />
          </div>
          <div className="charity-data-container-child">
            <H4
              text={"Fundraising:"}
              text_color={"#434343"}
              margin={"15px 0px"}
            />
            <P text={charity.fundraising} text_color={"#434343"} />
          </div>
          <div className="charity-data-container-child">
            <H4
              text={"Commitment:"}
              text_color={"#434343"}
              margin={"15px 0px"}
            />
            <P text={charity.commitment} text_color={"#434343"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Hoc(CharityPage);
