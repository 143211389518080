import * as React from "react";
const SvgComponent = ({ createTab }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
  >
    <rect
      width={52.917}
      height={52.917}
      rx={7.889}
      ry={7.889}
      style={{
        fill: createTab === "partnership" ? "#BBBBBB" : "#e6e6e6",
        strokeWidth: 0.144,
      }}
    />
    <path
      d="m45.453 28.923-2.455.827-2.133-11.71 5.48-2.196a1.294 1.294 0 1 0-.956-2.404l-6.462 2.584a1.292 1.292 0 0 0-.815 1.435v.233l-3.088 1.538a1.292 1.292 0 0 1-1.357-.117l-3.141-2.404a3.877 3.877 0 0 0-2.326-.775h-3.503a2.585 2.585 0 0 0-2.3 1.409l-2.314-.117a1.292 1.292 0 0 0-.646.143l-2.159 1.072-3.722-.93v-.168a1.292 1.292 0 0 0-.71-1.293l-5.17-2.585a1.292 1.292 0 0 0-1.163 2.301l4.38 2.197-1.059 11.71-2.326-.776a1.339 1.339 0 1 0-.827 2.546l3.877 1.293a1.538 1.538 0 0 0 .413 0 1.422 1.422 0 0 0 .711-.207 1.292 1.292 0 0 0 .582-.97l.103-1.201 1.667.827a3.968 3.968 0 0 0 1.41 2.442l7.457 5.351a2.585 2.585 0 0 0 3.67-.388 3.761 3.761 0 0 0 3.05-.168l6.992-3.49a2.986 2.986 0 0 0 1.603-2.196 2.21 2.21 0 0 0 0-.414l2.455-.814v.155a1.292 1.292 0 0 0 .608.879 1.292 1.292 0 0 0 .71.194 1.538 1.538 0 0 0 .414-.065l3.878-1.292a1.296 1.296 0 1 0-.828-2.456zM17.02 31.585a1.292 1.292 0 0 1-.388-1.434.258.258 0 0 1 .194-.181c.078 0 .181 0 .336.103l7.82 5.648-.427 1.293zm18.43 1.034-6.992 3.49a1.292 1.292 0 0 1-.982.077 2.585 2.585 0 0 0-.93-2.481l-7.755-5.648a2.87 2.87 0 0 0-2.65-.491 2.83 2.83 0 0 0-1.525 1.086l-1.758-.88a.788.788 0 0 0-.232-.064l.685-7.535 3.877.957.246-.026a1.292 1.292 0 0 0 .581-.142l2.275-1.15h.84l-.97 1.95a1.292 1.292 0 0 0 .57 1.733l.27.142a3.877 3.877 0 0 0 4.95-1.293h1.293l8.246 9.422a1.538 1.538 0 0 0 .116.194.336.336 0 0 1 .104.323.349.349 0 0 1-.181.336zm1.758-2.675-8.479-9.693a1.292 1.292 0 0 0-.956-.44h-2.585a1.292 1.292 0 0 0-1.15.711l-.142.272a1.292 1.292 0 0 1-.646.607l1.447-2.882H28.2a1.292 1.292 0 0 1 .775.258l3.205 2.404a3.877 3.877 0 0 0 4.058.362l2.417-1.202 1.564 8.608z"
      style={{
        fill: "#6b21a8",
        fillOpacity: 1,
        strokeWidth: 1.29243,
      }}
    />
  </svg>
);
export default SvgComponent;
