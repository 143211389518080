import "../style.css";
import "./fundraising.css";

import React from "react";

const RevenueEstimate = () => {
  return (
    <div className="fundraising-parent">
      <div className="fundraising-wrapper-top"></div>
      <div className="fundraising-wrapper-bottom"></div>
      <div className="fundraising-wrapper">
        <div className="secondary-flex-30 fundraising-container-picture-container">
          <img
            className="fundraising-estimate-image"
            src="https://media.manyhands.charity/static/many-hands-assets/basket.svg"
          />
        </div>
        <div className="secondary-flex-70 top-secondary-flex fundraising-text-container">
          <div className="text-wrapper tertiary-text">
            <h3 style={{ color: "var(--color-primary)" }}>
              Many Hands adds another egg to your fundraising basket, designed for minimal effort.
            </h3>
            
            <h3 style={{ color: "var(--color-primary)" }}>
              Start earning in minutes—seriously, just minutes!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueEstimate;
