import "./styles/home.css";

import React, { useEffect } from "react";

import axios from "axios";

function Unsubscribe() {
  useEffect(() => {
    const location = window.location;
    const urlParams: any = new URLSearchParams(location.search);

    const contact_id = urlParams.get("contact_id");

    axios
      .patch(`https://api.manyhands.charity/analytics/contact/${contact_id}`)
      .then((data) => {
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{
        height: "91vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "var(--dark-green)",
        color: "#fff",
      }}
    >
      <h2>You have been</h2>
      <br />
      <h2>unsubscribe</h2>
    </div>
  );
}

export default Unsubscribe;
