import * as React from "react"
const SvgComponent = ({ createTab }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
  >
    <rect
      width={52.917}
      height={52.917}
      rx={7.889}
      ry={7.889}
      style={{
        fill: createTab === "create" ? "#BBBBBB" : "#e6e6e6",
        strokeWidth: 0.144,
      }}
    />
    <path
      d="M38.963 13.954a9.127 9.127 0 0 0-12.908 0l-3.899 3.899 2.151 2.15 3.9-3.898a6.083 6.083 0 0 1 8.605 0 6.094 6.094 0 0 1 0 8.606l-3.899 3.899 2.151 2.15 3.9-3.898a9.128 9.128 0 0 0 0-12.908zM24.71 36.81a6.04 6.04 0 0 1-4.302 1.783 6.04 6.04 0 0 1-4.302-1.783 6.09 6.09 0 0 1 0-8.604l3.9-3.9-2.153-2.152-3.9 3.899c-3.564 3.564-3.564 9.344 0 12.91a9.103 9.103 0 0 0 6.455 2.672 9.103 9.103 0 0 0 6.455-2.673l3.899-3.899-2.153-2.152zm5.183-15.939 2.151 2.152-9.043 9.044-2.152-2.152z"
      style={{
        fill: "#6b21a8",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 1.5178,
      }}
    />
  </svg>
)
export default SvgComponent
