import "./Card.css";

import H4 from "../text/H4";
import React from "react";

interface ITrendingStoreCard {
  trending_stores: any;
  setValue: Function;
}
const TrendingStoresCard: React.FC<ITrendingStoreCard> = ({
  trending_stores,
  setValue,
}): JSX.Element => {
  const image_from_website = trending_stores.filter(
    (store: any) => store.image_url !== null
  );

  const imageHandler = () => {
    if (image_from_website.length > 0) {
      return (
        <img
          className="website-card-image"
          src={image_from_website[0].image_url}
        />
      );
    } else {
      return <img className="website-card-image" src="NoImage.png" />;
    }
  };

  const stringLimit = (string = "", limit = 0) => string.substring(0, limit);

  const formatStoreName = (name: string) =>
    name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <>
      {trending_stores.length > 0 && (
        <div className="website-card-container">
          <div className="website-card-image-container">
            <div style={{ display: "flex", margin: "0px 15px 0px 0px" }}></div>

            <div
              style={{ width: "80%", display: "flex" }}
              onClick={() =>
                setValue(trending_stores[0].store.replace(".com", ""))
              }
            >
              {imageHandler()}
            </div>
          </div>

          <div
            className="website-card-title-discount-container"
            onClick={() =>
              setValue(trending_stores[0].store.replace(".com", ""))
            }
          >
            <div className="website-card-title-container">
              <H4
                text={formatStoreName(
                  stringLimit(trending_stores[0].store.replace(".com", ""), 18)
                )}
                text_color={"var(--dark-green)"}
                margin={"10px 0px 10px 0px"}
              />
            </div>

            <div className="website-card-discount-container">
              <div className="website-card-discount-count">
                <H4
                  text={trending_stores.length}
                  text_color={"var(--dark-green)"}
                  margin={"0px"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TrendingStoresCard;
