import * as actionTypes from "../types/OrganizationSignUp";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const createOrganizationUsernamePassword = (obj: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/signup`,
      method: "post",
      data: obj,
      successType: actionTypes.ORGANIZATION_SIGN_UP_FULFILLED,
      errorType: actionTypes.ORGANIZATION_SIGN_UP_ERROR,
      dispatch,
    });
  };
};

export const createOrganizationInformationNoUuid = (
  token: string,
  obj: any
) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/charity/sign-up/${token}`,
      method: "post",
      data: obj,
      successType: actionTypes.ORGANIZATION_INFORMATION_FULFILLED,
      errorType: actionTypes.ORGANIZATION_INFORMATION_ERROR,
      dispatch,
    });
  };
};
