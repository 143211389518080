import "./graph.css";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import Tooltip from "@mui/material/Tooltip";

const PieGraph = ({ partner, charity }) => {
  const sumAllRevenue = (arr) => {
    var sum = arr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.revenue;
    }, 0);
    return Number(Math.round((sum * 100) / 100).toFixed(2));
  };

  const data = [
    { name: "Downloaded", value: sumAllRevenue(partner) },
    { name: "Selected", value: sumAllRevenue(charity) },
  ];

  const COLORS = ["#00c7a4", "#6b21a8"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${data[index].name}`}
      </text>
    );
  };

  const total = sumAllRevenue(partner) + sumAllRevenue(charity);

  const sumAllRevenueTotal = (total) => {
    const sum = total;
    const stripeFee = sum * (2.9 / 100);
    const netRevenue = sum - stripeFee;
    return netRevenue.toFixed(2);
  };

  const sumAllRevenueDownload = (total) => {
    const sum = total;
    const stripeFee = sum * (2.9 / 100);
    const netRevenue = sum - stripeFee;
    return netRevenue.toFixed(2);
  };

  return (
    <>
      <div className="graph-top-container">
        <Tooltip
          title="Total amount earned for the specified time frame"
          placement="top"
          arrow
        >
          <div className="graph-total-container total">
            {" "}
            Total: ${sumAllRevenueTotal(total)}
          </div>
        </Tooltip>
        <Tooltip
          title="Total amount earned as a partnered charity for the specified time frame. This is the amount users earned from downloading extension you promoted"
          placement="top"
          arrow
        >
          <div className="graph-total-container partner">
            {" "}
            Downloaded: ${sumAllRevenueDownload(sumAllRevenue(partner))}
          </div>
        </Tooltip>

        <Tooltip
          title="Total amount earned for the specified time frame as a selected charity by users "
          placement="top"
          arrow
        >
          <div className="graph-total-container charity">
            {" "}
            Selected: ${sumAllRevenueDownload(sumAllRevenue(charity))}
          </div>
        </Tooltip>
      </div>

      <ResponsiveContainer width="60%" height="100%">
        <PieChart width={400} height={800}>
          <Pie
            data={data}
            cx="40%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={200}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default PieGraph;
