import "./styles/home.css";

import React from "react";
import { TermsArray } from "../utils/terms";

interface TermSectionProps {
  title: string;
  paragraphs: string[];
  items?: string[];
  paragraphsTwo?: string[];
}

const TermsOfUsePage: React.FC = () => {
  return (
    <div
      style={{ marginTop: "80px", padding: "120px, 200px" }}
      className="terms-container"
    >
      <div className="terms-wrapper">
        {TermsArray.map((section: any, index: number) => (
          <TermSection key={index} section={section} />
        ))}
      </div>
    </div>
  );
};

const TermSection: React.FC<{ section: TermSectionProps }> = ({ section }) => {
  return (
    <div className="term-container">
      <h3>{section.title}</h3>
      {section.paragraphs &&
        section.paragraphs.map((paragraph, idx) => (
          <p key={idx}>{paragraph}</p>
        ))}
      {section.items && (
        <ul>
          {section.items.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      )}
      {section.paragraphsTwo &&
        section.paragraphsTwo.map((paragraph, idx) => (
          <p key={idx}>{paragraph}</p>
        ))}
    </div>
  );
};

export default TermsOfUsePage;
