export const TRANSACTION_FULFILLED = "TRANSACTION_FULFILLED";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";

export const SHARE_ANALYTICS_FULFILLED = "SHARE_ANALYTICS_FULFILLED";
export const SHARE_ANALYTICS_ERROR = "SHARE_ANALYTICS_ERROR";

export const POST_SHARE_LINK_FULFILLED = "POST_SHARE_LINK_FULFILLED";
export const POST_SHARE_LINK_CLEARED = "POST_SHARE_LINK_CLEARED";
export const POST_SHARE_LINK_ERROR = "POST_SHARE_LINK_ERROR";

export const GET_EMAIL_ANALYTICS_FULFILLED = "GET_EMAIL_ANALYTICS_FULFILLED";
export const GET_EMAIL_ANALYTICS_ERROR = "GET_EMAIL_ANALYTICS_ERROR";

export const POST_EMAIL_ANALYTICS_FULFILLED = "POST_EMAIL_ANALYTICS_FULFILLED";
export const POST_EMAIL_ANALYTICS_ERROR = "POST_EMAIL_ANALYTICS_ERROR";

export const POST_CONTACT_FULFILLED = "POST_CONTACT_FULFILLED";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";

export const CLEAR_POST_CONTACT_FULFILLED = "CLEAR_POST_CONTACT_FULFILLED";

export const GET_EMAIL_ADDRESSES_FULFILLED = "GET_EMAIL_ADDRESSES_FULFILLED";
export const GET_EMAIL_ADDRESSES__ERROR = "GET_EMAIL_ADDRESSES__ERROR";

export const ORGANIZATION_PATCH_FULFILLED = "ORGANIZATION_PATCH_FULFILLED";
export const ORGANIZATION_PATCH_ERROR = "ORGANIZATION_PATCH_ERROR";

export const UPDATE_ORGANIZATION_ERROR = "export const";

export const GET_ORGANIZATION_BY_STATE_FULFILLED =
  "GET_ORGANIZATION_BY_STATE_FULFILLED";

export const GET_ORGANIZATION_BY_STATE_ERROR =
  "GET_ORGANIZATION_BY_STATE_ERROR";

export const DELETE_CONTACT_FULFILLED = "DELETE_CONTACT_FULFILLED";

export const DELETE_CONTACT_ERROR = "DELETE_CONTACT_ERROR";
