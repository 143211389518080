import "./styles/home.css";

import Carousel from "../components/sections/Carousel";
import Hoc from "../hoc/Hoc";
import HowTo from "../components/sections/HowTo";
import React from "react";

function SignUp(Props: any) {
  return (
    <>
      <HowTo />
      <Carousel visible_button={false} />
    </>
  );
}

export default Hoc(SignUp);
