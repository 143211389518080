import "../update-organization.css";

import { Button, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";

import Hoc from "../../../../hoc/OrganizationSignUpHoc";
import axios from "axios";

interface IPaymentsTab {
  login: any;
}

const PaymentsTab: React.FC<IPaymentsTab> = ({ login }) => {
  const { user_info } = login;
  const uuid = user_info && user_info.user.uuid;

  const [nonprofitName, setNonprofitName] = useState("");
  const [nonprofitUrl, setNonprofitUrl] = useState("");
  const [accountLink, setAccountLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [urlError, setUrlError] = useState("");

  const validateUrl = (url: string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return urlPattern.test(url);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setUrlError("");

    if (!validateUrl(nonprofitUrl)) {
      setUrlError("Please enter a valid URL.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.manyhands.charity/payment-account-setup/create",
        { uuid, nonprofitName, nonprofitUrl }
      );
      setAccountLink(response.data.url);
    } catch (err) {
      setError("Failed to create account. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {urlError && <p style={{ color: "red" }}>{urlError}</p>}
      {accountLink ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <h2>Account created successfully!</h2>
          <h4 style={{ marginBottom: "10px" }}>
            Please complete Stripe onboarding by clicking the button below.
          </h4>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "250px" }}
            href={accountLink}
          >
            Complete Onboarding
          </Button>
        </div>
      ) : (
        <>
          <h2>Nonprofit Payment Onboarding</h2>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Organization Name"
              variant="outlined"
              value={nonprofitName}
              onChange={(e) => setNonprofitName(e.target.value)}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              label="Organization Url"
              variant="outlined"
              value={nonprofitUrl}
              onChange={(e) => setNonprofitUrl(e.target.value)}
              error={Boolean(urlError)}
              helperText={urlError}
              fullWidth
            />
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!nonprofitName || !nonprofitUrl}
            style={{ width: "250px" }}
          >
            {loading ? "Creating Account..." : "Submit"}
          </Button>
        </>
      )}
    </div>
  );
};

export default Hoc(PaymentsTab);
