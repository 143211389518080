import "../style.css";
import "./SmallOrg.css";

import React from "react";

const SmallOrganization = () => {
  return (
    <div className="small-organization-parent">
      <div className="small-organization-wrapper-top"></div>
      <div className="small-organization-wrapper-bottom"></div>
      <div className="small-organization-wrapper">
        <div className="secondary-flex-100 top-secondary-flex small-organization-text-container">
          <div className="text-wrapper tertiary-text">
            <h3>🤔 What about small organizations?</h3>
            <h4 style={{ color: "var(--color-primary)" }}>
              Leverage the Many Hands platform by partnering with businesses, or
              influencers to promote the Many Hands extension. You can choose to
              share revenue with partners. We handle all accounting, simplifying
              partnership management for you. <strong>Read more below.</strong>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallOrganization;