import Paper from "@mui/material/Paper";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface IEmailAnalytics {
  columns: any;
  rows: any;
}
const BasicTable: React.FC<IEmailAnalytics> = ({ columns, rows }) => {
  const newRows = () =>
    rows.map((row: any) => ({
      charity_name: row.charity_name,
      charity_type: row.charity_type,
      email_open: row.emails.reduce(
        (n: any, { email_open }: any) => n + email_open,
        0
      ),
      extension_click: row.emails.reduce(
        (n: any, { extension_click }: any) => n + extension_click,
        0
      ),
      website_click: row.emails.reduce(
        (n: any, { website_click }: any) => n + website_click,
        0
      ),
      replay_date: row.replay_date,
    }));
 
  return rows.length <= 0 ? (
    <>...Loading</>
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column: any, idx: number) => (
              <TableCell
                key={idx}
                style={{
                  backgroundColor: "#6b21a8",
                  color: "#fff",
                  fontSize: "15px",
                  fontWeight: "300",
                }}
              >
                {column.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {newRows().map((row: any, idx: number) => (
            <TableRow
              style={{ backgroundColor: idx % 2 == 0 ? "#fff" : "#eee" }}
              key={idx}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {columns.map((column: any, idx: number) => (
                <TableCell
                  key={idx}
                  style={{ fontSize: "15px", fontWeight: "300" }}
                >
                  {row[column]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
