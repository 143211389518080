import * as actionTypes from "../types/OrganizationSignUp";

const initialState = {
  uuid: "",
  email: "",
  account_type: "",
  signup_error: null,
  signup_error_message: "",
  organization_information: {},
  organization_error: null,
  organization_error_message: "",
};

const setToken = (token: string) => {
  document.cookie = `organization=${token}`;
};

const errorMessageHandler = (error: string) => {
  var statement = "";
  switch (error) {
    case "Request failed with status code 409":
      statement = "Email address already exists.";
      break;
    default:
      statement = "Error, try again. If the error persists - ";
  }
  return statement;
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.ORGANIZATION_SIGN_UP_FULFILLED:
      const { token, email, uuid_id, account_type } = action.payload;
      setToken(token);
      return {
        ...state,
        uuid: uuid_id,
        email: email,
        account_type: account_type,
        signup_error_message: false,
      };
    case actionTypes.ORGANIZATION_SIGN_UP_ERROR:
      return {
        ...state,
        signup_error: true,
        signup_error_message: errorMessageHandler(action.payload),
      };
    case actionTypes.ORGANIZATION_INFORMATION_FULFILLED:
      return {
        ...state,
        organization_information: !action.payload?.message
          ? action.payload
          : {},
        organization_error: action.payload?.message ? true : false,
        organization_error_message: action.payload?.message
          ? action.payload.message
          : "",
      };
    case actionTypes.ORGANIZATION_INFORMATION_ERROR:
      return {
        ...state,
        organization_error: true,
        organization_error_message: action.payload,
      };
    default:
      return state;
  }
};
