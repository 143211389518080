import "./Card.css";

import FullWidthCardContainer from "./FullWidthCardContainer";
import React from "react";

const FullWidthCard: React.FC<{ data: any }> = ({ data }) => {

  return (
    <FullWidthCardContainer data={data}>
      <div className={"full-width-button"}>
        <p
          style={{
            margin: "0px",
            color: "#6b21a8",
            fontSize: "15px",
            fontFamily: "Roboto Slab, serif",
            fontWeight: 600,
          }}
        >
          {"Activate Deal"}
        </p>
      </div>
    </FullWidthCardContainer>
  );
};
export default FullWidthCard;
