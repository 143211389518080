import { Button } from "@mui/material";
import React from "react";
const OrganizationReturn: React.FC = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <>
        <h2>Login</h2>
        <h3 style={{ margin: "15px 0px 10px 0px" }}>
          to check Stripe account status
        </h3>

        <Button
          style={{
            margin: "15px 0px",
            backgroundColor: "var(--color-secondary)",
          }}
          variant="contained"
          color="primary"
          href="/login"
        >
          Login
        </Button>
      </>
    </div>
  );
};

export default OrganizationReturn;
