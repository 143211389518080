import "./password-reset.css";

import { Alert, Button, TextField } from "@mui/material";
import React, { useState } from "react";

import axios from "axios";

const ResetPasswordRequest: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError("");
    setSuccess(false);

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.manyhands.charity/password-reset",
        { email }
      );
      if (response.status === 200) {
        setSuccess(true);
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="password-reset-container">
      {error && (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" style={{ marginTop: "20px" }}>
          Password reset email sent successfully. Please check your email!
        </Alert>
      )}
      <form className="form-password-reset-container" onSubmit={handleSubmit}>
        <TextField
          label="Email Address"
          variant="outlined"
          type="email"
          value={email}
          onChange={handleEmailChange}
          required
          fullWidth
          margin="normal"
        />
        <Button
          style={{
            marginTop: "20px",
            backgroundColor: "var(--color-secondary)",
          }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Reset Email
        </Button>
      </form>
    </div>
  );
};

export default ResetPasswordRequest;
