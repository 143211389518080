import "../sections/header/header-background.css";

import { animated, useSpring } from "react-spring";

import Heart from "../../static/logo.png";
import React from "react";

interface IEXTENSION {
  duration: boolean;
}

const Extension: React.FC<IEXTENSION> = ({ duration }) => {
  const animation: any = useSpring({
    right: duration ? "0px" : "1800px",
    position: "absolute",
    width: "100%",
  });

  const handleClick = () => {
    console.log("Handle CLick");
    const screenHeight = window.innerHeight;
    window.scrollBy({
      top: screenHeight - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <animated.div style={animation} className="extension-slide-container">
      <img
        src={Heart}
        alt={"Many Hands Logo"}
        style={{ height: "18%", width: "auto" }}
      />
      <h1 style={{ color: "#fff" }}>{"join us".toUpperCase()}</h1>
      <h3 style={{ color: "#fff" }}>
        {"and be one of our many hands!".toUpperCase()}
      </h3>
      <img
        onClick={() => handleClick()}
        className="white-arrow-down"
        src="https://media.manyhands.charity/arrow-down-white.svg"
      />
    </animated.div>
  );
};
export default Extension;
