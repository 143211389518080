import "./Contact.css";

import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

import hoc from "../../hoc/Hoc";

const Contact = ({ postContactMessage, contact_message_success }) => {
  const contactObject = {
    person_name: "",
    email_address: "",
    text: "",
  };

  const [contact, setContact] = useState(contactObject);

  const setContactHandler = (e) => {
    setContact({ ...contact, [e.name]: e.value });
  };

  const postContactHandler = () => {
    postContactMessage(contact);
  };

  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const email_validate = contact.email_address.match(validRegex) || [];

  const personDisableHandler =
    email_validate.length <= 0 ||
    contact.email_address.length <= 0 ||
    contact.text.length <= 0;

  const message_success = contact_message_success.contact_message_success;

  return (
    <>
      <div className="about-container">
        <div className="home-child-container about-text">
          <h1>Contact</h1>
        </div>
      </div>
      <div className="contact-page-container">
        <div className="contact-form-container form">
          {message_success === null || message_success === false ? (
            <>
              {!message_success && message_success !== null && (
                <div className="message-sent-container error">
                  Error sending your message. Please, try again.
                </div>
              )}
              <TextField
                fullWidth
                id="outlined-basic"
                label="First name"
                variant="outlined"
                value={contact.person_name}
                name="person_name"
                onChange={(e) => setContactHandler(e.target)}
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email address"
                variant="outlined"
                value={contact.email_address}
                name="email_address"
                onChange={(e) => setContactHandler(e.target)}
                style={{ marginTop: "20px" }}
              />
              <TextField
                fullWidth
                id="outlined-basic"
                label="Message"
                variant="outlined"
                value={contact.text}
                name="text"
                multiline
                rows={5}
                onChange={(e) => setContactHandler(e.target)}
                style={{ marginTop: "20px" }}
              />
              <Button
                variant="contained"
                color="inherit"
                style={{
                  color: personDisableHandler ? "grey" : "#FFF",
                  marginTop: "20px",
                  backgroundColor: personDisableHandler ? "#d1d1d1" : "#6b21a8",

                  padding: "6px 20px",
                  fontSize: "20px",

                  fontWeight: "400",
                  border: "none",
                  borderRadius: "10px",
                }}
                onClick={() => postContactHandler()}
                disabled={personDisableHandler}
              >
                Submit Message
              </Button>
            </>
          ) : (
            <div className="message-sent-container success">
              Your message was sent. You should receive a response shortly.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default hoc(Contact);
