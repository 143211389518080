import { Autocomplete, TextField } from '@mui/material';

import React from "react";
import { styled } from "@mui/material/styles";

interface IAutocomplete {
  data: any;
  value: string;
  setValue: Function;
  label: string;
}

const AutocompleteComponent: React.FC<IAutocomplete> = ({
  data,
  value,
  setValue,
  label,
}) => {
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "var(--color-secondary)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--color-secondary)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--lite-gray)",
      },
      "&:hover fieldset": {
        borderColor: "var(--lite-gray)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--lite-gray)",
      },
    },
  });

  return (
    <Autocomplete
      value={value}
      disablePortal
      id="combo-box-demo"
      options={data}
      onChange={(event: any, newValue: any | null) => setValue(newValue)}
      renderInput={(params: any) => (
        <div className="TextField-without-border-radius">
          <CssTextField
            {...params}
            size="small"
            variant="outlined"
            label={label}
            id="custom-css-outlined-input"
          />
        </div>
      )}
    />
  );
};

export default AutocompleteComponent;
