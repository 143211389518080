import React from "react";

interface Webpage {
  text_color: string;
  text_secondary?: string;
  text: string;
  margin?: string;
  fontSize?: string;
}

const H1: React.FC<Webpage> = ({
  text_color,
  text_secondary = "var(--dark-green)",
  text,
  margin,
  fontSize = "85px",
}) => {
  return (
    <h1
      style={{
        color: text_color,
        textShadow: `-3px -3px 0px ${text_secondary}`,
        margin: margin,
        fontSize: fontSize,
      }}
    >
      {text}
    </h1>
  );
};

export default H1;
