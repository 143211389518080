import "../sections/header/header-background.css";

import { animated, useSpring } from "react-spring";

import React from "react";

interface IEXTENSION {
  time: number;
  duration: boolean;
}

const Extension: React.FC<IEXTENSION> = ({ time, duration }) => {
  const animation: any = useSpring({
    right: duration ? "0px" : "1800px",
    position: "absolute",
    width: "100%",
  });

  return (
    <animated.div style={animation} className="extension-slide-container">
      {time < 4 ? (
        <>
          {/* <img src={ExtensionImage} alt={"Many Hands Charity ext flair"} /> */}
          <div className="extension-slide-desktop">
            <h1 style={{ color: "#fff" }}>{"Many Hands".toUpperCase()}</h1>
            <h3 style={{ color: "#fff" }}>
              {"A Browser extension SAVING YOU MONEY".toUpperCase()}
            </h3>
            <h3 style={{ color: "#fff" }}>
              {"& Giving to charities of your choice".toUpperCase()}
            </h3>
          </div>
          <div className="extension-slide-mobile">
            <h1 style={{ color: "#fff" }}>{"Many Hands".toUpperCase()}</h1>
            <h3 style={{ color: "#fff", marginTop: "10px" }}>
              {"Extension SAVING YOU MONEY".toUpperCase()}
            </h3>
            <h3 style={{ color: "#fff" }}>
              {"& Giving to charities of your choice".toUpperCase()}
            </h3>
          </div>
        </>
      ) : (
        <>
          {/* <img
              src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/logo.svg"
              alt={"Many Hands Charity Logo"}
              style={{ height: "18%", width: "auto", marginBottom: "40px" }}
            /> */}
          <div className="extension-slide-desktop">
            <h1 style={{ color: "#fff" }}>{"80%".toUpperCase()}</h1>
            <h3 style={{ color: "#fff" }}>
              {"of all revenue goes to the".toUpperCase()}
            </h3>
            <h3 style={{ color: "#fff" }}>
              {"non-profits of your choice".toUpperCase()}
            </h3>
          </div>
          <div className="extension-slide-mobile">
            <h1 style={{ color: "#fff" }}>{"80%".toUpperCase()}</h1>
            <h3 style={{ color: "#fff", marginTop: "10px" }}>
              {"of all revenue goes to the".toUpperCase()}
            </h3>
            <h3 style={{ color: "#fff" }}>
              {"non-profits of your choice".toUpperCase()}
            </h3>
          </div>
        </>
      )}
    </animated.div>
  );
};
export default Extension;
