import React from "react";

interface Webpage {
  text_color: string;
  text?: string;
  margin_top?: string;
}

const H3: React.FC<Webpage> = ({ text_color, text, margin_top = "25px" }) => {
  return <h3 style={{ color: text_color, marginTop: margin_top }}>{text}</h3>;
};

export default H3;
