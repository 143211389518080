import React from "react";

function Footer() {
  return (
    <footer
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "var(--color-secondary)",
        padding: "20px",
      }}
    >
      <nav>
        <ul className="ul">
          <li className="li">
            <a className="a" href="/about">
              About
            </a>
          </li>
          <li className="li">
            <a className="a" href="/faq">
              FAQ
            </a>
          </li>
          <li className="li">
            <a className="a" href="/terms">
              Terms of Use
            </a>
          </li>
          <li className="li">
            <a
              className="extensionLink"
              target="_blank"
              rel="noreferrer"
              href="https://chrome.google.com/webstore/detail/many-hands-charity-extens/nhenigdkimmdialnedpnckefaioddald"
            >
              Get Extension
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
