import { Box, Button, CardContent, Typography } from "@mui/material";

import React from "react";

interface Transaction {
  id: string;
  object: string;
  amount: number;
  available_on: number;
  created: number;
  currency: string;
  description: string | null;
  exchange_rate: number | null;
  fee: number;
  fee_details: any[];
  net: number;
  reporting_category: string;
  source: string;
  status: string;
  type: string;
}

interface Props {
  transactions: Transaction[];
  login_link: string;
}

const StripeBalanceTransaction: React.FC<Props> = ({
  transactions,
  login_link,
}) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>Transactions</h2>
        <Button
          variant="contained"
          color="primary"
          href={login_link}
          style={{ width: "250px" }}
          target="_blank"
        >
          Login to stripe account
        </Button>
      </div>

      {transactions && transactions.length > 0 ? (
        transactions.map((transaction) => (
          <Box
            key={transaction.id}
            sx={{
              border: `1px solid rgb(179 179 179)`,
              borderRadius: "10px",
              margin: "10px 0",
              padding: "10px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14, marginBottom: 1 }}
                color="text.secondary"
                gutterBottom
              >
                Created:{" "}
                {new Date(transaction.created * 1000).toLocaleDateString()}
              </Typography>
              <Box sx={{ flex: "1 1 200px", marginBottom: 1 }}>
                <Typography variant="h6" color="text.secondary">
                  Amount: ${(transaction.amount / 100).toFixed(2)}{" "}
                  {transaction.currency.toUpperCase()}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ flex: "1 1 400px", marginBottom: 1 }}>
                  <Typography variant="body1" component="div" gutterBottom>
                    Transaction ID: {transaction.id}
                  </Typography>
                </Box>

                <Box sx={{ flex: "1 1 200px", marginBottom: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Available On:{" "}
                    {new Date(
                      transaction.available_on * 1000
                    ).toLocaleDateString()}
                  </Typography>
                </Box>

                <Box sx={{ flex: "1 1 200px", marginBottom: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Reporting Category: {transaction.reporting_category}
                  </Typography>
                </Box>

                <Box sx={{ flex: "1 1 200px", marginBottom: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Status: {transaction.status}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Box>
        ))
      ) : (
        <Typography>No transactions available.</Typography>
      )}
    </div>
  );
};

StripeBalanceTransaction.defaultProps = {
  transactions: [],
};

export default StripeBalanceTransaction;
