import "./Card.css";

import { Box, Button, CardContent, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

import { ContentCopy } from "@mui/icons-material";

interface IShareCard {
  data: any;
  border?: boolean;
}

const ShareCard: React.FC<IShareCard> = ({ data, border = false }) => {
  const { created_at, description, share_url, title } = data;
  const [open, setOpen] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setOpen(true);
      },
      (err) => {
        console.error("Failed to copy the text to clipboard", err);
      }
    );
  };

  const handleClose = () => setOpen(false);

  return (
    <div
      style={{
        border: `${border ? "3px" : "1px"} solid ${
          border ? "#6b21a8" : "rgb(179 179 179)"
        } `,
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {created_at}
        </Typography>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {share_url}
        </Typography>
        <Button
          variant="contained"
          startIcon={<ContentCopy />}
          onClick={() => copyToClipboard(share_url)}
          sx={{
            backgroundColor: "#6b21a8",
            "&:hover": {
              backgroundColor: "#5a1a8a",
            },
          }}
        >
          Copy Link
        </Button>
      </CardContent>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="copy-success-modal"
        aria-describedby="copy-success-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
            fontFamily: "'Barlow', sans-serif",
            border: "none",
          }}
        >
          <Typography
            id="copy-success-modal"
            variant="h6"
            component="h2"
            sx={{ fontSize: 24 }}
          >
            Copy Success!
          </Typography>
          <Typography
            id="copy-success-description"
            sx={{ mt: 2, fontSize: 18 }}
          >
            Copied link to clipboard successfully! Paste in the platform of your
            choice e.g. Facebook.
          </Typography>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mt: 2,
              backgroundColor: "#6b21a8",
              "&:hover": {
                backgroundColor: "#5a1a8a",
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ShareCard;
