import * as React from "react";

const SvgComponent = (props) => (
  <svg width="auto" height="100%" viewBox="0 0 100.542 148.696" {...props}>
    <g transform="translate(-36.506 80.177)">
      <path
        d="M36.506-79.648h100.542V68.519H36.506z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.664424,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M36.506-80.177h100.542v15.875H36.506z"
        style={{
          fill: "#0f172a",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.217484,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M-114.502 694.86c-3.18-2.422-5.014-4.169-6.544-6.232-3.29-4.437-4.418-8.376-3.275-11.432.655-1.75 2.242-2.93 4.388-3.265 2.36-.367 5.329.603 6.65 2.174l.392.465.768-.74c2.092-2.017 5.668-2.561 8.33-1.269 1.667.81 2.622 2.326 2.824 4.488.354 3.794-2.721 9.46-7.295 13.438-1.357 1.18-4.43 3.57-4.59 3.569-.042 0-.784-.539-1.648-1.196z"
        style={{
          fill: "red",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.729237,
          strokeOpacity: 1,
        }}
        transform="matrix(.3575 0 0 .3575 85.075 -316.79)"
      />
      <path
        d="M-427.938 2629.735c-3.202-2.275-9.124-6.852-12.492-9.655-8.98-7.473-14.539-13.502-20.044-21.743-6.105-9.14-9.505-17.125-10.699-25.125-.274-1.834-.275-6.715-.002-8.415.78-4.865 2.425-8.464 5.166-11.309 1.802-1.87 3.444-3.046 5.89-4.216 2.244-1.073 4.81-1.808 7.587-2.172 1.821-.238 5.84-.16 7.746.15 3.792.618 7.194 1.712 10.187 3.275 2.772 1.448 4.759 2.995 6.65 5.178l1.196 1.38.008 8.807c.004 4.844.05 21.39.102 36.768.051 15.379.08 27.975.064 27.991-.017.016-.628-.395-1.358-.914z"
        style={{
          opacity: 0.99,
          fill: "#000",
          fillOpacity: 0.198653,
          stroke: "none",
          strokeWidth: 6.36737,
          strokeDashoffset: 210.392,
          strokeOpacity: 0.984314,
        }}
        transform="matrix(.0946 0 0 .0946 85.075 -316.79)"
      />
      <text
        xmlSpace="preserve"
        x={52.419}
        y={-68.208}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={52.419}
          y={-68.208}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={52.691}
        y={-67.965}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={52.691}
          y={-67.965}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <path
        d="M36.506-64.302h100.542v7.938H36.506z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.153785,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M36.506 55.835h100.542v.529H36.506z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={21.696}
        height={5.821}
        x={39.151}
        y={-63.03}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={43.826}
        y={-58.33}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={43.826}
          y={-58.33}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Deals"}
        </tspan>
      </text>
      <rect
        width={21.696}
        height={5.821}
        x={75.928}
        y={-63.03}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={76.522}
        y={-58.33}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={76.522}
          y={-58.33}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Charities"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={78.101}
        y={-14.381}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={78.101}
          y={-14.381}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Charities"}
        </tspan>
      </text>
      <rect
        width={100.542}
        height={54.721}
        x={36.506}
        y={6.699}
        rx={0}
        ry={0}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.143569,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M36.506-7.154h100.542v.529H36.506z"
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.398}
        height={10.583}
        x={39.549}
        y={11.68}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0986899,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.46}
        height={10.231}
        x={40.368}
        y={12.56}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.351994,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={71.235}
        y={19.189}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={71.235}
          y={19.189}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Share  with  email"}
        </tspan>
      </text>
      <g transform="translate(2412.736 -1846.37)">
        <rect
          width={21.696}
          height={5.821}
          x={-2300.288}
          y={1783.001}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={21.696}
          height={5.821}
          x={-2300.031}
          y={1783.341}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
      </g>
      <text
        xmlSpace="preserve"
        x={116.982}
        y={-58.33}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={116.982}
          y={-58.33}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Share"}
        </tspan>
      </text>
      <g transform="translate(1140.123 -13.392)">
        <rect
          width={100.542}
          height={36.582}
          x={-1103.618}
          y={-42.443}
          ry={0}
          style={{
            fill: "#fff",
            stroke: "none",
            strokeWidth: 0.437038,
            strokeDasharray: ".437038,.437038",
          }}
        />
        <path
          d="M-1076.34-9.171c-2.622-5.536-3.888-9.204-4.517-13.09-1.353-8.355-.114-14.51 3.593-17.864 2.123-1.92 5.114-2.41 8.27-1.353 3.47 1.162 6.828 4.574 7.52 7.64l.206.91 1.566-.467c4.267-1.27 9.518.507 12.23 4.14 1.7 2.277 1.93 5.012.683 8.096-2.19 5.414-10.365 10.958-19.391 13.152-2.678.65-8.543 1.738-8.76 1.624-.057-.03-.688-1.285-1.4-2.788z"
          style={{
            fill: "#f2f2f2",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 1.11743,
            strokeOpacity: 1,
          }}
        />
        <path
          d="M-1091.525-23.702c-2.376-.436-3.842-.876-5.23-1.57-2.986-1.493-4.671-3.307-4.89-5.267-.126-1.122.407-2.192 1.472-2.958 1.172-.843 3.038-1.132 4.179-.647l.338.144.212-.609c.576-1.658 2.355-2.93 4.143-2.96 1.12-.02 2.049.537 2.748 1.647 1.228 1.948 1.119 5.842-.259 9.237-.408 1.006-1.412 3.135-1.498 3.177-.023.012-.57-.075-1.215-.194z"
          style={{
            fill: "red",
            fillOpacity: 0.0853243,
            stroke: "none",
            strokeWidth: 0.44069,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-1073.572}
          y={-31.344}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "normal",
            fontSize: "12.1711px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            textAlign: "center",
            wordSpacing: "-1.46972px",
            textAnchor: "middle",
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 24.6164,
            strokeDasharray: "73.8487,73.8487",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-1073.572}
            y={-31.344}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 700,
              fontStretch: "normal",
              fontSize: "12.1711px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              textAlign: "center",
              textAnchor: "middle",
              fill: "#00c7a4",
              fillOpacity: 1,
              strokeWidth: 24.6164,
            }}
          >
            {"SHARING "}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-1095.052}
          y={-10.506}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "normal",
            fontSize: "7.61963px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            textAlign: "center",
            wordSpacing: "-.920106px",
            textAnchor: "middle",
            fill: "#666",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 15.4109,
            strokeDasharray: "46.2323,46.2323",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-1095.052}
            y={-10.506}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 700,
              fontStretch: "normal",
              fontSize: "7.61963px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              textAlign: "center",
              textAnchor: "middle",
              fill: "#666",
              fillOpacity: 1,
              strokeWidth: 15.4109,
            }}
          >
            {"for"}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-1053.003}
          y={-23.199}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 700,
            fontStretch: "normal",
            fontSize: "7.61963px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            textAlign: "center",
            wordSpacing: "-.920106px",
            textAnchor: "middle",
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 15.4109,
            strokeDasharray: "46.2323,46.2323",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-1053.003}
            y={-23.199}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 700,
              fontStretch: "normal",
              fontSize: "7.61963px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              textAlign: "center",
              textAnchor: "middle",
              fill: "red",
              fillOpacity: 1,
              strokeWidth: 15.4109,
            }}
          >
            {"resources "}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-1262.19}
          y={-23.351}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 300,
            fontStretch: "normal",
            fontSize: "18.2186px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            textAlign: "center",
            wordSpacing: "-2.19999px",
            textAnchor: "middle",
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 36.8477,
            strokeDasharray: "110.542,110.542",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
          transform="scale(.82448 1.21288)"
        >
          <tspan
            x={-1262.19}
            y={-23.351}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 300,
              fontStretch: "normal",
              fontSize: "18.2186px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              textAlign: "center",
              textAnchor: "middle",
              fill: "#6b21a8",
              fillOpacity: 1,
              strokeWidth: 36.8477,
            }}
          >
            {"="}
          </tspan>
        </text>
        <path
          d="M-1034.622-12.16c-.454-.641-.696-1.076-.861-1.553-.357-1.025-.337-1.83.057-2.336.225-.29.592-.417 1.014-.354.464.07.964.426 1.12.798l.045.11.188-.093c.51-.255 1.213-.147 1.636.251.265.25.355.59.266 1.007-.156.733-1.066 1.614-2.157 2.091-.324.142-1.04.41-1.07.4a4.1 4.1 0 0 1-.239-.321z"
          style={{
            fill: "#6b21a8",
            fillOpacity: 0.286689,
            stroke: "none",
            strokeWidth: 0.143275,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-1086.798}
          y={-27.89}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "16.1898px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: ".701555px",
            fill: "#666",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 43.6588,
            strokeDasharray: "130.976,130.976",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-1086.798}
            y={-7.653}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 600,
              fontStretch: "normal",
              fontSize: "16.1898px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#666",
              fillOpacity: 1,
              strokeWidth: 43.6588,
            }}
          >
            {"CHARITES "}
          </tspan>
        </text>
        <path
          d="M-1020.371-28.61c-1.922-1.464-3.03-2.52-3.955-3.767-1.988-2.681-2.67-5.061-1.98-6.908.396-1.058 1.355-1.771 2.652-1.973 1.426-.222 3.22.365 4.02 1.313l.236.282.464-.448c1.264-1.218 3.425-1.547 5.034-.766 1.008.49 1.584 1.405 1.706 2.712.215 2.293-1.644 5.716-4.408 8.12-.82.714-2.677 2.158-2.773 2.157-.026 0-.475-.325-.996-.723z"
          style={{
            fill: "#f2f2f2",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.44069,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-1019.23}
          y={-31.778}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 300,
            fontStretch: "normal",
            fontSize: "10.9502px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            textAlign: "center",
            wordSpacing: "-1.32228px",
            textAnchor: "middle",
            fill: "#b3b3b3",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 22.147,
            strokeDasharray: "66.4405,66.4405",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-1019.23}
            y={-31.778}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 300,
              fontStretch: "normal",
              fontSize: "10.9502px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              textAlign: "center",
              textAnchor: "middle",
              fill: "#b3b3b3",
              fillOpacity: 1,
              strokeWidth: 22.147,
            }}
          >
            {"MORE"}
          </tspan>
        </text>
        <path
          d="M-1026.723-21.226c-.702-.991-1.075-1.664-1.331-2.4-.55-1.586-.52-2.83.088-3.612.349-.448.916-.646 1.568-.548.718.108 1.49.659 1.73 1.234l.072.17.29-.144c.789-.395 1.875-.228 2.53.388.409.387.548.913.41 1.558-.241 1.132-1.648 2.494-3.335 3.232-.5.22-1.608.633-1.654.618-.015-.005-.179-.227-.369-.496z"
          style={{
            fill: "#00c7a4",
            fillOpacity: 0.262799,
            stroke: "none",
            strokeWidth: 0.221506,
            strokeOpacity: 1,
          }}
        />
      </g>
      <rect
        width={93.429}
        height={10.231}
        x={39.482}
        y={-12.213}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0970496,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.429}
        height={10.231}
        x={40.333}
        y={-11.556}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "#fff",
          strokeWidth: 0.351937,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={51.312}
        y={-4.931}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={51.312}
          y={-4.931}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"https://manyshands.charity/share"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={80.871}
        y={4.14}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={80.871}
          y={4.14}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Social"}
        </tspan>
      </text>
      <path
        d="M36.506 60.458h100.542v8.061H36.506z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.154977,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={78.008}
        y={65.797}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "4.49792px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={78.008}
          y={65.797}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.49792px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Settings"}
        </tspan>
      </text>
      <path
        d="M36.506-60.581h100.542v.529H36.506z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.398}
        height={10.583}
        x={39.549}
        y={28.614}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0986899,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.46}
        height={10.231}
        x={40.368}
        y={29.493}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.351994,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={67.432}
        y={36.123}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={67.432}
          y={36.123}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Share  with  Facebook"}
        </tspan>
      </text>
      <rect
        width={93.398}
        height={10.583}
        x={39.549}
        y={45.548}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0986899,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.46}
        height={10.231}
        x={40.368}
        y={46.427}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.351994,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={69.496}
        y={52.647}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={69.496}
          y={52.647}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Share  with  Twitter"}
        </tspan>
      </text>
    </g>
  </svg>
);

export default SvgComponent;
