import "../style.css";
import "./index.css";

import React from "react";

interface ILeftComponent {
  h2: string;
  h3: string;
  p: string;
  image: string;
}

const RevenueGenerated: React.FC<ILeftComponent> = ({ h2, h3, p, image }) => {
  return (
    <div className="click-generated-parent primary-component-container">
      <div className="purchase-generate-text-wrapper secondary-flex-50 top-secondary-flex">
        <div className="text-wrapper tertiary-text purchase-generate-text">
          <h3 style={{ color: "#fff" }}>{h3}</h3>
          <h2 style={{ color: "#fff" }}>{h2}</h2>
          <p style={{ color: "#fff" }}>{p}</p>
        </div>
      </div>
      <div
        className="secondary-flex-50"
        style={{
          position: "relative",
          height: "370px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="click-generated-picture-wrapper">
          <img className="click-generated-image" src={image} />
        </div>
      </div>
    </div>
  );
};

export default RevenueGenerated;
