import "../sections/header/header-background.css";

import { animated, useSpring } from "react-spring";

import React from "react";

interface IEXTENSION {
  duration: boolean;
  charities: any;
  text: string;
}

const Domestic: React.FC<IEXTENSION> = ({ duration, charities, text }) => {
  const animation: any = useSpring({
    right: duration ? "0px" : "1800px",
    position: "absolute",
    width: "100%",
  });

  return (
    // <div className="main-slide-container">
      <animated.div style={animation} className="many-slide-wrapper">
        <h1 style={{ color: "#fff" }}>BENEFIT</h1>
        <h3 style={{ color: "#fff" }}>{text.toUpperCase()}</h3>
        <div className="many-slide-wrapper-charities">
          {charities.map((charity: any, idx: number) =>
            idx <= 3 ? (
              <div
                key={charity.title}
                className="charity-background-container"
                style={{ backgroundColor: charity.background_color }}
              >
                <img
                  src={charity.logo}
                  alt={"Charity Logos"}
                  className="many-slide-logo"
                />
              </div>
            ) : null
          )}
        </div>
      </animated.div>
    // </div>
  );
};
export default Domestic;
