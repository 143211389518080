import * as React from "react";

const SvgComponent = (props) => (
  <svg width="auto" height="100%" viewBox="0 0 100.542 148.696" {...props}>
    <defs>
      <clipPath id="b" clipPathUnits="userSpaceOnUse">
        <rect
          width={100.542}
          height={148.167}
          x={-1220.518}
          y={325.364}
          rx={5.951}
          ry={5.951}
          style={{
            fill: "#fff",
            stroke: "none",
            strokeWidth: 0.879544,
            strokeDasharray: ".879544,.879544",
          }}
        />
      </clipPath>
      <clipPath id="c" clipPathUnits="userSpaceOnUse">
        <rect
          width={100.542}
          height={148.167}
          x={-1220.517}
          y={295.025}
          rx={5.951}
          ry={5.951}
          style={{
            fill: "#fff",
            stroke: "none",
            strokeWidth: 0.879544,
            strokeDasharray: ".879544,.879544",
          }}
        />
      </clipPath>
      <clipPath id="d" clipPathUnits="userSpaceOnUse">
        <rect
          width={100.542}
          height={148.167}
          x={-1220.517}
          y={264.686}
          rx={5.951}
          ry={5.951}
          style={{
            fill: "#fff",
            stroke: "none",
            strokeWidth: 0.879544,
            strokeDasharray: ".879544,.879544",
          }}
        />
      </clipPath>
      <filter
        id="a"
        width={1.027}
        height={1.084}
        x={-0.013}
        y={-0.042}
        style={{
          colorInterpolationFilters: "sRGB",
        }}
      >
        <feGaussianBlur stdDeviation={0.102} />
      </filter>
    </defs>
    <g transform="translate(-167.445 265.722)">
      <path
        d="M167.445-265.193h100.542v148.167H167.445z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.664424,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M167.445-265.722h100.542v15.875H167.445z"
        style={{
          fill: "#0f172a",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.217484,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M-114.502 694.86c-3.18-2.422-5.014-4.169-6.544-6.232-3.29-4.437-4.418-8.376-3.275-11.432.655-1.75 2.242-2.93 4.388-3.265 2.36-.367 5.329.603 6.65 2.174l.392.465.768-.74c2.092-2.017 5.668-2.561 8.33-1.269 1.667.81 2.622 2.326 2.824 4.488.354 3.794-2.721 9.46-7.295 13.438-1.357 1.18-4.43 3.57-4.59 3.569-.042 0-.784-.539-1.648-1.196z"
        style={{
          fill: "red",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.729237,
          strokeOpacity: 1,
        }}
        transform="matrix(.3575 0 0 .3575 216.015 -502.335)"
      />
      <path
        d="M-427.938 2629.735c-3.202-2.275-9.124-6.852-12.492-9.655-8.98-7.473-14.539-13.502-20.044-21.743-6.105-9.14-9.505-17.125-10.699-25.125-.274-1.834-.275-6.715-.002-8.415.78-4.865 2.425-8.464 5.166-11.309 1.802-1.87 3.444-3.046 5.89-4.216 2.244-1.073 4.81-1.808 7.587-2.172 1.821-.238 5.84-.16 7.746.15 3.792.618 7.194 1.712 10.187 3.275 2.772 1.448 4.759 2.995 6.65 5.178l1.196 1.38.008 8.807c.004 4.844.05 21.39.102 36.768.051 15.379.08 27.975.064 27.991-.017.016-.628-.395-1.358-.914z"
        style={{
          opacity: 0.99,
          fill: "#000",
          fillOpacity: 0.198653,
          stroke: "none",
          strokeWidth: 6.36737,
          strokeDashoffset: 210.392,
          strokeOpacity: 0.984314,
        }}
        transform="matrix(.0946 0 0 .0946 216.015 -502.335)"
      />
      <text
        xmlSpace="preserve"
        x={183.358}
        y={-253.753}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={183.358}
          y={-253.753}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={183.631}
        y={-253.51}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={183.631}
          y={-253.51}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <path
        d="M167.445-249.847h100.542v7.938H167.445z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.153785,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M167.445 241.381h100.542v.529H167.445z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={21.696}
        height={5.821}
        x={169.834}
        y={-248.915}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={21.696}
        height={5.821}
        x={170.091}
        y={-248.575}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={171.492}
        y={-243.996}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={171.492}
          y={-243.996}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Amazon"}
        </tspan>
      </text>
      <rect
        width={21.696}
        height={5.821}
        x={194.609}
        y={-248.575}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={21.696}
        height={5.821}
        x={219.127}
        y={-248.575}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={21.696}
        height={5.821}
        x={243.645}
        y={-248.575}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={199.284}
        y={-243.875}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={199.284}
          y={-243.875}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Deals"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={219.721}
        y={-243.875}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={219.721}
          y={-243.875}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Charities"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={247.921}
        y={-243.875}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={247.921}
          y={-243.875}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Share"}
        </tspan>
      </text>
      <g transform="translate(2477.712 -2001.898)">
        <rect
          width={100.442}
          height={26.441}
          x={-2310.217}
          y={1775.384}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#b3b3b3",
            strokeWidth: 0.0997499,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2304.566}
          y={1783.344}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.50404px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2304.566}
            y={1783.344}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "4.50404px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#1a1a1a",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"Take   extra   20%   off   select   styles"}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-2304.574}
          y={1789.266}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.8606px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2304.574}
            y={1789.266}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "3.8606px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "gray",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"ENDS         1/3/2023"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-2304.284}
          y={1792.671}
          fill="none"
          rx={2.783}
          ry={2.783}
          style={{
            fill: "#000",
            fillOpacity: 0,
            stroke: "#00c7a4",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2300.638}
          y={1796.736}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.660045px",
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 11.055,
            strokeDasharray: "33.1651,33.1651",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2300.638}
            y={1796.736}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "3.24544px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#00c7a4",
              fillOpacity: 1,
              strokeWidth: 11.055,
            }}
          >
            {"Save 20"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-1143.785}
          y={382.417}
          clipPath="url(#b)"
          rx={2.114}
          ry={5.985}
          style={{
            mixBlendMode: "normal",
            fill: "#ccc",
            fillOpacity: 0.533333,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            filter: "url(#a)",
          }}
          transform="translate(-1089.75 1410.273)"
        />
        <rect
          width={18.409}
          height={5.822}
          x={-2233.803}
          y={1792.356}
          rx={2.114}
          ry={5.985}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2232.502}
          y={1796.165}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "2.38892px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.48585px",
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8.13745,
            strokeDasharray: "24.4123,24.4123",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2232.502}
            y={1796.165}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "2.38892px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#6b21a8",
              fillOpacity: 1,
              strokeWidth: 8.13745,
            }}
          >
            {"Activate  Deals"}
          </tspan>
        </text>
      </g>
      <g transform="translate(2663.336 -1792.801)">
        <rect
          width={100.442}
          height={26.441}
          x={-2495.841}
          y={1592.828}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#b3b3b3",
            strokeWidth: 0.0997499,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2490.19}
          y={1600.788}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.50404px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2490.19}
            y={1600.788}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "4.50404px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#1a1a1a",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"10%   off   select   items"}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-2490.198}
          y={1606.71}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.8606px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2490.198}
            y={1606.71}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "3.8606px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "gray",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"ENDS         1/3/2023"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-2489.908}
          y={1610.115}
          fill="none"
          rx={2.783}
          ry={2.783}
          style={{
            fill: "#000",
            fillOpacity: 0,
            stroke: "#00c7a4",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2486.262}
          y={1614.18}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.660045px",
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 11.055,
            strokeDasharray: "33.1651,33.1651",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2486.262}
            y={1614.18}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "3.24544px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#00c7a4",
              fillOpacity: 1,
              strokeWidth: 11.055,
            }}
          >
            {"Save 10"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-1143.785}
          y={382.417}
          clipPath="url(#c)"
          rx={2.114}
          ry={5.985}
          style={{
            mixBlendMode: "normal",
            fill: "#ccc",
            fillOpacity: 0.533333,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            filter: "url(#a)",
          }}
          transform="translate(-1275.373 1227.717)"
        />
        <rect
          width={18.409}
          height={5.822}
          x={-2419.427}
          y={1609.8}
          rx={2.114}
          ry={5.985}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2418.126}
          y={1613.609}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "2.38892px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.48585px",
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8.13745,
            strokeDasharray: "24.4123,24.4123",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2418.126}
            y={1613.609}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "2.38892px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#6b21a8",
              fillOpacity: 1,
              strokeWidth: 8.13745,
            }}
          >
            {"Activate  Deals"}
          </tspan>
        </text>
      </g>
      <g transform="translate(2583.951 -1971.347)">
        <rect
          width={100.442}
          height={26.441}
          x={-2416.456}
          y={1797.915}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#b3b3b3",
            strokeWidth: 0.0997499,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <g transform="translate(-106.197 -37.53)">
          <text
            xmlSpace="preserve"
            x={-2304.566}
            y={1843.405}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "4.50404px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              wordSpacing: "-.785156px",
              fill: "#1a1a1a",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 13.1505,
              strokeDasharray: "39.4515,39.4515",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          >
            <tspan
              x={-2304.566}
              y={1843.405}
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 500,
                fontStretch: "normal",
                fontSize: "4.50404px",
                fontFamily: "Roboto Slab, serif",
                InkscapeFontSpecification: "&quot",
                fill: "#1a1a1a",
                fillOpacity: 1,
                strokeWidth: 13.1505,
              }}
            >
              {"Buy   one   get   one   free"}
            </tspan>
          </text>
          <text
            xmlSpace="preserve"
            x={-2304.574}
            y={1849.327}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "3.8606px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              wordSpacing: "-.785156px",
              fill: "#1a1a1a",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 13.1505,
              strokeDasharray: "39.4515,39.4515",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          >
            <tspan
              x={-2304.574}
              y={1849.327}
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 400,
                fontStretch: "normal",
                fontSize: "3.8606px",
                fontFamily: "Roboto Slab, serif",
                InkscapeFontSpecification: "&quot",
                fill: "gray",
                fillOpacity: 1,
                strokeWidth: 13.1505,
              }}
            >
              {"ENDS         1/3/2023"}
            </tspan>
          </text>
          <rect
            width={18.409}
            height={5.822}
            x={-2304.284}
            y={1852.732}
            fill="none"
            rx={2.783}
            ry={2.783}
            style={{
              fill: "#000",
              fillOpacity: 0,
              stroke: "#00c7a4",
              strokeWidth: 0.274687,
              strokeDasharray: ".274687,.274687",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          />
          <text
            xmlSpace="preserve"
            x={-2300.638}
            y={1856.797}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "3.24544px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              wordSpacing: "-.660045px",
              fill: "#00c7a4",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 11.055,
              strokeDasharray: "33.1651,33.1651",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          >
            <tspan
              x={-2300.638}
              y={1856.797}
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 500,
                fontStretch: "normal",
                fontSize: "3.24544px",
                fontFamily: "Roboto Slab, serif",
                InkscapeFontSpecification: "&quot",
                fill: "#00c7a4",
                fillOpacity: 1,
                strokeWidth: 11.055,
              }}
            >
              {"Bogo"}
            </tspan>
          </text>
          <rect
            width={18.409}
            height={5.822}
            x={-1143.785}
            y={382.417}
            clipPath="url(#d)"
            rx={2.114}
            ry={5.985}
            style={{
              mixBlendMode: "normal",
              fill: "#ccc",
              fillOpacity: 0.533333,
              stroke: "none",
              strokeWidth: 0.274687,
              strokeDasharray: ".274687,.274687",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              filter: "url(#a)",
            }}
            transform="translate(-1089.75 1470.334)"
          />
          <rect
            width={18.409}
            height={5.822}
            x={-2233.803}
            y={1852.417}
            rx={2.114}
            ry={5.985}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.274687,
              strokeDasharray: ".274687,.274687",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          />
          <text
            xmlSpace="preserve"
            x={-2232.502}
            y={1856.226}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "2.38892px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              wordSpacing: "-.48585px",
              fill: "#6b21a8",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 8.13745,
              strokeDasharray: "24.4123,24.4123",
              strokeDashoffset: 0,
              strokeOpacity: 1,
            }}
          >
            <tspan
              x={-2232.502}
              y={1856.226}
              style={{
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: 500,
                fontStretch: "normal",
                fontSize: "2.38892px",
                fontFamily: "Roboto Slab, serif",
                InkscapeFontSpecification: "&quot",
                fill: "#6b21a8",
                fillOpacity: 1,
                strokeWidth: 8.13745,
              }}
            >
              {"Activate  Deals"}
            </tspan>
          </text>
        </g>
      </g>
      <g transform="translate(2583.951 -1944.277)">
        <rect
          width={100.442}
          height={26.441}
          x={-2416.456}
          y={1797.915}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#b3b3b3",
            strokeWidth: 0.0997499,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2410.764}
          y={1805.875}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.50404px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2410.764}
            y={1805.875}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "4.50404px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#1a1a1a",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"$10  off"}
          </tspan>
        </text>
        <text
          xmlSpace="preserve"
          x={-2410.771}
          y={1811.797}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.8606px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.785156px",
            fill: "#1a1a1a",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 13.1505,
            strokeDasharray: "39.4515,39.4515",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2410.771}
            y={1811.797}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "3.8606px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "gray",
              fillOpacity: 1,
              strokeWidth: 13.1505,
            }}
          >
            {"ENDS         1/3/2023"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-2410.481}
          y={1815.202}
          fill="none"
          rx={2.783}
          ry={2.783}
          style={{
            fill: "#000",
            fillOpacity: 0,
            stroke: "#00c7a4",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2406.835}
          y={1819.267}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.660045px",
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 11.055,
            strokeDasharray: "33.1651,33.1651",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2406.835}
            y={1819.267}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "3.24544px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#00c7a4",
              fillOpacity: 1,
              strokeWidth: 11.055,
            }}
          >
            {"10off"}
          </tspan>
        </text>
        <rect
          width={18.409}
          height={5.822}
          x={-1143.785}
          y={382.417}
          clipPath="url(#d)"
          rx={2.114}
          ry={5.985}
          style={{
            mixBlendMode: "normal",
            fill: "#ccc",
            fillOpacity: 0.533333,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            filter: "url(#a)",
          }}
          transform="translate(-1195.947 1432.804)"
        />
        <rect
          width={18.409}
          height={5.822}
          x={-2340}
          y={1814.887}
          rx={2.114}
          ry={5.985}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.274687,
            strokeDasharray: ".274687,.274687",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2338.7}
          y={1818.696}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "2.38892px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.48585px",
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 8.13745,
            strokeDasharray: "24.4123,24.4123",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2338.7}
            y={1818.696}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "2.38892px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#6b21a8",
              fillOpacity: 1,
              strokeWidth: 8.13745,
            }}
          >
            {"Activate  Deals"}
          </tspan>
        </text>
      </g>
      <path
        d="M167.445-124.715h100.542v7.689H167.445z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.151354,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={208.947}
        y={-119.748}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "4.49792px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={208.947}
          y={-119.748}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.49792px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Settings"}
        </tspan>
      </text>
      <path
        d="M167.445 124.964h100.542v.529H167.445z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <g transform="translate(2663.023 -1808.508)">
        <rect
          width={35.393}
          height={9.208}
          x={-2482.084}
          y={1569.92}
          rx={2.227}
          ry={2.018}
          style={{
            fill: "#f9f9f9",
            stroke: "#00c7a4",
            strokeWidth: 0.257,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <g fill="none" fillRule="evenodd">
          <path
            fill="#f90"
            fillRule="nonzero"
            d="M-2485.97 1577.168c-3.376 1.607-5.472.263-6.813-.554-.083-.051-.224.012-.101.153.446.541 1.91 1.847 3.822 1.847 1.913 0 3.05-1.043 3.193-1.225.142-.18.042-.28-.101-.22zm.948-.523c-.09-.119-.551-.14-.841-.105-.29.035-.726.212-.688.319.02.04.059.022.258.004.2-.02.76-.091.877.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.096-.078.271-.282.389-.57.117-.29.188-.694.12-.783z"
          />
          <path
            fill="#000"
            d="M-2488.24 1573.89c0 .421.01.773-.203 1.147-.172.305-.444.492-.748.492-.416 0-.658-.317-.658-.784 0-.922.826-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.256.026c-.359-.298-.423-.437-.62-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.587-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.131-.784-.133-.2-.385-.282-.607-.282-.413 0-.781.212-.871.65-.018.097-.09.193-.187.198l-1.05-.113c-.089-.02-.186-.091-.162-.226.242-1.273 1.391-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.221.184-.614.526-.83.718l-.003-.003"
          />
          <path
            fill="#f90"
            fillRule="nonzero"
            d="M-2485.97 1577.168c-3.376 1.607-5.472.263-6.813-.554-.083-.051-.224.012-.101.153.446.541 1.91 1.847 3.822 1.847 1.913 0 3.05-1.043 3.193-1.225.142-.18.042-.28-.101-.22zm.948-.523c-.09-.119-.551-.14-.841-.105-.29.035-.726.212-.688.319.02.04.059.022.258.004.2-.02.76-.091.877.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.096-.078.271-.282.389-.57.117-.29.188-.694.12-.783z"
          />
          <path
            fill="#000"
            d="M-2488.24 1573.89c0 .421.01.773-.203 1.147-.172.305-.444.492-.748.492-.416 0-.658-.317-.658-.784 0-.922.826-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.256.026c-.359-.298-.423-.437-.62-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.587-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.131-.784-.133-.2-.385-.282-.607-.282-.413 0-.781.212-.871.65-.018.097-.09.193-.187.198l-1.05-.113c-.089-.02-.186-.091-.162-.226.242-1.273 1.391-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.221.184-.614.526-.83.718l-.003-.003"
          />
        </g>
        <text
          xmlSpace="preserve"
          x={-2479.309}
          y={1575.629}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.768251px",
            fill: "#999",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 12.8674,
            strokeDasharray: "38.602,38.602",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2479.309}
            y={1575.629}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 400,
              fontStretch: "normal",
              fontSize: "4.02932px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#999",
              fillOpacity: 1,
              strokeWidth: 12.8674,
            }}
          >
            {"Add  To  My  Store"}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
