import "./update-organization.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import Hoc from "../../../hoc/OrganizationSignUpHoc";

const OrganizationSignUp = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [percent, setPercent] = useState<string | null>(null);

  const handleSubmit = () => {
    // Logic to handle submission
    console.log({
      organizationName,
      organizationEmail,
      percent,
    });
  };

  console.log({
    organizationName,
    organizationEmail,
    percent,
  });

  return (
    <div className="update-organization-container">
      <h2>Create Partnership</h2>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Organization Name"
          variant="outlined"
          value={organizationName}
          onChange={(e) => setOrganizationName(e.target.value)}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Organization Email"
          variant="outlined"
          value={organizationEmail}
          onChange={(e) => setOrganizationEmail(e.target.value)}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="percent-label">Percent</InputLabel>
        <Select
          labelId="percent-label"
          id="percent-select"
          value={percent}
          label="Percent"
          onChange={(e) => setPercent(e.target.value)}
          fullWidth
        >
          {Array.from({ length: 11 }, (_, i) => i * 5).map((value) => (
            <MenuItem key={value} value={value}>
              {value}%
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!organizationName || !organizationEmail || percent === null}
      >
        Submit
      </Button>
    </div>
  );
};

export default Hoc(OrganizationSignUp);
