import { Alert, Button, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";

const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");


  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const passwordsMatch = password === confirmPassword && password !== "";

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError("");
    setSuccess(false);

    if (!passwordsMatch) {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
      return;
    }

    try {
      const response = await axios.post(
        `https://api.manyhands.charity/password-reset/${token}`,
        {
          password,
        }
      );
      if (response.data) {
        setSuccess(true);
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.data);
      } else {
        setError(
          "An unexpected error occurred during the password reset process."
        );
      }
    }
  };

  return (
    <div className="password-reset-container">
      {error && (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" style={{ marginTop: "20px" }}>
          Password reset successful. Please continue to login&nbsp;&nbsp;
          <a
            style={{
              color: "#000",
              fontSize: "15px",
              fontWeight: "600",
              textDecoration: "underline",
            }}
            href="/login"
          >
            Login
          </a>
        </Alert>
      )}
      {!success && (
        <form className="form-password-reset-container" onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Confirm New Password"
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
            fullWidth
            margin="normal"
          />
          <Tooltip
            title="Passwords do not match"
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <span>
              <Button
                style={{
                  marginTop: "20px",
                  color: !passwordsMatch ? "rgba(0, 0, 0, 0.26)" : "#fff",
                  backgroundColor: !passwordsMatch
                    ? "rgba(0, 0, 0, 0.12)"
                    : "var(--color-secondary)",
                }}
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!passwordsMatch}
              >
                Reset Password
              </Button>
            </span>
          </Tooltip>
        </form>
      )}
    </div>
  );
};

export default ResetPasswordForm;
