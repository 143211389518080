import React from "react";

interface IEmailDisplacy {
  link: string;
  organizationName: string;
}

const EmailDisplay: React.FC<IEmailDisplacy> = ({ link, organizationName }) => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: 1.6,
      }}
    >
      <div
        style={{
          width: "80%",
          margin: "20px auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <h4 style={{ fontWeight: "600" }}>
          Empower Our Cause with Every Purchase via Many Hands
        </h4>
        <br />
        <p>Hello,</p>
        <br />
        <p>
          We are excited to introduce <strong>Many Hands</strong>, an AI-driven
          Chrome extension that turns your everyday online shopping into a
          powerful fundraising tool for our cause. Simply by shopping, you help
          generate funds for our organization while enjoying savings.
        </p>
        <br />
        <h4 style={{ fontWeight: "600" }}>Why Many Hands?</h4>
        <br />
        <ul>
          <li>
            <strong>AI-Driven Savings:</strong> Access the best deals and
            discounts with our AI-powered search tools.
          </li>
          <li>
            <strong>Support Our Cause:</strong> Automatically direct 80% of
            affiliate revenue from your purchases to us.
          </li>
          <li>
            <strong>Privacy and Security:</strong> Shop with confidence—your
            personal information is safe; Many Hands neither sells nor stores
            your data.
          </li>
        </ul>
        <br />
        <h4 style={{ fontWeight: "600" }}>Get Started Now:</h4>
        <ul>
          <li>
            <strong>Install the Extension:</strong> Easily add Many Hands to
            Chrome
            <a
              style={{
                backgroundColor: "#6b21a8",
                color: "white",
                border: "none",
                padding: "5px 20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "0px 2px",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              here
            </a>
            .
          </li>
          <li>
            <strong>Select Us as Your Charity:</strong> We're pre-selected as
            your beneficiary to ensure you start supporting us right away.
          </li>
          <li>
            <strong>Shop and Make a Difference:</strong> Each purchase or
            extension link click supports our initiatives.
          </li>
        </ul>
        <br />
        <p>
          Thank you for making a significant impact just by shopping online!
        </p>
        <p>Best regards,</p>
        <br />
        <p>
          <strong>{organizationName}</strong>
          <br />
        </p>
      </div>
    </div>
  );
};

export default EmailDisplay;
