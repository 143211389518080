import * as actionTypes from "../types/login";

import {
  ORGANIZATION_PATCH_ERROR,
  ORGANIZATION_PATCH_FULFILLED,
  UPDATE_ORGANIZATION_ERROR,
} from "../types/charity";

const initialState = {
  user_info: {},
  login_error: "",
  update_organization: null,
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.LOGIN_FULFILLED:
      return {
        ...state,
        user_info: action.payload,
        login_error: false,
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        login_error: true,
      };
    case ORGANIZATION_PATCH_FULFILLED:
      return {
        ...state,
        user_info: { ...state.user_info, charity: action.payload },
        update_organization: true,
      };
    case ORGANIZATION_PATCH_ERROR:
      return {
        ...state,
        update_organization: false,
      };
    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        update_organization: action.payload,
      };
      
    case actionTypes.COMPLETE_ORGANIZATION_INFORMATION_FULFILLED:
      return {
        ...state,
        user_info: { ...state.user_info, charity: action.payload },
        update_organization: true,
      };
    case actionTypes.COMPLETE_ORGANIZATION_INFORMATION_ERROR:
      return {
        ...state,
        update_organization: false,
      };
    default:
      return state;
  }
};
