import "../components/sections/charity-landing-page/style.css";

import React, { useEffect } from "react";

import Banner from "../components/sections/charity-landing-page/banner/Banner";
import Contact from "../components/sections/charity-landing-page/contact/Contact";
import Demo from "../components/sections/charity-landing-page/demo/Demo";
import Description from "../components/sections/description/Description";
import Fundraising from "../components/sections/charity-landing-page/fundraising/Fundraising";
import Header from "../components/sections/charity-landing-page/header/header";
import { Helmet } from "react-helmet";
import Merchants from "../components/sections/merchant/Merchant";
import Organizations from "../components/sections/charity-landing-page/discounts/Discounts";
import Partner from "../components/sections/charity-landing-page/partner/Partner";
import RevenueEstimate from "../components/sections/charity-landing-page/revenue-estimate/estimate";
import RevenueGeneratedContainer from "../components/sections/charity-landing-page/revenue-generated/Container";
import SmallOrganization from "../components/sections/charity-landing-page/Small-organization/SmallOrg";

interface ICharityLandingPage {}

const CharityLandingPage: React.FC<ICharityLandingPage> = ({}) => {
  useEffect(() => {
    const location = window.location;
    const urlParams: any = new URLSearchParams(location.search);
    const id = urlParams.get("contact_id");
    document.cookie = `charity_contact_id=${id}`;
  }, []);

  return (
    <>
      <Helmet>
      <meta property="og:type" content="website" />
        <title>Many Hands: Fundraiser</title>
        <meta
          name="description"
          content="Many Hands Fundraiser Page Description"
        />
        <meta property="og:title" content="Many Hands Fundraiser Page" />
        <meta
          property="og:description"
          content="Many Hands Fundraiser Page Description"
        />

        <meta
          property="og:image"
          content="https://media.manyhands.charity/static/share/fundraiser.png"
        />
        <meta property="og:image:alt" content="Many Hands Charity" />
        <meta
          property="og:image:secure_url"
          content="https://media.manyhands.charity/static/share/fundraiser.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <Header />
      <div className="landing-page-body-container">
        <Description
          style={{ paddingTop: "60px" }}
          text_one={`Many Hands, an AI-driven Chrome extension, offers substantial discounts to users while donating 80% of its revenue to organizations like yours. `}
          text_two={`✏️ Sign up | 🤝 Share | 💰 Earn. Get up and running in minutes.`}
        />
        <RevenueEstimate />
        <Fundraising />
        <RevenueGeneratedContainer />
        <Merchants
          button_text={`Start Fundraising`}
          button_link={"/fundraiser-sign-up"}
        />
        <Demo />
        <Organizations />
        <SmallOrganization />
        <Banner />
        <Partner />
        <Contact />
      </div>
    </>
  );
};

export default CharityLandingPage;
