export const TermsArray = [
  {
    title: "MANY HANDS TERMS OF USE",
    paragraphs: [
      "Last Updated: April 1, 2024",
      "ACCEPTANCE OF TERMS",
      'By using our website or clicking "Accept," you agree to these Terms of Use, which include our Privacy Policy. If you disagree with any part of these terms, please discontinue using the Many Hands platform and any related services immediately.',
    ],
  },

  {
    title: "ABOUT MANY HANDS",
    paragraphs: [
      'Welcome to ManyHands.com, provided by Many Hands LLC (“Many Hands” or "we"). Our website and related services, including the Many Hands website, mobile applications, and various integrations and tools (collectively, the "Many Hands Platform") are available to you, provided you agree to these Terms of Use. These terms govern all aspects of your interaction with the Many Hands Platform, including, but not limited to, accessing, browsing, and contributing content or using the services provided through the Many Hands Platform.',
    ],
  },
  {
    title: "AMENDMENTS TO TERMS OF USE",
    paragraphs: [
      "We reserve the right to update or change these Terms of Use at our discretion and without prior notice. Whenever we modify these terms, we will post the revised version and update the “Last Updated” date. It is your responsibility to check these Terms of Use regularly for changes. The latest version of the Terms of Use will always be available on our website. Your continued use of the Many Hands Platform after any changes to these Terms of Use means you accept those changes.",
    ],
  },
  {
    title: "IMPORTANT AGREEMENTS",
    paragraphs: [
      "These Terms of Use include critical information about your rights and responsibilities, as well as exclusions and limitations of liability, and include the following specific clauses:",
    ],
    items: [
      "Disclaimers",
      "Limitations of Liability",
      "Indemnification by You",
      "Governing Law and Jurisdiction",
      "Waiver of Class Action Rights",
    ],
    paragraphsTwo: [
      "By accepting these Terms of Use, you acknowledge that you have read and understood these provisions.",
    ],
  },
  {
    title: "GLOBAL TERMS – ALL MANY HANDS APPS & SERVICES OVERVIEW OF SERVICES",
    paragraphs: [
      "Many Hands offers a digital platform designed to support fundraising activities. Users, referred to as “Fundraisers,” utilize the Many Hands Platform and associated services to generate funds for various causes. Fundraisers encourage supporters (“Supporters”) to participate in Fundraising Campaigns. A Fundraising Action involves activities such as clicking affiliate links and purchasing affiliate products.",
    ],
  },
  {
    title: "AIM OF SERVICES",
    paragraphs: [
      "The goal of our services is to empower communities and organizations seeking to make a positive impact on society (the “Purpose”). Our services facilitate Fundraisers in creating Teams with Partners, promoting Vouchers, and soliciting donations through customized content (“Content”) on the Many Hands Platform. All users, including Fundraisers, Supporters, and other collaborators (collectively, “Users” or “you”), can contribute to this Content by posting comments and submitting materials for inclusion.",
    ],
  },
  {
    title: "PRIVACY",
    paragraphs: [
      "Our Privacy Policy, which outlines how we handle Users’ personal information, is incorporated by reference into these Terms of Use and is accessible on our website. We take privacy seriously and do not intentionally collect personal data from individuals under 18 years old. Users have rights concerning their personal information, including the right to understand how their information is collected, used, disclosed for business purposes. Users may also request that we cease sharing their information, or ask that we delete any personal data we hold on them, unless we need to retain certain information for legitimate business purposes or are legally permitted to keep it. For all privacy-related requests, please contact info@manyhands.charity.",
    ],
  },
  {
    title: "SERVICE AVAILABILITY",
  },
  {
    title: "Terms Acceptance",
    paragraphs: [
      "The services provided by Many Hands are available to Users who accept all terms and conditions set forth in these Terms of Use, along with any additional terms that may apply to specific services, such as those relevant to Partners, all of which are incorporated herein by reference.",
    ],
  },
  {
    title: "Eligibility",
    paragraphs: [
      "The Services are accessible to: (i) individuals who are of legal age in their jurisdiction to enter into a binding contract with Many Hands, or those under legal age who have obtained consent from a parent or guardian; and (ii) individuals and organizations that have the capacity to enter into legally binding contracts.",
    ],
  },
  {
    title: "Age Restrictions",
    paragraphs: [
      "Despite any other provisions in these Terms or on the Many Hands Platform, individuals under the age of 18 are not allowed to use the Many Hands Platform or the Services. They are not permitted to register on the Many Hands Platform using their own personal details (e.g., name, date of birth, physical or email address, or other contact information). Furthermore, they cannot become Fundraisers, post content, or supply any personal information to us.",
    ],
  },
  {
    title: "Modification of Services",
    paragraphs: [
      "Many Hands reserves the right to modify or discontinue the Services (or any part thereof) at any time with or without notice. While not obligated to update the Many Hands Platform, we make no representation or warranty that specific Content will remain available at any particular time, or that you will be able to access the platform or the Services at all times.",
    ],
  },
  {
    title: "Additional Terms and Third-Party Partners",
    paragraphs: [
      "Your access to or use of the Services may subject you to additional terms, rules, policies, and conditions imposed by us (“Additional Terms”), which are hereby incorporated by reference into these Terms. In cases of inconsistency between these Terms and any Additional Terms, these Terms will prevail unless expressly stated otherwise by us.",
    ],
  },
  {
    title: "OWNERSHIP OF THE MANY HANDS PLATFORM",
  },
  {
    title: "Proprietary Rights",
    paragraphs: [
      "The Many Hands Platform and Services, including the infrastructure that supports them, are proprietary and owned by Many Hands. The Many Hands Platform, Services, and all Content contained therein are protected by copyrights, trademarks, patents, service marks, international treaties, and other proprietary rights and laws both in the United States and internationally. As a visitor to the Many Hands Platform, you are granted a non-exclusive, non-transferable, revocable, limited license to access and use the Many Hands Platform and Content (including any updates) in accordance with these Terms of Use. This license may be terminated by us at any time without notice for any reason. You agree not to modify, copy, distribute, transmit, display, reproduce, publish, create derivative works from, transfer, or sell any information, software, products, or services obtained from or through the Many Hands Platform or Services, except as explicitly allowed under these Terms of Use. Unauthorized use results in the automatic termination of this license.",
    ],
  },
  {
    title: "Transmission of Information",
    paragraphs: [
      "Given that the security of the Internet and other networks used to access the Many Hands Platform or communicate with us is beyond our control, Many Hands and our Partners cannot be responsible for the security of information transmitted via these channels. We are not liable for any data loss during its transmission.",
    ],
  },
  {
    title: "User Generated Content",
    paragraphs: [
      `You acknowledge that all materials posted or made available on the Many Hands Platform, including but not limited to, text, images, video, and audio content ("Submissions"), are the sole responsibility of the originating individual. This means you are fully responsible for all Submissions you upload, post, email, transmit, or otherwise make available via the Many Hands Platform.
      By posting Submissions on or through the Many Hands Platform, you grant Many Hands a royalty-free, perpetual, irrevocable, sublicensable, non-exclusive license to use, reproduce, modify, publish, edit, translate, distribute, perform, and display such Submissions alone or as part of other works in any form, media, or technology, whether now known or hereafter developed, and to sublicense such rights. This license extends to the use of your Submissions for promotions, advertising, market research, or any other lawful purpose, without limitation. You also waive any moral rights you may have in such Submissions and assert that your Submissions are your original work, or you have the necessary licenses from the original owner, and do not violate the law or the rights of any third party.
      Many Hands and our Partners do not pre-screen Submissions, and therefore do not guarantee the accuracy, integrity, or quality of Submissions. By using the Service, you may encounter Submissions that you find offensive, indecent, or objectionable. Under no circumstances will Many Hands or our Partners be liable for any Submissions, including, but not limited to, any errors or omissions in any Submissions, or any loss or damage of any kind incurred in connection with the use of or exposure to any Submissions posted, emailed, transmitted, or otherwise made available via the Service. Submissions are public and not private communications.
      `,
    ],
  },
  {
    title: "COPYRIGHT INFRINGEMENT",
  },
  {
    title: "Handling Copyright Infringement Claims",
    paragraphs: [
      `Many Hands takes copyright infringement claims very seriously and will respond to notices of alleged copyright infringement in accordance with applicable laws.
      Many Hands and our Partners do not pre-screen Submissions, and therefore do not guarantee the accuracy, integrity, or quality of Submissions. By using the Service, you may encounter Submissions that you find offensive, indecent, or objectionable. Under no circumstances will Many Hands or our Partners be liable for any Submissions, including, but not limited to, any errors or omissions in any Submissions, or any loss or damage of any kind incurred in connection with the use of or exposure to any Submissions posted, emailed, transmitted, or otherwise made available via the Service. Submissions are public and not private communications.
      `,
    ],
  },
  {
    title: `Notifying Many Hands of Infringement`,
    paragraphs: [
      `If you believe that your copyright has been infringed on the Many Hands Platform, please direct your notice to us via email at: info@manyhands.charity. Include a detailed description of the alleged infringement in your notice.
      `,
    ],
  },
  {
    title: `Enforcement and Rights`,
    paragraphs: [
      `Many Hands reserves the right to remove any content that allegedly infringes another party's copyright. We will terminate the accounts of repeat infringers in appropriate circumstances.
      `,
    ],
  },
  {
    title: `DMCA Compliance`,
    paragraphs: [
      `For claims of copyright infringement within the United States, Many Hands complies with the provisions of the Digital Millennium Copyright Act (DMCA), 17 U.S.C. §§ 101 et seq. Our designated agent is registered with the United States Copyright Office in accordance with the DMCA.
      `,
    ],
  },
  {
    title: `To File a Notice of Infringement, Include the Following:`,
    items: [
      `a) Your name, address, telephone number, and email address;`,
      `b) A description of the copyrighted work you claim has been infringed;`,
      `c) The location on the Many Hands Platform of the allegedly infringing material, sufficiently detailed for us to locate the material (e.g., URL);`,
      `d) A statement of your good faith belief that the disputed use is not authorized by the copyright owner, its agent, or applicable law;`,
      `Waiver of Class Action Rights`,
      `e) A statement, under penalty of perjury under applicable law, that the information in your notice is accurate and that you are the copyright owner or are authorized to act on the owner's behalf;
      `,
      `f) Your electronic or physical signature.`,
    ],
  },
  {
    title: `Filing a DMCA Counter-Notice to Restore Removed Content:
    `,
    paragraphs: [
      `If you believe your content was removed from the Many Hands Platform by mistake or misidentification, please provide us with a counter-notification including:
        `,
    ],
    items: [
      `a) Your name, address, telephone number, and email address;
      `,
      `b) A description of the material that was removed;`,
      `c) Where the material appeared on the Many Hands Platform before it was removed (e.g., URL);
      `,
      `d) A statement of your good faith belief that the material was removed or disabled as a result of mistake or misidentification;`,
      `Waiver of Class Action Rights`,
      `e) A statement, under penalty of perjury under applicable law, that the information in your counter-notice is accurate;
      `,
      `f) A statement consenting to the jurisdiction of the U.S. District Court for the judicial district where your address is located, or if outside the United States, for any judicial district in which Many Hands may be found, and that you will accept service of process from the person who provided the original infringement notification or an agent of that person;
      `,
      `g) Your electronic or physical signature.
      `,
    ],
  },
  {
    title: "TRADEMARKS",
  },
  {
    title: `Usage Restrictions`,
    paragraphs: [
      `You agree not to display or use in any manner trademarks, service marks, logos, trade dress, slogans, or other brand features, either registered under the laws of the United States, or any other country or jurisdiction, or unregistered and existing at common law, belonging to Many Hands (the “Many Hands Marks”) unless using provide marketing material found in user Admin Portal. Merchant and applicable Team logos and trademarks are used within the Many Hands Platform and Services with the express consent of each of the Merchants and Team Members who create the Teams. Such logos and trademarks are protected by each applicable party and should not be reproduced or downloaded without prior consent from the applicable party.
      `,
    ],
  },
  {
    title: `Infringement Claims`,
    paragraphs: [
      `If you believe in good faith that any of your registered trademarks or service marks have been copied in a way that constitutes trademark infringement under applicable law, please provide us with a written notice containing the following:`,
    ],
    items: [
      `a) Your name, address, telephone number, and email address;
      `,
      `b) A description of the trademark you claim to be infringed, including the registration number, jurisdictions in which the trademark is registered, and the categories of goods and/or services covered by the registration;
      `,
      `c) A description of where on the Many Hands Platform the trademark you claim is infringing may be found, sufficient for Many Hands to locate the material (e.g., the URL);
      `,
      `d) A statement that you have a good faith belief that the use of the trademark is not authorized by the trademark owner, registration, or licensee or their respective agents, or applicable law;
      `,
      `e) A statement by you under penalty of perjury under applicable law that the information in your notice is accurate and that you are the trademark registrant or licensee or authorized to act on the copyright owner’s behalf; and
      `,
      `f) Your electronic or physical signature.
      `,
    ],
  },
  {
    title: `Content Removal and Notifications`,
    paragraphs: [
      `If we remove your Content because of a trademark infringement report, you will receive a notification from us that includes the name and email of the rights holder who made the report and/or the details of the report. If you believe the content should not have been removed, you can follow up with the rights holder directly to try to resolve the issue. Many Hands does not provide a counter-notice or appeal mechanisms for claims of trademark infringement, and disclaims any liability for claims you might make regarding mistaken or misidentified trademark infringement notice and takedown requests.`,
    ],
  },
  {
    title: `USER CONDUCT`,
  },
  {
    title: `Platform Guidelines`,
    paragraphs: [
      `The Many Hands platform is private property. All interactions on the Many Hands platform must be lawful and must comply with these Terms of Use. If your behavior, as determined by us in our sole discretion, does not comply, including actions that restrict or inhibit other Users' enjoyment of our platform, we may limit your use of the Services and pursue other remedies.
      `,
    ],
  },
  {
    title: `Prohibited Activities`,
    paragraphs: [
      `You agree to use the Services only in ways that are consistent with the stated Purpose and to refrain from using the Services to:
      `,
    ],
    items: [
      `Upload, post, email, transmit, or otherwise make available any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, racially, ethnically, or otherwise objectionable;
      `,
      `Harm or attempt to harm minors in any way;
      `,
      `Upload personal information, including photographs or contact information, of individuals under 18 years of age without consent from their legal guardian;
      `,
      `Falsely impersonate any person or entity, or falsely state or misrepresent your affiliation with a person or entity;
      `,
      `Forge headers or otherwise manipulate identifiers to disguise the origin of any content transmitted through the Service;
      `,
      `Upload, post, email, transmit, or otherwise make available any material that you do not have a right to make available under any law or under contractual or fiduciary relationships;
      `,
      `Infringe any patent, trademark, trade secret, copyright, or other proprietary rights of any party;
      `,
      `Send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of illegal solicitation;
      `,
      `Upload, post, email, transmit, or otherwise make available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software, hardware, or telecommunications equipment;
      `,
      `Interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies, or regulations of networks connected to the Service;
      `,
      `Violate any applicable local, state, national, or international laws, including, but not limited to, regulations promulgated by any securities exchange;
      `,
      `Collect or provide funds for any illegal activities, including terrorist activities or money laundering;
      `,
      `Stalk, harass, or harm another individual;
      `,
      `Collect or store personal data about other users without their express consent;
      `,
      `Attempt to override or circumvent any usage rules embedded into the Service;
      `,
      `Take any action that imposes or may impose an unreasonable or disproportionately large load on the Many Hands platform’s infrastructure;
      `,
      `Interfere or attempt to interfere with the proper working of the Service;
      `,
      `Bypass any measures used to prevent or restrict access to the Service;
      `,
      `Use automated or manual means to crawl or spider any part of the Many Hands Platform;`,
      `Commit click fraud, or access the Service by any means other than the interface provided by Many Hands, unless specifically allowed in a separate agreement.
      `,
    ],
  },
  {
    title: `Enforcement Actions`,
    paragraphs: [
      `If Many Hands suspects, at its sole discretion, that the Service has been used unlawfully, we reserve the right to terminate the appropriate User's access to the Many Hands Platform and Services and to report such use to the relevant authorities.
      `,
    ],
  },
  {
    title: `OBLIGATIONS OF FUNDRAISERS AND GROUPS`,
  },
  {
    title: `Approval and Verification`,
    paragraphs: [
      `Groups are subject to approval by Many Hands and/or participating Merchants at the sole discretion of Many Hands. Groups and their payment beneficiaries will be evaluated and approved in accordance with Many Hands Group & Payment Beneficiary Approval Guidelines, and sometimes with the consent of the participating Merchant. Many Hands and Merchants reserve the right to contact any entity listed as a beneficiary of the raised funds to request information that will enable Many Hands and the participating Merchant to approve and verify the legitimacy of the Group. Such proposed beneficiaries may decline the funds raised or intended to be raised, and at any time thereafter, any current or prospective payment beneficiary may opt out of receiving future funds at any time by emailing us at info@manyhands.charity.
      `,
    ],
  },
  {
    title: `Conditions for Fundraisers`,
    paragraphs: [
      `If you are a Fundraiser, your use of the Services is subject to the following additional terms and conditions:
      `,
    ],
    items: [
      `You must be a registered User to create a Group. You must use a valid email address to create a Group and not use another User's account. Keep your email address current in your User settings.
    `,
      `As a Fundraiser, you warrant the validity and accuracy of the Group as portrayed on the Many Hands Platform and agree not to mislead Users about your fundraising purpose.
    `,
      `If your Group supports a third party, you must provide accurate, complete, and updated information about that third party.
      `,
      `You must provide full and complete payment information to direct Many Hands or its designees to send final payment. This information may include name and address. If you are providing payment details for a third party, you must obtain their consent to do so. You may use your personal address to collect funds on behalf of a third party, but Many Hands reserves the right to directly verify receipt of the payment with the third party within one month of payment issuance.
      `,
    ],
  },
  {
    title: `Payment Beneficiaries`,
    paragraphs: [
      `Beneficiaries of funds raised must meet the requirements outlined in the Many Hands Groups & Payment Beneficiary Approval Guidelines to be approved. If your Group has raised funds but your Payment Beneficiary does not meet these guidelines, Many Hands will contact you via email with the reason for denial. You will then have the opportunity to submit an alternate beneficiary for consideration. If the second Payment Beneficiary also does not meet our guidelines, and you have already raised funds, those funds will be redirected to another approved Group on the Many Hands platform. Only when your payment beneficiary is approved will you be permitted to withdraw any funds raised.`,
    ],
  },
  {
    title: `MEMBER ACCOUNT, PASSWORD & SECURITY
    `,
  },
  {
    title: `Account Registration`,
    paragraphs: [
      `Certain features and areas of the Many Hands Platform may require you to register, create an account, and become a member. To register, you will need to provide certain information, including your name, email address, a username or ID, password, and possibly other uniquely identifying details (collectively, “Registration Information”). You agree to ensure that your Registration Information is always true, accurate, complete, and up-to-date. Your Registration Information and certain other personal details are governed by our Privacy Policy, which details how we collect, use, and share your information.
      `,
    ],
  },
  {
    title: `Account Responsibility`,
    paragraphs: [
      `You are solely responsible for maintaining the confidentiality of your account, including your password, and for all activities that occur under your account. You agree not to share your login credentials with anyone, use another person’s identity, name, or email address for registration or use of the Many Hands Platform, or use an email address or other designation that is profane, offensive, or otherwise inappropriate. You are also responsible for preventing any unauthorized use of your account. Should any unauthorized access or security breach occur, you are required to notify Many Hands immediately. Many Hands, our Merchants, and other Users and partners will not be liable for losses or damages arising from your failure to protect your account credentials or handle your account responsibly.`,
    ],
  },
  {
    title: `Ownership of User ID`,
    paragraphs: [
      `Your username or ID is the property of Many Hands or its affiliates and is considered part of your account information, not personal information.
      `,
    ],
  },
  {
    title: `Account Activity`,
    paragraphs: [
      `You are responsible for all activities and content posted under your account.
      `,
    ],
  },
  {
    title: `CLOSING AN ACCOUNT GROUP`,
    paragraphs: [
      `Groups can be closed at any time by the account owner. During this process, the owner can request payments be sent to the designated payment beneficiary or that earnings be disbursed to the individual who generated them. Any pending funds at the time of account group closure will be disbursed once they have cleared.
      `,
    ],
  },
  {
    title: `WEBSITES OF OTHERS`,
    paragraphs: [
      `The Many Hands Platform contains links to websites maintained by third parties. These links are provided solely for convenience and not as an endorsement of the contents on such websites. Many Hands expressly disclaims any representations regarding the content or accuracy of materials on these third-party websites or their privacy practices. If you decide to access third-party websites, you do so at your own risk and according to the terms of use and privacy policies of those websites.
      `,
    ],
  },
  {
    title: `DISCLAIMER`,
  },
  {
    title: `General Conditions`,
    paragraphs: [
      `To the extent not prohibited by applicable law, the Many Hands Platform, the Service, the Content, and Submissions are provided on an "AS IS, AS AVAILABLE" basis. Many Hands, along with our payment card networks and payment processors, expressly disclaims all warranties, including the warranties of merchantability, merchantable quality, title, fitness for a particular purpose, and non-infringement. Many Hands disclaims all responsibility for any loss, injury, claim, liability, or damage of any kind resulting from, arising out of, or in any way related to:
      (a) Any errors in or omissions from the Many Hands Platform, the Service, the Content, and the Submissions including, but not limited to, technical inaccuracies and typographical errors,
      (b) Third-party communications,
      (c) Any third-party websites or content therein directly or indirectly accessed through links on the Many Hands Platform, including but not limited to any errors in or omissions therefrom,
      (d) The unavailability of the Many Hands Platform, the Service, the Content, the Submissions, or any portion thereof,
      (e) Your use of the Many Hands Platform, the Service, the Content, or the Submissions, or
      (f) Your use of any equipment or software in connection with the Many Hands Platform, the Service, the Content, or the Submissions.
      Many Hands does not make any representations, warranties, or guarantees with respect to any of the merchandise, products, or services featured, mentioned, described, auctioned, distributed, given away, rented, sold, or otherwise available on or through the Many Hands Platform or Service.
       `,
    ],
  },
  {
    title: `Limitation of Liability`,
    paragraphs: [
      `To the extent not prohibited by applicable law, Many Hands and its suppliers (including payment card networks and payment processors) shall not be liable for any loss, injury, claim, liability, or damage of any kind resulting from your use of the Many Hands Platform, the Service, the Content, the Submissions, or any third-party communications. Many Hands and its suppliers shall not be liable for any special, direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever (including, without limitation, attorneys’ fees) in any way due to, resulting from, or arising in connection with the use of or inability to use the Many Hands Platform, the Service, the Content, the Submissions, or any third-party communications. To the extent the foregoing limitation of liability is prohibited or fails of its essential purpose, Many Hands’ and its suppliers' sole obligation to you for damages shall be limited to $100.00 in the aggregate.`,
    ],
  },
  {
    title: `Indemnification/Release`,
    paragraphs: [
      `You agree to defend, indemnify, and hold harmless Many Hands, its parents, subsidiaries, affiliates, and their respective present and future directors, officers, licensors, suppliers, and vendors (including payment card networks and payment processors), third-party information providers, employees, agents, successors, and assigns from and against all claims, losses, expenses, damages, and costs, including legal fees, arising out of or related to any violation of these Terms of Use by you, any Submissions uploaded or submitted by you, and/or any false, infringing or misleading information that you submit to the Many Hands Platform.
      `,
    ],
  },
  {
    title: `Class Action Waiver`,
    paragraphs: [
      `You and Many Hands agree that, to the extent not prohibited by applicable law, any proceedings to resolve or litigate any dispute arising hereunder will be conducted solely on an individual basis, and that you will not seek to have any dispute heard as a class action, a representative action, a collective action, a private attorney-general action, or in any proceeding in which you act or propose to act in a representative capacity. You further agree that no proceeding will be joined, consolidated, or combined with another proceeding without the prior written consent of Many Hands and all parties to any such proceeding.
      `,
    ],
  },
  {
    title: `SPECIAL ADMONITIONS FOR INTERNATIONAL USE`,
    paragraphs: [
      `Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and the transmission of technical data exported from the United States or the country in which you reside.`,
    ],
  },
  {
    title: `GENERAL PRACTICES REGARDING USE, STORAGE, AND ACCOUNT TERMINATION
    `,
    paragraphs: [
      `You acknowledge that Many Hands may establish general practices and limits concerning the use of the Service. You agree that Many Hands has no responsibility or liability for the deletion or failure to store any messages, communications, or other Content maintained or transmitted by the Service. Many Hands reserves the right to terminate accounts and discard any Submissions for any reason, including but not limited to:
      `,
    ],
    items: [
      `Violations of these Terms of Use or the service's intended purposes;
        `,
      `Requests by law enforcement or other government agencies;`,
      `User-initiated account deletions;
      `,
      `Discontinuance or material modification of the Service;
      `,
      `Unexpected technical or security issues;`,
      `Compliance with legal processes;
      `,
      `Engagement in illegal activities, including fraud;
      `,
      `Twelve months of continuous inactivity.
      `,
    ],
    paragraphsTwo: [
      `To maintain an active account, you must engage in transactions or withdrawals within a 12-month period. Accounts failing to meet this criterion may be declared inactive and terminated. Many Hands may reallocate any accumulated benefits or earnings at its discretion.
        `,
    ],
  },
  {
    title: `CANCELLATION`,
    paragraphs: [
      `To close your account, please notify us in writing by sending an email to our Customer Support Center at info@manyhands.charity. While we assist with managing your subscriptions and account closure, we may not be able to delete records of past interactions and transactions due to financial reporting and compliance requirements.
      We will retain your information as long as your account is active or as needed to provide you services and maintain a record of your transactions for financial reporting. If you wish to cancel your account or request that we no longer use your information to provide services, please contact our Customer Support Center. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
      `,
    ],
  },
  {
    title: `NO RIGHT OF SURVIVORSHIP AND NON-TRANSFERABILITY
    `,
    paragraphs: [
      `You agree that your Many Hands account is non-transferable and any rights to your username/ID or submissions within your account terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all submissions may be permanently deleted. This policy also applies to any funds deposited or earned through Many Hands’ services.
      `,
    ],
  },
  {
    title: `REMEDIES FOR VIOLATIONS
    `,
    paragraphs: [
      `Many Hands reserves the right to seek all remedies available at law and in equity for violations of these Terms of Use, including but not limited to the right to block access from a particular internet address to the Many Hands platform and any other related websites and their features.
      `,
    ],
  },
  {
    title: `GOVERNING LAW
    `,
    paragraphs: [
      `Unless otherwise required by applicable mandatory local law, these Terms of Use are governed as follows: (i) for users domiciled outside of the United States, these Terms of Use are governed by and construed in accordance with the internal laws of the State of Colorado, without reference to its principles of conflicts of laws, and any action arising out of or relating to these Terms of Use shall be filed only in the courts located in Denver, Colorado; and (ii) for users domiciled in the United States, these Terms of Use are governed by and construed in accordance with the internal laws of the State of Colorado, and any action arising out of or relating to these Terms of Use shall be filed only in the state or federal courts located in Denver County, Colorado. You hereby consent and submit to the exclusive personal jurisdiction of such courts for the purpose of litigating any such action.
      `,
    ],
  },
  {
    title: `WAIVER AND SEVERABILITY OF TERMS

    `,
    paragraphs: [
      `The failure of Many Hands to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. If any provision is found by a court of competent jurisdiction to be invalid, the other provisions shall remain in full force and effect. A waiver of any provision of this Agreement shall only be valid if provided in writing and shall only be applicable to the specific incident and occurrence so waived.
      `,
      `Questions about the Terms of Use should be sent to info@manyhands.charity.
      `,
    ],
  },
  {
    title: `MANY HANDS FUNDRAISING SERVICE TERMS`,
  },
  {
    title: `EARNED REVENUE`,
    paragraphs: [
      `(i) Revenue earned through the use of the Service to support a fundraising group is considered Earned Revenue and represents the exact amount that will be received by the fundraising group. The determination of whether a purchase made through a merchant qualifies for Earned Revenue is at the sole discretion of Many Hands. If a merchant fails to report a transaction to Many Hands or fails to make payment for any reason, Many Hands reserves the right to cancel the Earned Revenue associated with that transaction.
      (ii) Earned Revenue is detailed on each Fundraising Action.
      (iii) The sum of all Earned Revenue will be displayed in the Fundraiser’s “Payment” tab, which will also indicate when a payment has been sent to the payment recipient. Earned Revenue is considered to be earned when it is displayed on the Payment tab, subject to confirmation by relevant external commercial party.
      (iv) At our sole discretion, we may, without notice, deduct Earned Revenue from your funds to make adjustments for returns and cancellations of purchases made at merchants through our platform. Any such adjustments will be made in accordance with this Agreement, any applicable Many Hands policies and terms, the terms of the merchant offer, and any and all applicable laws, rules, and regulations. Many Hands decisions are final. Should you disagree with any adjustments made to your account or payments made to you, your sole remedy is to withdraw from the Service.
      (v) It is your responsibility to check your account regularly to ensure that Earned Revenue has been properly credited and that your balance is accurate. If you believe that Earned Revenue has not been correctly credited, you must submit a claim from your account.
      (vi) The “Goal Meter” will display the amount raised through actions in support of the fundraising. In some cases, the Goal Meter may be an approximation of the Earned Revenue as a result of adding to the Goal Meter as soon as a support action is taken. If a support action must be confirmed or verified at a later date, there may be a discrepancy between the Goal Meter and the Earned Revenue. In any such case, the amount shown in the Payment tab supersedes the amount shown in the Goal Meter.
      (vii) From time to time, the amount displayed in the Goal Meter and/or in the Payment tab may be inaccurate as a result of error. Many Hands reserves the right to correct any such error and will not be obligated to pay out any erroneously reported Earned Revenue.
      `,
    ],
  },
  {
    title: `TRANSACTIONAL TERMS
    `,
    paragraphs: [
      `(i) Earned Revenue will be paid in the relevant currency based on the geographic area and the currency in which the transaction is completed.
      (ii) Earned Revenue will be disbursed to the approved Payment Recipient as noted in the “Payments” tab of the fundraiser’s account.
      (iii) Uncashed checks, or checks returned to Many Hands, will be credited back to your fundraising account, subject to a $40 check re-processing fee.
      (iv) Requests to change the payee name or address after check processing will incur a $40 fee.
      (v) Reissuing lost checks may also be subject to a $40 re-processing fee.
      (vi) Withdrawal requests for funds under $100 will be honored, less any credits earned during the campaign period.
      `,
    ],
  },
  {
    title: `BUYERS
    `,
    paragraphs: [
      `If you support a fundraising initiative by taking an action through the Services, you become a Buyer, subject to the following obligations in addition to all other terms and conditions of these Terms of Use:
      (i) All fundraising actions are at your own risk. Many Hands does not guarantee that your support will be used as prescribed by the fundraiser. We do not verify the compliance of these uses with applicable laws, which remains the responsibility of the fundraiser.
      (ii) Purchases made directly from participating merchants through our site or via the Many Hands browser extension generate Earned Revenue, calculated based on the net purchase amount, which excludes taxes, fees, shipping, and other specified exclusions.
      (iii) Vouchers, including gift cards and discount savings cards, are purchased through our Service from participating merchants and are redeemable for goods, services, or experiences from those merchants. The merchant is solely responsible for honoring any Voucher and for any losses caused directly or indirectly by the use or redemption of a Voucher. By purchasing a Voucher, you agree to the Terms of Sale and accept the risks associated with any discrepancies between the paid value and the give value of the Voucher.
      `,
    ],
  },
  {
    title: `ACCOUNT LIMITS & FEES`,
  },
  {
    title: `Electronic Fund Transfer Disclosure
    `,
    paragraphs:[`Many Hands may offer a variety of electronic banking services for use with your account. The purpose of this section is to inform you about the electronic banking services available and to provide you with the relevant disclosures applicable to these services. Separate agreements that govern the terms of specific services, including any agreements related to Cards, may also be provided to you.
    `]
  },
];
