const base_static_url =
  "https://king-prawn-app-kvphh.ondigitalocean.app/static";

export const merchant_array = [
  {
    text: "Nike",
    background_image: `${base_static_url}/merchant-logos/nike.png`,
  },
  {
    text: "Skill Share",
    background_image: `${base_static_url}/merchant-logos/skill-share.png`,
  },
  {
    text: "Lululemon",
    background_image: `${base_static_url}/merchant-logos/lululemon.png`,
  },
  {
    text: "Walmart",
    background_image: `${base_static_url}/merchant-logos/walmart.png`,
  },
  {
    text: "Amazon",
    background_image: `${base_static_url}/merchant-logos/amazon.png`,
  },
  {
    text: "Apple",
    background_image: `${base_static_url}/merchant-logos/apple.png`,
  },
  {
    text: "Ebay",
    background_image: `${base_static_url}/merchant-logos/ebay.png`,
  },
  {
    text: "Target",
    background_image: `${base_static_url}/merchant-logos/target.png`,
  },
  {
    text: "Home Depot",
    background_image: `${base_static_url}/merchant-logos/the-home-depot.png`,
  },
  {
    text: "Bestbuy",
    background_image: `${base_static_url}/merchant-logos/bestbuy.png`,
  },
];

export const newCharityArr = [
  {
    id: 1,
    user_charity_id: 1,
    partner: false,
    affiliate_id_prefix: "c32d8b45-92fe-44f6-8b61-42c2107dfe87",
    title: "The Nature Conservancy",
    quote:
      "A Future Where People and Nature Thrive by maximizing our ability to effect change between now and 2030, we can shape a brighter future for people and our planet.",
    description:
      "The Nature Conservancy is a global environmental nonprofit working to create a world where people and nature can thrive. Founded in the U.S. through grassroots action in 1951, The Nature Conservancy (TNC) has grown to become one of the most effective and wide-reaching environmental organizations in the world. Thanks to more than a million members and the dedicated efforts of our diverse staff and over 400 scientists, we impact conservation in 76 countries and territories: 37 by direct conservation impact and 39 through partners.",
    category: "Environmental/ Animal",
    private_donation: "868 Million",
    total_revenue: "1.29 Billion",
    fundraising: "84%",
    commitment: "67%",
    logo: `${base_static_url}/charity-logos/the-nature-conservancy.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/the-nature-conservancy.png`,
  },

  {
    id: 2,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe86",
    title: "The Humane Society",
    quote:
      "We fight the big fights to end suffering for all animals and with your help, will achieve the vision behind our name: a humane society.",
    description: `The Humane Society of the United States is the nation’s most effective animal protection organization. With you by our side, we take on the big fights to end suffering for all animals.`,
    category: "Environmental/ Animal",
    private_donation: "169 Million",
    total_revenue: "182 Million",
    fundraising: "76%",
    commitment: "71%",
    logo: `${base_static_url}/charity-logos/the-humane-society.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/the-humane-society.jpeg`,
  },
  {
    id: 3,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "America Cancer Society",
    quote:
      "We advocate for lifesaving policy changes. We promote healthy lifestyles to help you prevent cancer. We research cancer and its causes to find more answers and better treatments. We provide information, answers, and support to people in every community who have been touched by cancer.",
    description: `Founded in 1913 in New York City as the American Society for the Control of Cancer, the American Cancer Society works to eliminate cancer.`,
    category: "Health",
    private_donation: "683 Million",
    total_revenue: "812 Million",
    fundraising: "86%",
    commitment: "80%",
    logo: `${base_static_url}/charity-logos/america-cancer-society.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/america-cancer-society.jpeg`,
  },
  {
    id: 4,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "American National Red Cross",
    quote:
      "The Red Cross, born of a desire to bring assistance without discrimination to the wounded on the battlefield, endeavors—in its international and national capacity—to prevent and alleviate human suffering wherever it may be found. Its purpose is to protect life and health and to ensure respect for the human being. It promotes mutual understanding, friendship, cooperation and lasting peace amongst all peoples.",
    description: `The American Red Cross prevents and alleviates human suffering in the face of emergencies by mobilizing the power of volunteers and the generosity of donors.`,
    category: "Domestic Needs",
    private_donation: "835 Million",
    total_revenue: "3.39 Billion",
    fundraising: "80%",
    commitment: "91%",
    logo: `${base_static_url}/charity-logos/american-national-red-cross.svg`,
    background_color: "#f0f0f0",
    front_image: `${base_static_url}/charity-background-image/american-national-red-cross.jpeg`,
  },
  {
    id: 5,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Direct Relief",
    quote:
      "Direct Relief is a humanitarian aid organization, active in all 50 states and more than 80 countries, with a mission to improve the health and lives of people affected by poverty or emergencies – without regard to politics, religion, or ability to pay.",
    description: `Founded in 1948, Direct Relief routes donated medicines and supplies to local healthcare providers in more than 140 countries, including the United States.`,
    category: "International Needs",
    private_donation: "2.21 Billion",
    total_revenue: "2.22 Billion",
    fundraising: "100%",
    commitment: "100%",
    logo: `${base_static_url}/charity-logos/direct-relief.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/direct-relief.jpeg`,
  },
  {
    id: 6,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Disabled American Veterans",
    quote:
      "Providing free, professional assistance to veterans and their families in obtaining benefits and services earned through military service and provided by the Department of Veterans Affairs (VA) and other agencies of government.",
    description: `Founded in 1920, Disabled American Veterans provides services and advocacy to injured military personnel.`,
    category: "Domestic Needs",
    private_donation: "257 Million",
    total_revenue: "341 Million",
    fundraising: "85%",
    commitment: "81%",
    logo: `${base_static_url}/charity-logos/disabled-american-veterans.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/disabled-american-veterans.jpeg`,
  },
  {
    id: 7,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Doctors Without Borders USA",
    quote:
      "Doctors Without Borders provides assistance to populations in distress, to victims of natural or man-made disasters and to victims of armed conflict. They do so irrespective of race, religion, creed or political convictions.",
    description: `Founded in 1971, Doctors Without Borders USA, affiliated with a Nobel Peace Prize winner, provides medical services in war-torn regions and developing countries.`,
    category: "International Needs",
    private_donation: "625 Million",
    total_revenue: "642 Million",
    fundraising: "87%",
    commitment: "85%",
    logo: `${base_static_url}/charity-logos/doctors-without-borders-usa.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/doctors-without-borders-usa.jpeg`,
  },
  {
    id: 8,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Earthjustice",
    quote: "We are here because the earth needs a good lawyer.",
    description: `Earthjustice is the premier nonprofit public interest environmental law organization.`,
    category: "Environmental",
    private_donation: "",
    total_revenue: "",
    fundraising: "",
    commitment: "",
    logo: `${base_static_url}/charity-logos/earthjustice.svg`,
    background_color: "#d5e046",
    front_image: `${base_static_url}/charity-background-image/earthjustice.jpeg`,
  },
  {
    id: 9,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Feeding America",
    quote:
      "Feeding America works with manufacturers and retailers to donate food that would otherwise go to waste. Our partners donate truckloads of food they are unable to sell. That rescued food then travels to food bank members throughout the country. Your donations help Feeding America provide that food to people who need it.",
    description: `Founded in 1979, Feeding America is the nation's leading hunger-relief charity.`,
    category: "Domestic Needs",
    private_donation: "4.06 Billion",
    total_revenue: "4.21 Billion",
    fundraising: "99%",
    commitment: "99%",
    logo: `${base_static_url}/charity-logos/feeding-america.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/feeding-america.jpeg`,
  },
  {
    id: 10,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Food for the Poor",
    quote:
      "By providing lifesaving food, secure housing, clean water, healthcare, emergency relief, micro-enterprise projects and education opportunities, we are giving those in extreme poverty a chance at a better future, and showing them God's love.",
    description: `Founded in 1982, Food for the Poor is an Christian relief agency specializing in providing donated goods to the needy in Latin America and the Caribbean.`,
    category: "International Needs",
    private_donation: "837 Million",
    total_revenue: "857 Million",
    fundraising: "95%",
    commitment: "94%",
    logo: `${base_static_url}/charity-logos/food-for-the-poor.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/food-for-the-poor.jpeg`,
  },
  {
    id: 11,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Friends of the Earth",
    quote:
      "We are part of an international community dedicated to protecting the natural world and the wellbeing of everyone in it. We lead campaigns, provide resources and information, and drive real solutions to the environmental problems facing us all.",
    description: `
    Background. Friends of the Earth U.S. was founded in California in 1969 by environmentalist David Brower after he left the Sierra Club.`,
    category: "Environmental",
    private_donation: "",
    total_revenue: "",
    fundraising: "",
    commitment: "",
    logo: `${base_static_url}/charity-logos/friends-of-the-earth.png`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/friends-of-the-earth.jpeg`,
  },
  {
    id: 12,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Goodwill Industries International",
    quote:
      "Goodwill Industries operates as a network of independent, community-based organizations in South Korea, Venezuela, Brazil, Mexico, Panama, Uruguay, the United States, Canada, and eight other countries, with 165 local Goodwill retail stores in the United States and Canada.",
    description: `Founded in 1902 and best known for its used goods stores, Goodwill Industries International also provides job training, employment placement services and other community-based programs.`,
    category: "Domestic Needs",
    private_donation: "1.44 Billion",
    total_revenue: "7.41 Billion",
    fundraising: "99%",
    commitment: "89%",
    logo: `${base_static_url}/charity-logos/goodwill-industries-international.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/goodwill-industries-international.jpeg`,
  },
  {
    id: 13,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Habitat for Humanity International",
    quote:
      "Habitat for Humanity is a global nonprofit housing organization working in local communities across all 50 states in the United States and in more than 70 countries around the world. Habitat's vision is of a world where everyone has a decent place to live.",
    description: `Founded in 1976 as a Christian housing ministry, Habitat for Humanity International musters donations and volunteer help to build actual homes for people that need them.`,
    category: "International Needs",
    private_donation: "1.27 Billion",
    total_revenue: "2.13 Billion",
    fundraising: "88%",
    commitment: "83%",
    logo: `${base_static_url}/charity-logos/habitat-for-humanity-international.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/habitat-for-humanity-international.jpeg`,
  },
  {
    id: 14,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Marine Toys for Tots Foundation",
    quote:
      'The mission of the program is "to collect new unwrapped toys and distribute those toys to less fortunate children at Christmas.',
    description: `Founded in 1991 and run by the United States Marine Corps Reserve, Toys for Tots distributes toys to children whose parents cannot afford to buy them holiday gifts.`,
    category: "Youth",
    private_donation: "355 Million",
    total_revenue: "371 Million",
    fundraising: "98%",
    commitment: "97%",
    logo: `${base_static_url}/charity-logos/marine-toys-for-tots-foundation.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/marine-toys-for-tots-foundation.jpeg`,
  },
  {
    id: 15,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Mayo Clinic",
    quote:
      "Mayo Clinic is regularly acknowledged among the very best in the nation in the following specialties: Cancer. Cardiology and heart surgery. Diabetes and endocrinology.",
    description: `Founded in 1864, Mayo Clinic is a medical practice and research group with major facilities in Minnesota, Florida and Arizona.`,
    category: "Medical",
    private_donation: "783 Million",
    total_revenue: "19.82 Billion",
    fundraising: "97%",
    commitment: "99%",
    logo: `${base_static_url}/charity-logos/mayo-clinic.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/mayo-clinic.jpeg`,
  },
  {
    id: 16,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Salvation Army",
    quote:
      'Image result for Salvation Army Its founders sought to bring salvation to the poor, destitute, and hungry by meeting both their "physical and spiritual needs". It is present in 133 countries, running charity shops, operating shelters for the homeless and disaster relief, and humanitarian aid to developing countries.',
    description: `Founded in London in 1865, the Salvation Army is a church that is better known for its charity work in over 120 countries.`,
    category: "Domestic Needs",
    private_donation: "2.34 Billion",
    total_revenue: "5.79 Billion",
    fundraising: "90%",
    commitment: "82%",
    logo: `${base_static_url}/charity-logos/salvation-army.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/salvation-army.jpeg`,
  },
  {
    id: 17,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Special Olympics",
    quote:
      "The mission of Special Olympics is to provide year-round sports training and athletic competition in a variety of Olympic-type sports for children and adults with intellectual disabilities, giving them continuing opportunities to develop physical fitness, demonstrate courage, experience joy and participate in a sharing of gifts, skills and friendship with their families, other Special Olympics athletes and the community.",
    description: `Founded in 1969 by Eunice Kennedy Shriver, Special Olympics provides athletic competitions for persons with intellectual disabilities.`,
    category: "Youth",
    private_donation: "240 Million",
    total_revenue: "44 Million",
    fundraising: "85%",
    commitment: "80%",
    logo: `${base_static_url}/charity-logos/special-olympics.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/special-olympics.jpeg`,
  },
  {
    id: 18,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "St Jude Children's Hospital",
    quote:
      "St. Jude Children's Research Hospital® is leading the way the world understands, treats and defeats childhood cancer.",
    description: `Founded in 1962 by Danny Thomas, St. Jude Children's Research Hospital is a pediatric treatment and research facility in Memphis focused on children's catastrophic diseases.`,
    category: "Medical",
    private_donation: "2.42 Billion",
    total_revenue: "2.33 Billion",
    fundraising: "85%",
    commitment: "69%",
    logo: `${base_static_url}/charity-logos/st-jude-children's-hospital.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/st-jude-childrens-hospital.jpeg`,
  },
  {
    id: 19,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Step up for Students",
    quote:
      "Step Up AT is an evidence-based customizable program that coaches teachers, teacher assistants, teacher support specialists, school districts, and other agencies to adopt evidence-based assistive technology (AT) practices shown to improve early literacy outcomes for young children (ages 3-5) with disabilities.",
    description: `Founded in 2002, Step Up For Students Florida raises money primarily to provide private school vouchers for poor students in Florida.`,
    category: "Education",
    private_donation: "819 Million",
    total_revenue: "822 Billion",
    fundraising: "100%",
    commitment: "99%",
    logo: `${base_static_url}/charity-logos/step-up-for-students.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/step-up-for-students.jpeg`,
  },
  {
    id: 20,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "United Way Worldwide",
    quote:
      "United Way is engaged in 95% of the U.S., and serves more than 1,100 communities across 37 countries and territories worldwide. We bring people together to build stronger, more equitable communities where everyone can thrive.",
    description: `Dating back to 1887, United Way Worldwide is the nation's largest charity by donations received, much of it from payroll deductions via 1,3000 local affiliates.`,
    category: "Domestic Needs",
    private_donation: "2.77 Billion",
    total_revenue: "3.89 Billion",
    fundraising: "88%",
    commitment: "85%",
    logo: `${base_static_url}/charity-logos/united-way-worldwide.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/united-way-worldwide.jpeg`,
  },
  {
    id: 21,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "World Wildlife Fund",
    quote:
      "World Wildlife Fund carefully manages resources to maximize your support of our work—saving endangered wildlife, protecting fragile ecosystems, tackling the climate crisis, and finding practical and beneficial ways for both people and nature to thrive.",
    description: `Founded in 1961, World Wildlife Fund addresses global environmental issues.`,
    category: "Environment/Animal",
    private_donation: "396 Million",
    total_revenue: "542 Million",
    fundraising: "90%",
    commitment: "82%",
    logo: `${base_static_url}/charity-logos/world-wildlife-fund.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/world-wildlife-fund.jpeg`,
  },
  {
    id: 22,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "Wounded Warrior Project",
    quote: `Since 2003, Wounded Warrior Project® (WWP) has been a tireless advocate for our nation's finest, improving the lives of millions of wounded warriors and their families. Thanks to the generosity of our donors and supporters, we provide programs and services that change – and save – lives.`,
    description: `Founded in 2003, Wounded Warrior Project provides aid to and lobbies for injured military service members.`,
    category: "Domestic Needs",
    private_donation: "327 Million",
    total_revenue: "435 Million",
    fundraising: "79%",
    commitment: "76%",
    logo: `${base_static_url}/charity-logos/wounded-warrior-project.svg`,
    background_color: "#fff",
    front_image: `${base_static_url}/charity-background-image/wounded-warrior-project.jpeg`,
  },
  {
    id: 23,
    user_charity_id: 2,
    partner: false,
    affiliate_id_prefix: "c33d8b45-92fe-44f6-8b61-42c2107dfe84",
    title: "YMCA of the USA",
    quote: `The YMCA is the leading nonprofit committed to strengthening individuals and communities across the country. At the Y, we’re here to help you find your “why” – your greater sense of purpose – by connecting you with opportunities to improve your health, support young people, make new friends and contribute to a stronger, more cohesive community for all. `,
    description: `Founded in 1844, the Young Men's Christian Association is a worldwide organization perhaps best known in the U.S. for its fitness facilities.`,
    category: "Youth",
    private_donation: "1.41 Billion",
    total_revenue: "7.44 Billion",
    fundraising: "90%",
    commitment: "84%",
    logo: `${base_static_url}/charity-logos/ymca-of-the-usa.svg`,
    background_color: "#000",
    front_image: `${base_static_url}/charity-background-image/ymca-of-the-usa.png`,
  },
];

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
