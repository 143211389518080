import React, { useState } from "react";

import Cards from "./CharityCards";
import styles from "./styles.module.css";

interface ICard {
  charity: any;
}

const CardContainer: React.FC<ICard> = ({ charity }) => {
  const [flipped, set] = useState(false);
  return (
    <div
      className={`${styles.cardContainer}`}
      onMouseEnter={() => set(true)}
      onMouseLeave={() => set(false)}
    >
      <Cards charity={charity} flipped={flipped} />
    </div>
  );
};

export default CardContainer;
