import * as actionTypes from "../types/coupons";

const initialState = {
  store_names: [],
  store_names_error: false,
  store_discounts: [],
  store_discounts_error: false,
  trending_stores: [],
  trending_stores_error: false,
  value: null
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.GET_STORE_NAMES:
      return {
        ...state,
        store_names: action.payload,
      };
    case actionTypes.GET_STORE_NAMES_ERROR:
      return {
        ...state,
        store_names_error: true,
      };
    case actionTypes.GET_STORE_DISCOUNTS:
      return {
        ...state,
        store_discounts: action.payload,
      };
    case actionTypes.GET_STORE_DISCOUNTS_ERROR:
      return {
        ...state,
        store_discounts_error: true,
      };
    case actionTypes.GET_TRENDING_STORE:
      return {
        ...state,
        trending_stores: action.payload,
      };
    case actionTypes.GET_TRENDING_STORE_ERROR:
      return {
        ...state,
        trending_stores_error: true,
      };
      case actionTypes.SET_VALUE:
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};
