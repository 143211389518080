import "./styles/home.css";

import React, { Fragment } from "react";

import DropDown from "../components/DropDown";
import Hoc from "../hoc/Hoc";

function Faq(Props: any) {
  const arr: any = [
    {
      question: "What is Many Hands?",
      answer:
        "Many Hands is a pioneering browser extension that enhances the online shopping experience by offering discounts and enabling users to support non-profit organizations effortlessly. It redirects 80% of the affiliate commissions earned from user purchases to non-profits chosen by the users, transforming everyday shopping into an act of philanthropy.",
    },
    {
      question: "Who created Many Hands and why?",
      answer:
        "Joseph, a former software engineer, created Many Hands. Overwhelmed by his demanding job in the tech industry, Joseph decided to use his skills for a greater purpose. He envisioned a platform that leverages technology to benefit individuals and the wider community, fostering a sustainable model of philanthropy through conscious consumerism.",
    },
    {
      question: "How does Many Hands support non-profit organizations?",
      answer:
        "Many Hands supports non-profits by redirecting a significant portion of its revenue—80% of affiliate commissions earned from user purchases—to the organizations chosen by the users. This innovative approach allows users to contribute to their favorite causes without incurring additional expenses.",
    },
    {
      question:
        "What makes Many Hands different from other discount or donation platforms?",
      answer:
        "Unlike other platforms, Many Hands focuses on conscious consumerism and sustainable philanthropy. It not only provides online shopping discounts but also empowers users to support non-profit causes effortlessly. This dual focus on savings and social impact sets Many Hands apart as a tool for positive change.",
    },
    {
      question: "How is Many Hands seeking funding and expanding its reach?",
      answer:
        "In a novel approach to growth and funding, Joseph is partnering with non-profits rather than pursuing conventional investment routes. By inviting non-profits to share the Many Hands extension within their communities, more revenue is directed towards these organizations, enhancing their ability to make a difference. This strategy fosters mutual benefit and shared success, ensuring a more equitable distribution of resources.",
    },
    {
      question: "How can I get involved with Many Hands?",
      answer:
        "To get involved with Many Hands, you can start by installing the browser extension to enjoy discounts while shopping online. You can also select the non-profit organizations you wish to support through your purchases. Additionally, spreading the word about Many Hands and its mission can help expand its impact and support more causes.",
    },
    {
      question: "What is the vision behind Many Hands?",
      answer:
        "The vision behind Many Hands is to redefine the boundaries of business and philanthropy by creating a platform where technology and commerce directly contribute to societal well-being. It represents Joseph's commitment to using innovation for altruism, aiming to prove that business success and social impact can coexist harmoniously.",
    },

    {
      question: "What exactly does the Many Hands extension do?",
      answer:
        "The Many Hands extension is a tool that integrates with your web browser to utilize affiliate marketing programs, directing a portion of your online purchases towards charitable contributions. It's a seamless way for shoppers to support their favorite charities without extra costs.",
    },
    {
      question:
        "What percentage of my purchase goes to charity through Many Hands?",
      answer:
        "Many Hands directs a generous 80% of the affiliate commissions it earns from your purchases straight to the charity you choose. This high percentage ensures that your shopping has a significant impact on the causes you care about.",
    },
    {
      question: "How does Many Hands ensure my privacy and data security?",
      answer:
        "Many Hands prioritizes your privacy by only utilizing the pathnames of websites you visit to identify potential discounts or sales. We strictly do not store, sell, or misuse your personal data, ensuring a secure and privacy-conscious shopping experience.",
    },
    {
      question: "What is the payment schedule for charities receiving funds?",
      answer:
        "Charities benefiting from Many Hands receive their payments on a monthly basis. This regular payout ensures that organizations have a steady stream of support to fund their important work.",
    },
    {
      question: "Which browsers are compatible with the Many Hands extension?",
      answer:
        "Currently, the Many Hands extension is available for desktop versions of Chrome. We're actively working on expanding compatibility to include Safari and Firefox in the near future to accommodate a wider range of users.",
    },
    {
      question: "Who can I contact for support or inquiries about Many Hands?",
      answer:
        "For any questions or support related to the Many Hands extension, feel free to contact us via email at info@manyhands.charity. Our team is dedicated to assisting you with any concerns or inquiries.",
    },
    {
      question: "How can a charity become part of the Many Hands network?",
      answer:
        "We're always looking to broaden the range of charities we support. If you represent a charity or have a charity in mind that you'd like to see included, please get in touch with us at info@manyhands.charity. We value community input in expanding our network of charitable organizations.",
    },
  ];
  return (
    <Fragment>
      <div className="about-container">
        <div className="home-child-container about-text">
          <h1>FAQ</h1>
        </div>
      </div>

      <div className="faq-container">
        {arr.map((obj: IFaqObject, idx: number) => (
          <DropDown key={idx} obj={obj} />
        ))}
      </div>
    </Fragment>
  );
}

export default Hoc(Faq);
