import React, { useState } from "react";

import axios from "axios";

const OnboardingForm = () => {
  const [nonprofitName, setNonprofitName] = useState("");
  const [nonprofitUrl, setNonprofitUrl] = useState("");
  const [accountLink, setAccountLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post(
        "https://api.manyhands.charity/payment-account-setup/create",
        {
          nonprofitName,
          nonprofitUrl,
        }
      );
      setAccountLink(response.data.url);
    } catch (err) {
      setError("Failed to create account. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Nonprofit Onboarding</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Nonprofit Name:
            <input
              type="text"
              value={nonprofitName}
              onChange={(e) => setNonprofitName(e.target.value)}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Nonprofit URL:
            <input
              type="text"
              value={nonprofitUrl}
              onChange={(e) => setNonprofitUrl(e.target.value)}
              required
            />
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Creating Account..." : "Submit"}
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {accountLink && (
        <div>
          <p>Account created successfully!</p>
          <a href={accountLink} target="_blank" rel="noopener noreferrer">
            Complete Onboarding
          </a>
        </div>
      )}
    </div>
  );
};

export default OnboardingForm;
