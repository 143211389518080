import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Hoc from "../../hoc/Hoc";
import ShareCard from "../card/ShareCard";

interface ILinkBuilder {
  postCharityShareAnalytics: Function;
  postCharityShareAnalyticsClear: Function;
  login: any;
  charity: any;
}

const LinkBuilder: React.FC<ILinkBuilder> = ({
  login,
  postCharityShareAnalytics,
  postCharityShareAnalyticsClear,
  charity: analytics,
}) => {
  const {
    user_info: { charity },
  } = login;

  useEffect(() => {
    return () => {
      postCharityShareAnalyticsClear();
    };
  }, []);

  const [info, setInfo] = useState({
    title: "",
    description: "",
  });

  const setInfoHandler = (e: any) => {
    setInfo({ ...info, [e.name]: e.value });
  };

  const postShareLinkHandler = () => {
    postCharityShareAnalytics(charity.affiliate_id_prefix, info);
  };

  const disabledHandler =
    info.title.length <= 0 || info.description.length <= 0;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {analytics &&
        analytics.post_share_link &&
        analytics.post_share_link.length > 0 ? (
          <ShareCard data={analytics.post_share_link[0]} />
        ) : (
          <>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="title"
              onChange={(e) => setInfoHandler(e.target)}
            />
            <TextField
              id="outlined-basic"
              label="Description"
              variant="outlined"
              name="description"
              onChange={(e) => setInfoHandler(e.target)}
              style={{ marginTop: "20px" }}
            />
            <Button
              variant="contained"
              color="inherit"
              style={{
                color: disabledHandler ? "grey" : "#00c7a4",
                marginTop: "20px",
                backgroundColor: disabledHandler ? "#d1d1d1" : "#6b21a8",
              }}
              onClick={() => postShareLinkHandler()}
              disabled={disabledHandler}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Hoc(LinkBuilder);
