import * as React from "react";

const SvgComponent = (props) => (
  <svg width="auto" height="100%" viewBox="0 0 100.542 148.696" {...props}>
    <g transform="translate(-77.66 61.035)">
      <path
        d="M77.661-60.506h100.542V87.661H77.661z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.664424,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M77.661-61.035h100.542v15.875H77.661z"
        style={{
          fill: "#0f172a",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.217484,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M-114.502 694.86c-3.18-2.422-5.014-4.169-6.544-6.232-3.29-4.437-4.418-8.376-3.275-11.432.655-1.75 2.242-2.93 4.388-3.265 2.36-.367 5.329.603 6.65 2.174l.392.465.768-.74c2.092-2.017 5.668-2.561 8.33-1.269 1.667.81 2.622 2.326 2.824 4.488.354 3.794-2.721 9.46-7.295 13.438-1.357 1.18-4.43 3.57-4.59 3.569-.042 0-.784-.539-1.648-1.196z"
        style={{
          fill: "red",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.729237,
          strokeOpacity: 1,
        }}
        transform="matrix(.3575 0 0 .3575 126.23 -297.647)"
      />
      <path
        d="M-427.938 2629.735c-3.202-2.275-9.124-6.852-12.492-9.655-8.98-7.473-14.539-13.502-20.044-21.743-6.105-9.14-9.505-17.125-10.699-25.125-.274-1.834-.275-6.715-.002-8.415.78-4.865 2.425-8.464 5.166-11.309 1.802-1.87 3.444-3.046 5.89-4.216 2.244-1.073 4.81-1.808 7.587-2.172 1.821-.238 5.84-.16 7.746.15 3.792.618 7.194 1.712 10.187 3.275 2.772 1.448 4.759 2.995 6.65 5.178l1.196 1.38.008 8.807c.004 4.844.05 21.39.102 36.768.051 15.379.08 27.975.064 27.991-.017.016-.628-.395-1.358-.914z"
        style={{
          opacity: 0.99,
          fill: "#000",
          fillOpacity: 0.198653,
          stroke: "none",
          strokeWidth: 6.36737,
          strokeDashoffset: 210.392,
          strokeOpacity: 0.984314,
        }}
        transform="matrix(.0946 0 0 .0946 126.23 -297.647)"
      />
      <text
        xmlSpace="preserve"
        x={93.574}
        y={-49.066}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={93.574}
          y={-49.066}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={93.846}
        y={-48.823}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={93.846}
          y={-48.823}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <path
        d="M77.661-45.16h100.542v7.938H77.661z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.153785,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M77.661 36.693h100.542v.529H77.661z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={21.696}
        height={5.821}
        x={80.306}
        y={-43.888}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={84.981}
        y={-39.188}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={84.981}
          y={-39.188}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#ccc",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Deals"}
        </tspan>
      </text>
      <g transform="translate(2417.114 -1827.229)">
        <rect
          width={21.696}
          height={5.821}
          x={-2300.288}
          y={1783.001}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={21.696}
          height={5.821}
          x={-2300.031}
          y={1783.341}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
      </g>
      <text
        xmlSpace="preserve"
        x={117.677}
        y={-39.188}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={117.677}
          y={-39.188}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Charities"}
        </tspan>
      </text>
      <g transform="translate(2380.337 -1827.229)">
        <rect
          width={21.696}
          height={5.821}
          x={-2226.477}
          y={1783.341}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2222.2}
          y={1788.041}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.766792px",
            fill: "#ccc",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 12.843,
            strokeDasharray: "38.5288,38.5288",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2222.2}
            y={1788.041}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 600,
              fontStretch: "normal",
              fontSize: "4.7625px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#ccc",
              fillOpacity: 1,
              strokeWidth: 12.843,
            }}
          >
            {"Share"}
          </tspan>
        </text>
      </g>
      <rect
        width={100.542}
        height={61.638}
        x={77.661}
        y={-20.744}
        rx={0}
        ry={0}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.152373,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M77.661 79.972h100.542v7.689H77.661z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.151354,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={119.163}
        y={84.939}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "4.49792px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={119.163}
          y={84.939}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.49792px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Settings"}
        </tspan>
      </text>
      <path
        d="M77.661-79.723h100.542v.529H77.661z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.476}
        height={10.944}
        x={81.194}
        y={-34.239}
        rx={4.942}
        ry={4.942}
        style={{
          fill: "#fff",
          stroke: "#ccc",
          strokeWidth: 0.371566,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <circle
        cx={80.962}
        cy={42.143}
        r={2.163}
        style={{
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0631827,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <circle
        cx={80.962}
        cy={42.143}
        r={1.736}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0507122,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <rect
        width={0.543}
        height={2.871}
        x={80.691}
        y={44.159}
        rx={0.102}
        ry={0.102}
        style={{
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0480082,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <text
        xmlSpace="preserve"
        x={93.005}
        y={-27.13}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 300,
          fontStretch: "normal",
          fontSize: "4.35552px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.759265px",
          fill: "gray",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.7169,
          strokeDasharray: "38.1505,38.1505",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={93.005}
          y={-27.13}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 300,
            fontStretch: "normal",
            fontSize: "4.35552px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "gray",
            fillOpacity: 1,
            strokeWidth: 12.7169,
          }}
        >
          {"Type  Charity  Name "}
        </tspan>
      </text>
      <path
        d="M77.661 20.289h100.542v.529H77.661z"
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.398}
        height={14.163}
        x={80.704}
        y={-12.588}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={81.554}
        y={-11.678}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={92.517}
        y={-3.185}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={92.517}
          y={-3.185}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"The  Nature  Conservancy"}
        </tspan>
      </text>
      <g transform="matrix(.62668 0 0 .62668 1538.978 -1119.446)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <rect
        width={93.398}
        height={14.163}
        x={80.704}
        y={5.068}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={81.554}
        y={5.979}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={92.527}
        y={14.472}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={92.527}
          y={14.472}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"The  Humane  Society"}
        </tspan>
      </text>
      <rect
        width={93.398}
        height={14.163}
        x={80.704}
        y={22.724}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={81.554}
        y={23.635}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={92.49}
        y={32.128}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={92.49}
          y={32.128}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Wounded  Warroir  Project"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={113.412}
        y={-14.916}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={113.412}
          y={-14.916}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"My  Charities"}
        </tspan>
      </text>
      <g transform="matrix(.62668 0 0 .62668 1538.978 -1101.79)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <g transform="matrix(.62668 0 0 .62668 1538.978 -1084.134)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <text
        xmlSpace="preserve"
        x={119.256}
        y={45.275}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={119.256}
          y={45.275}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Charities"}
        </tspan>
      </text>
      <rect
        width={100.478}
        height={10.681}
        x={77.692}
        y={46.719}
        rx={0}
        ry={0}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#b3b3b3",
          strokeWidth: 0.0634104,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <g transform="matrix(.62668 0 0 .62668 908.497 39.813)">
        <circle
          cx={-1317.339}
          cy={19.542}
          r={4.222}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <g
          style={{
            fill: "#fff",
          }}
          transform="matrix(1.0058 1.0298 -1.0298 1.0058 42.196 1404.727)"
        >
          <rect
            width={0.664}
            height={3.826}
            x={956.11}
            y={-952.284}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(-135.323)"
          />
          <rect
            width={0.664}
            height={3.826}
            x={950.039}
            y={954.529}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(134.677)"
          />
        </g>
      </g>
      <text
        xmlSpace="preserve"
        x={88.124}
        y={53.164}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={88.124}
          y={53.164}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Mayo  Clinic"}
        </tspan>
      </text>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -145.34 -110.362) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
      <rect
        width={100.478}
        height={10.681}
        x={77.692}
        y={57.463}
        rx={0}
        ry={0}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#b3b3b3",
          strokeWidth: 0.0634104,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <g transform="matrix(.62668 0 0 .62668 908.497 50.557)">
        <circle
          cx={-1317.339}
          cy={19.542}
          r={4.222}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <g
          style={{
            fill: "#fff",
          }}
          transform="matrix(1.0058 1.0298 -1.0298 1.0058 42.196 1404.727)"
        >
          <rect
            width={0.664}
            height={3.826}
            x={956.11}
            y={-952.284}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(-135.323)"
          />
          <rect
            width={0.664}
            height={3.826}
            x={950.039}
            y={954.529}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(134.677)"
          />
        </g>
      </g>
      <text
        xmlSpace="preserve"
        x={88.124}
        y={63.909}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={88.124}
          y={63.909}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Salvation  Army"}
        </tspan>
      </text>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -143.132 -104.99) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
      <rect
        width={100.478}
        height={10.681}
        x={77.692}
        y={68.208}
        rx={0}
        ry={0}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#b3b3b3",
          strokeWidth: 0.0634104,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <g transform="matrix(.62668 0 0 .62668 908.497 61.302)">
        <circle
          cx={-1317.339}
          cy={19.542}
          r={4.222}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <g
          style={{
            fill: "#fff",
          }}
          transform="matrix(1.0058 1.0298 -1.0298 1.0058 42.196 1404.727)"
        >
          <rect
            width={0.664}
            height={3.826}
            x={956.11}
            y={-952.284}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(-135.323)"
          />
          <rect
            width={0.664}
            height={3.826}
            x={950.039}
            y={954.529}
            rx={0}
            ry={0}
            style={{
              fill: "#fff",
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 0.24926,
              strokeDasharray: "none",
              strokeDashoffset: 210.392,
              strokeOpacity: 1,
            }}
            transform="rotate(134.677)"
          />
        </g>
      </g>
      <text
        xmlSpace="preserve"
        x={88.124}
        y={74.653}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={88.124}
          y={74.653}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Special Olympics"}
        </tspan>
      </text>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -140.924 -99.617) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -151.246 -120.408) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -158.501 -138.064) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
      <g
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
        }}
        transform="rotate(-135.322 -154.874 -129.236) scale(.35979)"
      >
        <rect
          width={1.846}
          height={10.635}
          x={-1353.377}
          y={-9.044}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={1.846}
          height={10.635}
          x={7.198}
          y={-1353.377}
          rx={0}
          ry={0}
          style={{
            fill: "#6b21a8",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.692797,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-90)"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
