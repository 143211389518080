import * as React from "react";

const SvgComponent = (props) => (
  <svg width="auto" height="100%" viewBox="0 0 100.542 148.696" {...props}>
    <g transform="translate(-141.467 32.641)">
      <path
        d="M141.467-32.112h100.542v148.167H141.467z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.664424,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M141.467-32.641h100.542v15.875H141.467z"
        style={{
          fill: "#0f172a",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.217484,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M-114.502 694.86c-3.18-2.422-5.014-4.169-6.544-6.232-3.29-4.437-4.418-8.376-3.275-11.432.655-1.75 2.242-2.93 4.388-3.265 2.36-.367 5.329.603 6.65 2.174l.392.465.768-.74c2.092-2.017 5.668-2.561 8.33-1.269 1.667.81 2.622 2.326 2.824 4.488.354 3.794-2.721 9.46-7.295 13.438-1.357 1.18-4.43 3.57-4.59 3.569-.042 0-.784-.539-1.648-1.196z"
        style={{
          fill: "red",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.729237,
          strokeOpacity: 1,
        }}
        transform="matrix(.3575 0 0 .3575 190.036 -269.253)"
      />
      <path
        d="M-427.938 2629.735c-3.202-2.275-9.124-6.852-12.492-9.655-8.98-7.473-14.539-13.502-20.044-21.743-6.105-9.14-9.505-17.125-10.699-25.125-.274-1.834-.275-6.715-.002-8.415.78-4.865 2.425-8.464 5.166-11.309 1.802-1.87 3.444-3.046 5.89-4.216 2.244-1.073 4.81-1.808 7.587-2.172 1.821-.238 5.84-.16 7.746.15 3.792.618 7.194 1.712 10.187 3.275 2.772 1.448 4.759 2.995 6.65 5.178l1.196 1.38.008 8.807c.004 4.844.05 21.39.102 36.768.051 15.379.08 27.975.064 27.991-.017.016-.628-.395-1.358-.914z"
        style={{
          opacity: 0.99,
          fill: "#000",
          fillOpacity: 0.198653,
          stroke: "none",
          strokeWidth: 6.36737,
          strokeDashoffset: 210.392,
          strokeOpacity: 0.984314,
        }}
        transform="matrix(.0946 0 0 .0946 190.036 -269.253)"
      />
      <text
        xmlSpace="preserve"
        x={157.38}
        y={-20.672}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={157.38}
          y={-20.672}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={157.652}
        y={-20.429}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "7.54062px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={157.652}
          y={-20.429}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "7.54062px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Many Hands"}
        </tspan>
      </text>
      <path
        d="M141.467-16.766h100.542v7.938H141.467z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.153785,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M141.467 8.3h100.542v.529H141.467z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={21.696}
        height={5.821}
        x={143.855}
        y={-15.834}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={21.696}
        height={5.821}
        x={144.113}
        y={-15.494}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={21.696}
        height={5.821}
        x={168.631}
        y={-15.494}
        rx={3.307}
        ry={3.307}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0611759,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={148.787}
        y={-10.794}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={148.787}
          y={-10.794}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Deals"}
        </tspan>
      </text>
      <g transform="translate(2431.884 -1798.835)">
        <rect
          width={21.696}
          height={5.821}
          x={-2250.995}
          y={1783.341}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2250.401}
          y={1788.041}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.766792px",
            fill: "#ccc",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 12.843,
            strokeDasharray: "38.5288,38.5288",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2250.401}
            y={1788.041}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 600,
              fontStretch: "normal",
              fontSize: "4.7625px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#ccc",
              fillOpacity: 1,
              strokeWidth: 12.843,
            }}
          >
            {"Charities"}
          </tspan>
        </text>
      </g>
      <g transform="translate(2444.143 -1798.835)">
        <rect
          width={21.696}
          height={5.821}
          x={-2226.477}
          y={1783.341}
          rx={3.307}
          ry={3.307}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0611759,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2222.2}
          y={1788.041}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.766792px",
            fill: "#ccc",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 12.843,
            strokeDasharray: "38.5288,38.5288",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2222.2}
            y={1788.041}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 600,
              fontStretch: "normal",
              fontSize: "4.7625px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#ccc",
              fillOpacity: 1,
              strokeWidth: 12.843,
            }}
          >
            {"Share"}
          </tspan>
        </text>
      </g>
      <rect
        width={100.542}
        height={79.479}
        x={141.467}
        y={7.65}
        rx={0}
        ry={0}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.173026,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <path
        d="M141.467 108.366h100.542v7.689H141.467z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.151354,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={182.969}
        y={113.333}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 500,
          fontStretch: "normal",
          fontSize: "4.49792px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#6b21a8",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={182.969}
          y={113.333}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "4.49792px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#6b21a8",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"Settings"}
        </tspan>
      </text>
      <path
        d="M141.467-108.117h100.542v.529H141.467z"
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.476}
        height={10.944}
        x={145}
        y={-5.845}
        rx={4.942}
        ry={4.942}
        style={{
          fill: "#fff",
          stroke: "#ccc",
          strokeWidth: 0.371566,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <circle
        cx={104.484}
        cy={107.901}
        r={2.163}
        style={{
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0631827,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <circle
        cx={104.484}
        cy={107.901}
        r={1.736}
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0507122,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <rect
        width={0.543}
        height={2.871}
        x={104.213}
        y={109.917}
        rx={0.102}
        ry={0.102}
        style={{
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0480082,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="rotate(-46.329)"
      />
      <text
        xmlSpace="preserve"
        x={156.811}
        y={1.263}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 300,
          fontStretch: "normal",
          fontSize: "4.35552px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.759265px",
          fill: "gray",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.7169,
          strokeDasharray: "38.1505,38.1505",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={156.811}
          y={1.263}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 300,
            fontStretch: "normal",
            fontSize: "4.35552px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "gray",
            fillOpacity: 1,
            strokeWidth: 12.7169,
          }}
        >
          {"Search    by    store    name"}
        </tspan>
      </text>
      <path
        d="M141.467-8.105h100.542v.529H141.467z"
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.0397071,
          strokeDasharray: "none",
          strokeDashoffset: 210.392,
          strokeOpacity: 1,
        }}
        transform="scale(1 -1)"
      />
      <rect
        width={93.398}
        height={14.163}
        x={144.51}
        y={15.806}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={145.361}
        y={16.716}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <g fill="none" fillRule="evenodd">
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M165.788 26.441c-3.376 1.607-5.472.263-6.813-.554-.083-.051-.224.012-.102.153.447.542 1.912 1.848 3.823 1.848 1.913 0 3.05-1.044 3.193-1.226.141-.18.042-.28-.101-.22zm.948-.523c-.09-.118-.551-.14-.841-.105-.29.035-.726.212-.689.319.02.04.06.022.259.004.2-.02.76-.09.877.062.117.153-.179.885-.233 1.004-.052.118.02.148.118.07.097-.08.272-.283.39-.57.117-.29.188-.694.119-.784z"
        />
        <path
          fill="#000"
          d="M163.518 23.163c0 .421.01.773-.203 1.147-.172.305-.444.492-.749.492-.415 0-.657-.317-.657-.784 0-.922.826-1.09 1.609-1.09zm1.09 2.637a.226.226 0 0 1-.255.026c-.359-.298-.423-.437-.62-.721-.594.606-1.014.787-1.785.787-.91 0-1.619-.562-1.619-1.687 0-.878.476-1.476 1.154-1.768.587-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.131-.784-.133-.2-.385-.282-.608-.282-.412 0-.78.212-.87.65-.018.098-.09.193-.187.198l-1.05-.113c-.089-.02-.186-.09-.162-.226.242-1.273 1.391-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.407 1.003.07.098.086.215-.003.288-.22.184-.613.527-.83.718l-.002-.003"
        />
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M165.788 26.441c-3.376 1.607-5.472.263-6.813-.554-.083-.051-.224.012-.102.153.447.542 1.912 1.848 3.823 1.848 1.913 0 3.05-1.044 3.193-1.226.141-.18.042-.28-.101-.22zm.948-.523c-.09-.118-.551-.14-.841-.105-.29.035-.726.212-.689.319.02.04.06.022.259.004.2-.02.76-.09.877.062.117.153-.179.885-.233 1.004-.052.118.02.148.118.07.097-.08.272-.283.39-.57.117-.29.188-.694.119-.784z"
        />
        <path
          fill="#000"
          d="M163.518 23.163c0 .421.01.773-.203 1.147-.172.305-.444.492-.749.492-.415 0-.657-.317-.657-.784 0-.922.826-1.09 1.609-1.09zm1.09 2.637a.226.226 0 0 1-.255.026c-.359-.298-.423-.437-.62-.721-.594.606-1.014.787-1.785.787-.91 0-1.619-.562-1.619-1.687 0-.878.476-1.476 1.154-1.768.587-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.131-.784-.133-.2-.385-.282-.608-.282-.412 0-.78.212-.87.65-.018.098-.09.193-.187.198l-1.05-.113c-.089-.02-.186-.09-.162-.226.242-1.273 1.391-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.407 1.003.07.098.086.215-.003.288-.22.184-.613.527-.83.718l-.002-.003"
        />
      </g>
      <text
        xmlSpace="preserve"
        x={184.067}
        y={25.209}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={184.067}
          y={25.209}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Amazon"}
        </tspan>
      </text>
      <g transform="matrix(.62668 0 0 .62668 1602.784 -1091.053)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <g transform="translate(2444.143 -1798.835)">
        <rect
          width={10.147}
          height={5.892}
          x={-2218.992}
          y={1819.687}
          fill="none"
          rx={1.69}
          ry={1.827}
          style={{
            fill: "#000",
            fillOpacity: 0,
            stroke: "#00c7a4",
            strokeWidth: 0.405,
            strokeDasharray: ".405,.405",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        />
        <text
          xmlSpace="preserve"
          x={-2215.731}
          y={1823.786}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            wordSpacing: "-.660045px",
            fill: "#00c7a4",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 11.055,
            strokeDasharray: "33.1651,33.1651",
            strokeDashoffset: 0,
            strokeOpacity: 1,
          }}
        >
          <tspan
            x={-2215.731}
            y={1823.786}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: 500,
              fontStretch: "normal",
              fontSize: "3.24544px",
              fontFamily: "Roboto Slab, serif",
              InkscapeFontSpecification: "&quot",
              fill: "#00c7a4",
              fillOpacity: 1,
              strokeWidth: 11.055,
            }}
          >
            {"40"}
          </tspan>
        </text>
      </g>
      <rect
        width={93.398}
        height={14.163}
        x={144.51}
        y={33.462}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={145.361}
        y={34.372}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={184.067}
        y={42.865}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={184.067}
          y={42.865}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Walmart"}
        </tspan>
      </text>
      <rect
        width={93.398}
        height={14.163}
        x={144.51}
        y={51.118}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={145.361}
        y={52.029}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={184.067}
        y={60.522}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={184.067}
          y={60.522}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Bestbuy"}
        </tspan>
      </text>
      <rect
        width={93.398}
        height={14.163}
        x={144.51}
        y={68.774}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.114166,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <rect
        width={93.398}
        height={14.163}
        x={145.361}
        y={69.685}
        rx={2.575}
        ry={2.484}
        style={{
          fill: "#fff",
          stroke: "#6b21a8",
          strokeWidth: 0.414,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={184.067}
        y={78.178}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={184.067}
          y={78.178}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Nike"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={180.443}
        y={13.477}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.7625px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.766792px",
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.843,
          strokeDasharray: "38.5288,38.5288",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={180.443}
          y={13.477}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.7625px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#fff",
            fillOpacity: 1,
            strokeWidth: 12.843,
          }}
        >
          {"My  Stores"}
        </tspan>
      </text>
      <g transform="translate(2340.845 -1795.78)">
        <rect
          width={9.065}
          height={8.854}
          x={-2182.584}
          y={1832.807}
          rx={3.274}
          ry={2.461}
          style={{
            fill: "#0071dc",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0965051,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
          }}
        />
        <path
          fill="#f3b53b"
          d="M-2178.064 1836.042c.17 0 .308-.09.329-.202l.165-1.895c0-.187-.218-.341-.494-.341s-.495.154-.495.34l.168 1.896c.018.115.159.2.327.2m-1.024.594c.085-.148.078-.313-.011-.387l-1.557-1.09c-.161-.094-.403.018-.54.257-.139.24-.116.506.045.598l1.725.803c.108.04.253-.037.338-.184m2.048 0c.085.147.23.223.34.184l1.723-.803c.161-.092.184-.358.05-.598-.139-.239-.383-.352-.544-.257l-1.557 1.09c-.087.074-.094.24-.01.387m-1.026 1.77c.17 0 .308.09.329.203l.165 1.896c0 .186-.218.34-.494.34s-.495-.154-.495-.34l.168-1.896c.018-.115.159-.2.327-.2m1.023-.591c.086-.147.23-.223.341-.184l1.723.803c.16.092.184.36.049.598-.138.239-.382.352-.543.257l-1.557-1.09c-.087-.074-.094-.24-.01-.384m-2.05 0c.085.145.078.31-.011.384l-1.557 1.09c-.161.092-.403-.018-.54-.257-.139-.24-.116-.506.045-.598l1.725-.803c.108-.04.253.037.338.184m4.475 1.309h.017c.018 0 .035-.01.035-.023 0-.014-.01-.023-.032-.023h-.018zm0 .083h-.037v-.15h.057c.03 0 .042 0 .053.011.01.01.016.018.016.032 0 .018-.014.03-.032.037.014 0 .024.018.028.037.01.023.01.032.01.037h-.036c-.01 0-.01-.018-.014-.034 0-.016-.011-.023-.03-.023h-.018v.06zm.023-.198c-.07 0-.118.055-.118.122s.05.12.118.12c.067 0 .115-.054.115-.12a.115.115 0 0 0-.115-.122zm0-.03a.152.152 0 1 1 0 .304.152.152 0 0 1-.157-.152c0-.085.07-.152.157-.152z"
          style={{
            fillRule: "evenodd",
            strokeWidth: 0.230033,
          }}
        />
      </g>
      <g transform="translate(2341.162 -1795.41)">
        <rect
          width={8.109}
          height={5.348}
          x={-2182.423}
          y={1851.846}
          rx={0}
          ry={0}
          style={{
            fill: "#0046be",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0709318,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
          }}
        />
        <path
          fill="#fff200"
          d="M-2176.209 1855.474h1.437v1.15h-1.437l-.358-.336v-.475Z"
        />
        <path
          fill="#1d252c"
          d="M-2176.28 1856.049a.07.07 0 0 1-.072.07.07.07 0 0 1-.07-.07.07.07 0 0 1 .07-.071c.04 0 .071.032.071.07z"
        />
        <path
          fill="#fff"
          d="M-2176.227 1855.066c0-.054.043-.097.097-.097.055 0 .097.043.097.096a.096.096 0 0 1-.097.097.096.096 0 0 1-.097-.096zm.182 0a.082.082 0 0 0-.085-.085.082.082 0 0 0-.084.085c0 .05.037.084.084.084a.082.082 0 0 0 .085-.085zm-.117-.052h.032c.022 0 .036.01.036.029 0 .016-.01.024-.023.027l.03.044h-.017l-.028-.042h-.015v.042h-.015zm.033.047c.014 0 .02-.006.02-.017 0-.013-.007-.018-.02-.018h-.018v.035zm-4.546-1.736.028-.01a.41.41 0 0 0 .053-.704c-.118-.095-.28-.142-.484-.142h-.886v1.817h.938c.241 0 .74-.045.74-.52 0-.363-.38-.437-.39-.44zm-.702-.43h.216c.08-.002.148.054.147.133.001.079-.068.135-.147.133h-.216Zm.404.922a.178.178 0 0 1-.127.048h-.277v-.326h.276c.15-.008.242.174.128.278zm.765 1.519.028-.01a.41.41 0 0 0 .053-.705c-.119-.094-.28-.141-.484-.141h-.885v1.816h.937c.24 0 .74-.045.74-.52 0-.362-.38-.436-.39-.44zm-.7-.43h.214c.08-.003.148.053.148.132 0 .08-.068.135-.148.133h-.215zm.402.923a.178.178 0 0 1-.128.047h-.275v-.326h.275c.15-.008.242.174.128.279zm1.723-1.984h-.878v-.285h.717v-.416h-.717v-.233h.88v-.442h-1.467v1.816h1.465zm1.502-1.376v.442h.48v1.374h.585v-1.374h.48v-.442Z"
        />
        <path
          fill="#fff"
          d="M-2177.945 1853.816c-.128.106-.37-.012-.466-.11l-.346.33.022.022c.307.303.956.394 1.283.101.268-.211.277-.666-.002-.852a1.38 1.38 0 0 0-.365-.162.617.617 0 0 1-.162-.057c-.131-.07-.02-.203.097-.191a.31.31 0 0 1 .23.092l.347-.33-.007-.008c-.28-.28-.882-.322-1.175-.061a.523.523 0 0 0-.199.406c-.007.382.328.515.634.605.065.027.15.044.158.113 0 .04-.016.074-.049.102zm-.836 1.743c-.008.289-.428.289-.436 0v-1.079h-.588v1.052a.787.787 0 0 0 .822.785.785.785 0 0 0 .785-.785v-1.051h-.583Zm1.588-.46-.386-.618h-.588l.68 1.168v.648h.587v-.649l.68-1.167h-.589z"
        />
      </g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21 8.719 7.836 14.303C6.74 14.768 5.818 15 5.075 15c-.836 0-1.445-.295-1.819-.884-.485-.76-.273-1.982.559-3.272.494-.754 1.122-1.446 1.734-2.108-.144.234-1.415 2.349-.025 3.345.275.2.666.298 1.147.298.386 0 .829-.063 1.316-.19z"
        clipRule="evenodd"
        style={{
          fill: "none",
        }}
        transform="matrix(.49999 0 0 .49999 156.794 70.837)"
      />
      <g transform="matrix(.62668 0 0 .62668 1602.784 -1073.396)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <g transform="matrix(.62668 0 0 .62668 1602.784 -1055.74)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <g transform="matrix(.62668 0 0 .62668 1602.784 -1038.084)">
        <circle
          cx={-2316.442}
          cy={1778.988}
          r={4.222}
          style={{
            fill: "red",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.135961,
            strokeDasharray: "none",
            strokeDashoffset: 55.6662,
            strokeOpacity: 1,
          }}
        />
        <rect
          width={0.956}
          height={5.508}
          x={-1793.705}
          y={-2308.192}
          rx={0}
          ry={0}
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.358806,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
            strokeOpacity: 1,
          }}
          transform="rotate(-89.647)"
        />
      </g>
      <rect
        width={10.147}
        height={5.892}
        x={225.152}
        y={38.508}
        fill="none"
        rx={1.69}
        ry={1.827}
        style={{
          fill: "#000",
          fillOpacity: 0,
          stroke: "#00c7a4",
          strokeWidth: 0.405,
          strokeDasharray: ".405,.405",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={227.731}
        y={42.607}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "3.24544px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.660045px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 11.055,
          strokeDasharray: "33.1651,33.1651",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={227.731}
          y={42.607}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 11.055,
          }}
        >
          {"105"}
        </tspan>
      </text>
      <rect
        width={10.147}
        height={5.892}
        x={225.152}
        y={56.164}
        fill="none"
        rx={1.69}
        ry={1.827}
        style={{
          fill: "#000",
          fillOpacity: 0,
          stroke: "#00c7a4",
          strokeWidth: 0.405,
          strokeDasharray: ".405,.405",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={228.457}
        y={60.264}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "3.24544px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.660045px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 11.055,
          strokeDasharray: "33.1651,33.1651",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={228.457}
          y={60.264}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 11.055,
          }}
        >
          {"32"}
        </tspan>
      </text>
      <rect
        width={10.147}
        height={5.892}
        x={225.152}
        y={73.82}
        fill="none"
        rx={1.69}
        ry={1.827}
        style={{
          fill: "#000",
          fillOpacity: 0,
          stroke: "#00c7a4",
          strokeWidth: 0.405,
          strokeDasharray: ".405,.405",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <text
        xmlSpace="preserve"
        x={229.33}
        y={77.92}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "3.24544px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.660045px",
          fill: "#00c7a4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 11.055,
          strokeDasharray: "33.1651,33.1651",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={229.33}
          y={77.92}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 500,
            fontStretch: "normal",
            fontSize: "3.24544px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#00c7a4",
            fillOpacity: 1,
            strokeWidth: 11.055,
          }}
        >
          {"7"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={179.428}
        y={91.661}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={179.428}
          y={91.661}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Recent  Visits"}
        </tspan>
      </text>
      <rect
        width={0.529}
        height={14.232}
        x={191.569}
        y={93.355}
        rx={0}
        ry={0}
        style={{
          fill: "#ccc",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.00531186,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      />
      <g fill="none" fillRule="evenodd">
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M152.796 103.115c-3.376 1.607-5.471.263-6.813-.554-.083-.051-.224.012-.101.153.447.541 1.911 1.847 3.822 1.847 1.913 0 3.051-1.043 3.193-1.225.142-.18.042-.28-.1-.22zm.948-.523c-.09-.119-.551-.14-.84-.105-.291.035-.727.212-.69.319.02.04.06.022.26.004.2-.02.76-.091.876.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.097-.078.272-.282.39-.57.116-.29.187-.694.118-.783z"
        />
        <path
          fill="#000"
          d="M150.526 99.837c0 .421.01.773-.202 1.147-.172.305-.445.492-.75.492-.415 0-.657-.317-.657-.784 0-.922.827-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.255.026c-.36-.298-.424-.437-.621-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.588-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.13-.784-.133-.2-.386-.282-.608-.282-.413 0-.781.212-.87.65-.02.097-.09.193-.188.198l-1.05-.113c-.088-.02-.186-.091-.162-.226.242-1.273 1.392-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.22.184-.614.526-.83.718l-.003-.003"
        />
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M152.796 103.115c-3.376 1.607-5.471.263-6.813-.554-.083-.051-.224.012-.101.153.447.541 1.911 1.847 3.822 1.847 1.913 0 3.051-1.043 3.193-1.225.142-.18.042-.28-.1-.22zm.948-.523c-.09-.119-.551-.14-.84-.105-.291.035-.727.212-.69.319.02.04.06.022.26.004.2-.02.76-.091.876.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.097-.078.272-.282.39-.57.116-.29.187-.694.118-.783z"
        />
        <path
          fill="#000"
          d="M150.526 99.837c0 .421.01.773-.202 1.147-.172.305-.445.492-.75.492-.415 0-.657-.317-.657-.784 0-.922.827-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.255.026c-.36-.298-.424-.437-.621-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.588-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.13-.784-.133-.2-.386-.282-.608-.282-.413 0-.781.212-.87.65-.02.097-.09.193-.188.198l-1.05-.113c-.088-.02-.186-.091-.162-.226.242-1.273 1.392-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.22.184-.614.526-.83.718l-.003-.003"
        />
      </g>
      <text
        xmlSpace="preserve"
        x={-2273.068}
        y={1901.032}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        transform="translate(2444.143 -1799.148)"
      >
        <tspan
          x={-2273.068}
          y={1901.032}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Amazon"}
        </tspan>
      </text>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M152.796 103.115c-3.376 1.607-5.471.263-6.813-.554-.083-.051-.224.012-.101.153.447.541 1.911 1.847 3.822 1.847 1.913 0 3.051-1.043 3.193-1.225.142-.18.042-.28-.1-.22zm.948-.523c-.09-.119-.551-.14-.84-.105-.291.035-.727.212-.69.319.02.04.06.022.26.004.2-.02.76-.091.876.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.097-.078.272-.282.39-.57.116-.29.187-.694.118-.783z"
        />
        <path
          fill="#000"
          d="M150.526 99.837c0 .421.01.773-.202 1.147-.172.305-.445.492-.75.492-.415 0-.657-.317-.657-.784 0-.922.827-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.255.026c-.36-.298-.424-.437-.621-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.588-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.13-.784-.133-.2-.386-.282-.608-.282-.413 0-.781.212-.87.65-.02.097-.09.193-.188.198l-1.05-.113c-.088-.02-.186-.091-.162-.226.242-1.273 1.392-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.22.184-.614.526-.83.718l-.003-.003"
        />
        <path
          fill="#f90"
          fillRule="nonzero"
          d="M152.796 103.115c-3.376 1.607-5.471.263-6.813-.554-.083-.051-.224.012-.101.153.447.541 1.911 1.847 3.822 1.847 1.913 0 3.051-1.043 3.193-1.225.142-.18.042-.28-.1-.22zm.948-.523c-.09-.119-.551-.14-.84-.105-.291.035-.727.212-.69.319.02.04.06.022.26.004.2-.02.76-.091.876.062.118.153-.178.885-.232 1.003-.053.119.02.149.118.07.097-.078.272-.282.39-.57.116-.29.187-.694.118-.783z"
        />
        <path
          fill="#000"
          d="M150.526 99.837c0 .421.01.773-.202 1.147-.172.305-.445.492-.75.492-.415 0-.657-.317-.657-.784 0-.922.827-1.09 1.609-1.09zm1.091 2.637a.226.226 0 0 1-.255.026c-.36-.298-.424-.437-.621-.721-.594.605-1.014.787-1.784.787-.91 0-1.62-.562-1.62-1.687 0-.878.477-1.476 1.154-1.768.588-.26 1.408-.305 2.035-.376v-.14c0-.258.02-.562-.13-.784-.133-.2-.386-.282-.608-.282-.413 0-.781.212-.87.65-.02.097-.09.193-.188.198l-1.05-.113c-.088-.02-.186-.091-.162-.226.242-1.273 1.392-1.656 2.42-1.656.527 0 1.215.14 1.63.538.527.492.477 1.148.477 1.862v1.686c0 .507.21.73.408 1.003.07.098.085.215-.003.288-.22.184-.614.526-.83.718l-.003-.003"
        />
      </g>
      <text
        xmlSpace="preserve"
        x={171.075}
        y={101.883}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={171.075}
          y={101.883}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Amazon"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={221.648}
        y={101.883}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 600,
          fontStretch: "normal",
          fontSize: "4.02932px",
          fontFamily: "Roboto Slab, serif",
          InkscapeFontSpecification: "&quot",
          wordSpacing: "-.768251px",
          fill: "#999",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 12.8674,
          strokeDasharray: "38.602,38.602",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
      >
        <tspan
          x={221.648}
          y={101.883}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 600,
            fontStretch: "normal",
            fontSize: "4.02932px",
            fontFamily: "Roboto Slab, serif",
            InkscapeFontSpecification: "&quot",
            fill: "#999",
            fillOpacity: 1,
            strokeWidth: 12.8674,
          }}
        >
          {"Walmart"}
        </tspan>
      </text>
      <g transform="translate(2378.426 -1736.763)">
        <rect
          width={9.065}
          height={8.854}
          x={-2182.584}
          y={1832.807}
          rx={3.274}
          ry={2.461}
          style={{
            fill: "#0071dc",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0965051,
            strokeDasharray: "none",
            strokeDashoffset: 210.392,
          }}
        />
        <path
          fill="#f3b53b"
          d="M-2178.064 1836.042c.17 0 .308-.09.329-.202l.165-1.895c0-.187-.218-.341-.494-.341s-.495.154-.495.34l.168 1.896c.018.115.159.2.327.2m-1.024.594c.085-.148.078-.313-.011-.387l-1.557-1.09c-.161-.094-.403.018-.54.257-.139.24-.116.506.045.598l1.725.803c.108.04.253-.037.338-.184m2.048 0c.085.147.23.223.34.184l1.723-.803c.161-.092.184-.358.05-.598-.139-.239-.383-.352-.544-.257l-1.557 1.09c-.087.074-.094.24-.01.387m-1.026 1.77c.17 0 .308.09.329.203l.165 1.896c0 .186-.218.34-.494.34s-.495-.154-.495-.34l.168-1.896c.018-.115.159-.2.327-.2m1.023-.591c.086-.147.23-.223.341-.184l1.723.803c.16.092.184.36.049.598-.138.239-.382.352-.543.257l-1.557-1.09c-.087-.074-.094-.24-.01-.384m-2.05 0c.085.145.078.31-.011.384l-1.557 1.09c-.161.092-.403-.018-.54-.257-.139-.24-.116-.506.045-.598l1.725-.803c.108-.04.253.037.338.184m4.475 1.309h.017c.018 0 .035-.01.035-.023 0-.014-.01-.023-.032-.023h-.018zm0 .083h-.037v-.15h.057c.03 0 .042 0 .053.011.01.01.016.018.016.032 0 .018-.014.03-.032.037.014 0 .024.018.028.037.01.023.01.032.01.037h-.036c-.01 0-.01-.018-.014-.034 0-.016-.011-.023-.03-.023h-.018v.06zm.023-.198c-.07 0-.118.055-.118.122s.05.12.118.12c.067 0 .115-.054.115-.12a.115.115 0 0 0-.115-.122zm0-.03a.152.152 0 1 1 0 .304.152.152 0 0 1-.157-.152c0-.085.07-.152.157-.152z"
          style={{
            fillRule: "evenodd",
            strokeWidth: 0.230033,
          }}
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
