import axios from "axios";

// const base_url = `http://localhost:8080`;
const base_url = `https://api.manyhands.charity`;

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const restCall = (params) => {
  const { url, method, data, successType, errorType, dispatch } = params;
  const axVars = {
    url: `${base_url}${url}`,
    method,
    data: data,
  };
  return instance(axVars)
    .then((resp) => {
      dispatch({ type: successType, payload: resp.data });
    })
    .catch((error) => {
      dispatch({ type: errorType, payload: error.message });
    });
};

export const apiRequest = (params) => {
  const { url, method, data } = params;
  return axios.request({
    url: `${base_url}${url}`,
    method,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
