export const GET_STORE_NAMES = "GET_STORE_NAMES";
export const GET_STORE_NAMES_ERROR = "GET_STORE_NAMES_ERROR";

export const GET_STORE_DISCOUNTS = "GET_STORE_DISCOUNTS";
export const GET_STORE_DISCOUNTS_ERROR = "GET_STORE_DISCOUNTS_ERROR";

export const GET_TRENDING_STORE = "GET_TRENDING_STORE";
export const GET_TRENDING_STORE_ERROR = "GET_TRENDING_STORE_ERROR";

export const SET_VALUE = "SET_VALUE";
