import * as actionTypes from "../types/login";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const loginUser = (obj: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/login`,
      method: "post",
      data: obj,
      successType: actionTypes.LOGIN_FULFILLED,
      errorType: actionTypes.LOGIN_ERROR,
      dispatch,
    });
  };
};

export const completeCreateOrganization = (
  token: string,
  obj: any
) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/charity/sign-up/${token}`,
      method: "post",
      data: obj,
      successType: actionTypes.COMPLETE_ORGANIZATION_INFORMATION_FULFILLED,
      errorType: actionTypes.COMPLETE_ORGANIZATION_INFORMATION_ERROR,
      dispatch,
    });
  };
};

