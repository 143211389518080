import * as actionTypes from "../types/charity";

import { Dispatch } from "redux";
import { restCall } from "../../utils/fetch";

export const getCharityTransaction = (
  id: string,
  start: string,
  end: string
) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/transactions/${id}/${start}/${end}`,
      method: "get",
      data: null,
      successType: actionTypes.TRANSACTION_FULFILLED,
      errorType: actionTypes.TRANSACTION_ERROR,
      dispatch,
    });
  };
};

export const getCharityShareAnalytics = (id: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/${id}`,
      method: "get",
      data: null,
      successType: actionTypes.SHARE_ANALYTICS_FULFILLED,
      errorType: actionTypes.SHARE_ANALYTICS_ERROR,
      dispatch,
    });
  };
};

export const postCharityShareAnalytics = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/${id}`,
      method: "post",
      data: data,
      successType: actionTypes.POST_SHARE_LINK_FULFILLED,
      errorType: actionTypes.POST_SHARE_LINK_ERROR,
      dispatch,
    });
  };
};

export const postCharityShareAnalyticsClear = () => ({
  type: actionTypes.POST_SHARE_LINK_CLEARED,
  payload: null,
});

// Admin analytic actions
export const getEmailAnalytics = (id: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/organizations/email`,
      method: "get",
      data: null,
      successType: actionTypes.GET_EMAIL_ANALYTICS_FULFILLED,
      errorType: actionTypes.GET_EMAIL_ANALYTICS_ERROR,
      dispatch,
    });
  };
};

export const postEmailAnalytics = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/organization/${id}`,
      method: "post",
      data: data,
      successType: actionTypes.POST_EMAIL_ANALYTICS_FULFILLED,
      errorType: actionTypes.POST_EMAIL_ANALYTICS_ERROR,
      dispatch,
    });
  };
};

export const postContact = (id: string, data: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/contact/${id}`,
      method: "post",
      data: data,
      successType: actionTypes.POST_CONTACT_FULFILLED,
      errorType: actionTypes.POST_CONTACT_ERROR,
      dispatch,
    });
  };
};

export const postEmailAnalyticsClear = () => ({
  type: actionTypes.POST_EMAIL_ANALYTICS_FULFILLED,
  payload: {},
});

export const clearPostContact = () => ({
  type: actionTypes.CLEAR_POST_CONTACT_FULFILLED,
  payload: {},
});

export const getEmailAddress = (domain: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/organization_address/${domain}`,
      method: "get",
      data: null,
      successType: actionTypes.GET_EMAIL_ADDRESSES_FULFILLED,
      errorType: actionTypes.GET_EMAIL_ADDRESSES__ERROR,
      dispatch,
    });
  };
};

export const patchOrganizationInformation = (id: string, obj: any) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/charity/${id}`,
      method: "patch",
      data: obj,
      successType: actionTypes.ORGANIZATION_PATCH_FULFILLED,
      errorType: actionTypes.ORGANIZATION_PATCH_ERROR,
      dispatch,
    });
  };
};

export const updateOrganizationError = (value: boolean | null) => ({
  type: actionTypes.UPDATE_ORGANIZATION_ERROR,
  payload: value,
});

export const getOrganizationByState = (state: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/get_organization_by_state/${state}`,
      method: "get",
      successType: actionTypes.GET_ORGANIZATION_BY_STATE_FULFILLED,
      errorType: actionTypes.GET_ORGANIZATION_BY_STATE_ERROR,
      dispatch,
    });
  };
};
// /contact/:id
export const deleteContactById = (id: number) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/contact/${id}`,
      method: "delete",
      successType: actionTypes.DELETE_CONTACT_FULFILLED,
      errorType: actionTypes.DELETE_CONTACT_ERROR,
      dispatch,
    });
  };
};
