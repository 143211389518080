import "../style.css";
import "./Partner.css";

import Button from "../../../button/SignUp";
import DropDown from "../../../DropDown";
import React from "react";

const Partner = () => {
  const arr = [
    {
      question: "Fundraising Made Effortless and Continuous!",
      answer:
        "1) Share the Many Hands Extension on your social networks and encourage followers to download it. This saves them money and generates effortless revenue for your organization, beyond just the initial sale.",
      answerTwo:
        "2) Continue to earn revenue. As long as users keep utilizing the Many Hands Extension, your organization will earn continuously, month after month, year after year!",
      answerThree:
        "3) We provide all the necessary marketing materials, including links, photos, and email templates.",
    },
    {
      question: "Payout Schedule",
      answer:
        "Enjoy hassle-free monthly payouts with the Many Hands Extension. Funds are automatically deposited monthly, ensuring consistent support. Payouts start after the third month to account for returns and adjustments.",
    },
    {
      question: "Higher Lifetime Payout Percentages",
      answer:
        "Join early for better financial rewards. Early adopters of the Many Hands Extension get 80% payout percentages, maximizing your organization’s earnings. The sooner you join, the more you gain, with preferential rates rewarding early and long-term commitment.",
    },
    {
      question: "Be the Exclusive Non-Profit for a User",
      answer:
        "If a user downloads the extension through a link from your organization, you will receive an 80% lifetime commission.",
    },
    {
      question: "Administrative Panel",
      answer:
        "Track your earnings, view analytics, and create fundraising partnerships directly through the panel.",
    },
    {
      question: "Enhance Your Fundraising Partnerships—Huge Revenue Potential",
      answer:
        "Enhance your existing corporate/fundraising partnerships by utilizing Many Hands. If a partner shares the Many Hands extension, it can expand your reach and fundraising capabilities. You can determine the portion of proceeds the partner receives. This means that if they share the Many Hands extension and 1,000 people download it, your organization could potentially earn $100,000 annually from those 1,000 users. You can then decide on the percentage of that revenue to allocate to them. The percentage is up to you.",
      answerTwo:
        "Think of a company or brand, an influencer, and their reach. It's also important to note that those 1,000 users will continue to generate money year after year as long as they are using the Many Hands extension.",
    },
  ];

  return (
    <div className="partner-parent primary-component-container">
      <div className="partner-text-wrapper tertiary-text">
        <img
          className="partner-image"
          src="https://media.manyhands.charity/static/many-hands-assets/innovator_partner_logo.svg"
        />
        <h3 style={{ color: "var(--color-secondary)", marginBottom: "20px" }}>
          As a new company, we're excited to reward our early adopters
        </h3>
      </div>

      <div className="partner-drop-down">
        {arr.map((obj: IFaqObject, idx: number) => (
          <DropDown key={idx} obj={obj} />
        ))}
      </div>
      <div className="partner-bottom-wrapper">
        <Button
          text={`Start Fundraising`}
          link={"/fundraiser-sign-up"}
          main_color={"var(--color-secondary)"}
          secondary_color={"var(--dark-green)"}
        />
      </div>
    </div>
  );
};

export default Partner;
