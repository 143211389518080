import "./CharitySelection.css";

import React, { useEffect, useState } from "react";

import Button from "../../../components/button/Button";
import ComputerSvg from "./CharitySelectSvg";
import StaticImage from "./StaticCharityImage";

const MyComponent: React.FC = (props) => {
  const [image, setImage] = useState<any>(false);

  const isSafari = () => {
    var userAgent = navigator.userAgent;
    var safariAgent = userAgent.indexOf("Safari") !== -1;
    var chromeAgent = userAgent.indexOf("Chrome") !== -1;
    var firefoxAgent = userAgent.indexOf("Firefox") !== -1;
    return safariAgent && !chromeAgent && !firefoxAgent;
  };

  useEffect(() => {
    if (isSafari()) {
      console.log("The browser is Safari.");
      setImage(true);
    } else {
      setImage(false);
      console.log("The browser is not Safari.");
    }
  }, []);

  return (
    <div className="myComponentContainer">
      <div className="leftDiv">
        <div className="childOfLeftDiv">
          {image ? <StaticImage /> : <ComputerSvg />}
        </div>
      </div>

      <div className="rightDiv">
        <h2>Select</h2>
        <h2>3 non-profits</h2>
        <p className="rightDivText">
          With the power to choose, you're not just shopping—you're making a
          difference! Select up to three charities to receive the affiliate
          commission from your purchases.
        </p>
        <Button
          main_color={"#fff"}
          secondary_color={"var(--color-secondary)"}
        />
      </div>
    </div>
  );
};

export default MyComponent;
