import React, { useEffect } from "react";

import H4 from "../../components/text/H4";
import Hoc from "../../hoc/Hoc";
import TrendingStoreCard from "../../components/card/TrendingStoreCard";
import { height } from "@mui/system";

interface ITrendingStores {
  setValue: Function;
  Props: any;
}

function TrendStores(Props: any) {
  const { getTrendingStores, coupons, setValue } = Props;
  const { trending_stores } = coupons;

  useEffect(() => {
    getTrendingStores();
  }, []);

  return (
    <div
      style={{
        marginTop: "10px",
        backgroundColor: "var(--color-secondary)",
        padding: "0px 20px 20px 20px",
        borderTop: "2px solid var(--dark-green)",
        borderRadius: " 5px",
      }}
    >
      <H4
        text={`Trending Stores`}
        text_color={"#fff"}
        margin={"10px 0px 10px 0px"}
      />
      {trending_stores.map((store: any, idx: number) => (
        <TrendingStoreCard
          key={idx}
          trending_stores={store}
          setValue={setValue}
        />
      ))}
    </div>
  );
}

export default Hoc(TrendStores);
