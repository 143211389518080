import "./Card.css";

import React from "react";

const FullWidthCardContainer: React.FC<{
  data: any;
  children: React.ReactNode;
}> = ({ data, children }) => {
  var dateSplit = "";

  if (data.end_date !== null) {
    dateSplit = data.end_date.split("-");
  }

  const redirectFrom = `http://redirect.viglink.com`;
  const u = data.cashback_link;
  const key = `952b3733c3a1ac395ef3a872db749456`;

  const link = `${redirectFrom}?u=${encodeURIComponent(u)}&key=${key}&cuid=manyhandwevsite`;

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div className="full-width-card-container-website">
        <div
          style={{
            margin: "0px",
            fontSize: "15px",
            color: "#0f172a",
            marginBottom: "7px",
            fontFamily: "Roboto Slab, serif",
            fontWeight: 600,
          }}
        >
          {data.title}
        </div>

        {data.description !== data.title && (
          <div
            style={{
              margin: "0px",
              fontSize: "15px",
              color: "#0f172a",
              marginBottom: "7px",
              fontFamily: "Roboto Slab, serif",
              fontWeight: 400,
            }}
          >
            {data.description}
          </div>
        )}

        {data.end_date !== null && (
          <div
            style={{
              margin: "0px",
              fontWeight: 600,
              color: "#999999",
              textDecoration: "none",
              marginBottom: "7px",
              fontSize: "15px",
              fontFamily: "Roboto Slab, serif",
            }}
          >
            {`ENDS  ${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`}
          </div>
        )}

        <div className="full-width-discount-container">
          <div className="discount-container ">
            {data.code.length > 0 && (
              <div
                style={{
                  border: "2px dashed var(--dark-green)",
                  padding: "5px 10px",
                  borderRadius: " 5px",
                }}
              >
                <p
                  style={{
                    margin: "0px",
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#00c7a4",
                    fontFamily: "Roboto Slab, serif",
                  }}
                >
                  {data.code}
                </p>
              </div>
            )}
          </div>
          <div className="full-width-button-container">{children}</div>
        </div>
      </div>
    </a>
  );
};
export default FullWidthCardContainer;
