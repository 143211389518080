import "../graph/graph.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

interface IAccordionComponent {
  postContact: Function;
  post_id: any;
  email: any;
  contacts: any;
}

const AccordionComponent: React.FC<IAccordionComponent> = ({
  postContact,
  post_id,
  email,
  contacts,
}) => {
  const contactObject = {
    person_name: "",
    email_address: "",
    email_address_type: "",
  };

  const [contact, setContact] = useState(contactObject);
  const { first_name, last_name, value, type } = email;

  useEffect(() => {
    setContact({
      person_name: first_name === null ? "" : first_name,
      email_address: value,
      email_address_type: type,
    });
  }, [email]);

  useEffect(() => {
    return () => {
      setContact(contactObject);
    };
  }, []);

  const setContactHandler = (e: any) => {
    setContact({ ...contact, [e.name]: e.value });
  };

  const postContactHandler = () => {
    postContact(post_id, contact);
  };

  const contactDisableHandler =
    contact.email_address_type.length <= 0 || contact.email_address.length <= 0;

  const personDisableHandler =
    contact.email_address_type.length <= 0 ||
    contact.email_address.length <= 0 ||
    contact.person_name.length <= 0;

  const disabledHandlerAccordion =
    contact &&
    contact.email_address_type &&
    contact.email_address_type === "personal"
      ? personDisableHandler
      : contactDisableHandler;

  return (
    <>
      {value !== contacts && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#ececec" }}
          >
            <Typography>
              {first_name !== null ? `${first_name} ${last_name}` : `${value}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              id="outlined-basic"
              label="EMAIL ADDRESS"
              variant="outlined"
              value={contact.email_address}
              name="email_address"
              onChange={(e) => setContactHandler(e.target)}
              style={{ marginTop: "20px" }}
            />
            <Box>
              <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="demo-simple-select-label">
                  EMAIL ADDRESS TYPE
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={contact.email_address_type}
                  name="email_address_type"
                  onChange={(e) => setContactHandler(e.target)}
                >
                  <MenuItem
                    value={"company"}
                  >{`Company (e.g. info@name.com)`}</MenuItem>
                  <MenuItem value={"personal"}>Personal</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {contact.email_address_type === "personal" && (
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="person_name"
                value={contact.person_name}
                onChange={(e) => setContactHandler(e.target)}
                style={{ marginTop: "20px" }}
              />
            )}
            <Button
              variant="contained"
              color="inherit"
              style={{
                color: disabledHandlerAccordion ? "grey" : "#00c7a4",
                marginTop: "20px",
                backgroundColor: disabledHandlerAccordion
                  ? "#d1d1d1"
                  : "#6b21a8",
              }}
              onClick={() => postContactHandler()}
              disabled={disabledHandlerAccordion}
            >
              ADD EMAIL
            </Button>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default AccordionComponent;
