import * as React from "react";
const SvgComponent = ({ createTab }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
  >
    <rect
      width={52.917}
      height={52.917}
      rx={7.889}
      ry={7.889}
      style={{
        fill: createTab === "view" ? "#BBBBBB" : "#e6e6e6",
        strokeWidth: 0.144,
      }}
    />
    <path
      d="M-222.451 18.81a7.102 7.102 0 0 1 10.044 0l3.034 3.034-1.674 1.674-3.034-3.034a4.733 4.733 0 0 0-6.696 0 4.742 4.742 0 0 0 0 6.697l3.034 3.034-1.674 1.674-3.034-3.034a7.103 7.103 0 0 1 0-10.045zm11.09 17.787a4.7 4.7 0 0 0 3.348 1.387 4.7 4.7 0 0 0 3.348-1.387 4.738 4.738 0 0 0 0-6.696l-3.034-3.034 1.675-1.675 3.034 3.034a7.104 7.104 0 0 1-10.045 10.046l-3.035-3.034 1.676-1.675zm-4.033-12.403-1.673 1.674 7.037 7.038 1.674-1.674z"
      style={{
        fill: "#00c7a4",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 1.18109,
      }}
      transform="translate(248.018 -.399)"
    />
    <path
      d="M-214.526 8.881a9.127 9.127 0 0 0-12.908 0l-3.899 3.9 2.151 2.15 3.9-3.899a6.083 6.083 0 0 1 8.605 0 6.094 6.094 0 0 1 0 8.606l-3.9 3.9 2.152 2.15 3.899-3.899a9.128 9.128 0 0 0 0-12.908zm-14.253 22.857a6.04 6.04 0 0 1-4.302 1.783 6.04 6.04 0 0 1-4.302-1.783 6.09 6.09 0 0 1 0-8.604l3.899-3.9-2.153-2.152-3.899 3.9c-3.564 3.564-3.564 9.343 0 12.909a9.103 9.103 0 0 0 6.455 2.673 9.103 9.103 0 0 0 6.455-2.673l3.899-3.9-2.153-2.152zm5.183-15.938 2.151 2.151-9.044 9.044-2.15-2.151z"
      style={{
        fill: "#6b21a8",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 1.5178,
      }}
      transform="translate(248.018 -.399)"
    />
    <path
      d="M-225.644 32.874a4.825 4.825 0 0 1 6.823 0l2.061 2.061-1.137 1.137-2.06-2.06a3.215 3.215 0 0 0-4.55 0 3.221 3.221 0 0 0 0 4.548l2.061 2.061-1.137 1.137-2.06-2.06a4.825 4.825 0 0 1 0-6.824zm7.534 12.082a3.193 3.193 0 0 0 2.274.943 3.219 3.219 0 0 0 2.274-5.49l-2.06-2.062 1.137-1.138 2.06 2.061a4.826 4.826 0 0 1-6.823 6.824l-2.06-2.06 1.137-1.139zm-2.74-8.425-1.137 1.137 4.78 4.781 1.138-1.137z"
      style={{
        fill: "#00c7a4",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 0.802289,
      }}
      transform="translate(248.018 -.399)"
    />
    <path
      d="M-215.298 31.471a5.365 5.365 0 0 1-.182 7.585l-2.346 2.235-1.234-1.294 2.346-2.236a3.575 3.575 0 0 0 .122-5.056 3.582 3.582 0 0 0-5.057-.122l-2.346 2.236-1.233-1.294 2.346-2.236a5.365 5.365 0 0 1 7.584.182zm-13.631 8.052a3.55 3.55 0 0 0-1.109 2.503 3.579 3.579 0 0 0 6.043 2.675l2.346-2.237 1.234 1.295-2.346 2.236a5.366 5.366 0 0 1-7.403-7.767l2.346-2.236 1.235 1.295zm9.438-2.82-1.234-1.295-5.441 5.187 1.233 1.294z"
      style={{
        fill: "#6b21a8",
        fillOpacity: 1,
        fillRule: "evenodd",
        stroke: "none",
        strokeWidth: 0.892079,
      }}
      transform="translate(248.018 -.399)"
    />
  </svg>
);
export default SvgComponent;
