import "./NonProfitSlider.css";
import "swiper/swiper.min.css";

import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper";
import React from "react";
import SliderCard from "./CardContainer";
import { newCharityArr as charity_array } from "../../../utils/charity";

const CharitySlider: React.FC = () => {
  return (
    <div className="charitySliderContainer">
      <div className="text-wrap">
        <h2 style={{ color: "var(--dark-green)" }}>Our charities</h2>
      </div>
      <div className="charityCardContainer">
        <Swiper
          autoplay={{ delay: 3000 }}
          slidesPerView={1}
          spaceBetween={45}
          slidesPerGroup={1}
          centeredSlides={true}
          loop={true}
          speed={2000}
          loopFillGroupWithBlank={true}
          modules={[Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {charity_array.map((charity: any, idx: number) => (
            <SwiperSlide key={idx}>
              <SliderCard charity={charity} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CharitySlider;
