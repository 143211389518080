import "./styles/home.css";

import { Alert, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";

import AdminPage from "./AdminPage";
import CharityAdminPage from "./CharityAdminPage";
import H1 from "../components/text/H1";
import Hoc from "../hoc/Hoc";
import { styled } from "@mui/material/styles";

interface AccountTypeHandlerProps {
  account_type: string;
}

const AccountTypeHandler: React.FC<AccountTypeHandlerProps> = ({
  account_type,
}) => {
  switch (account_type) {
    case "admin":
      return <AdminPage />;
    case "charity":
      return <CharityAdminPage />;
    default:
      return null;
  }
};

function Login(Props: any) {
  const {
    login: {
      user_info: { user, charity = false },
      login_error,
    },
    loginUser,
  } = Props;

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "var(--color-secondary)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--color-secondary)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--lite-gray)",
      },
      "&:hover fieldset": {
        borderColor: "var(--lite-gray)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--lite-gray)",
      },
    },
  });

  const [userInfo, setUserInfo] = useState<any>({
    email: "",
    password: "",
  });

  const inputHandler = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    setUserInfo((prevState: object) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = () => {
    loginUser(userInfo);
  };

  return (
    <>
      {user && user.account_type ? (
        <AccountTypeHandler account_type={user.account_type} />
      ) : (
        <>
          <div className="about-container">
            <div className="home-child-container about-text">
              <h1>Login</h1>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
                height: "75vh",
              }}
            >
              {login_error && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">
                    Email of password were incorrect. Please try
                    again!&nbsp;&nbsp;
                    <a
                      style={{
                        color: "#000",
                        fontSize: "15px",
                        fontWeight: "600",
                        textDecoration: "underline",
                      }}
                      href="/reset-password-request"
                    >
                      Reset password
                    </a>
                  </Alert>
                </Stack>
              )}
              <TextField
                value={userInfo.email}
                name={"email"}
                onChange={(e) => inputHandler(e)}
                sx={{ m: 2 }}
                size="small"
                variant="outlined"
                label={"User name"}
                id="custom-css-outlined-input"
              />
              <TextField
                value={userInfo.password}
                name={"password"}
                onChange={(e) => inputHandler(e)}
                sx={{ m: 2 }}
                size="small"
                variant="outlined"
                // type="password"
                label={"Password"}
                id="custom-css-outlined-input"
              />
              <Button
                onClick={() => submitHandler()}
                sx={{ m: 2 }}
                variant="contained"
                color="inherit"
                style={{
                  color: "#fff",
                  backgroundColor: "var(--color-secondary)",
                }}
              >
                LOGIN
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Hoc(Login);
