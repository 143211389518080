import "../style.css";
import "./contact.css";

import Button from "../../../button/SignUp";
import React from "react";

const Partner = () => {
  return (
    <div className="contact-parent primary-component-container">
      <h2 style={{ color: "var(--dark-green)", paddingBottom: "1rem" }}>
        Any Questions
      </h2>
      <h3 style={{ color: "var(--dark-green)", fontWeight: "200" }}>
        We learn and grow from your inquires.
      </h3>
      <Button
        text={`Contact`}
        link={"/contact"}
        main_color={"var(--dark-green)"}
        secondary_color={"var(--color-secondary)"}
      />
    </div>
  );
};

export default Partner;
