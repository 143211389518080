import "./demo.css";

import React, { useState } from "react";

import Button from "../../../button/SignUp";
import DemoVideo from "../../../organization-admin/DemoVideo";

interface IDemo {
  page?: string;
}
const Demo: React.FC<IDemo> = ({ page }) => {
  const [videoSrc, setVideoSrc] = useState<string>(
    "videos/extension_new_j1pqra"
  );

  const changeVideo = (src: string) => {
    setVideoSrc(src);
  };

  return (
    <div className="primary-component-container demo-parent">
      <div className="secondary-flex-100 demo-text-controls-container">
        <div className="secondary-flex-50 demo-text-container">
          <div
            className="demo-text-h2-h3-wrapper"
            style={{ marginBottom: page === "home" ? "10px" : "0px" }}
          >
            <h2 style={{ color: "var(--dark-green)" }}>DEMO</h2>
            <h3 style={{ color: "#fff" }}>OF EXTENSION</h3>
          </div>
          {page !== "home" && (
            <h3
              style={{
                color: "var(--dark-green)",
                marginBottom: "10px",
                fontWeight: "200",
              }}
            >
              {"And the Fundraiser Admin Panel".toUpperCase()}
            </h3>
          )}
        </div>

        <div className="secondary-flex-50 demo-controls-container">
          {page !== "home" && (
            <>
              <button
                className={
                  videoSrc === "videos/extension_new_j1pqra"
                    ? "demo-button demo-button-selected"
                    : "demo-button"
                }
                onClick={() => changeVideo("videos/extension_new_j1pqra")}
              >
                <p>Extension</p>
              </button>
              <button
                className={
                  videoSrc === "videos/brief_admin"
                    ? "demo-button demo-button-selected"
                    : "demo-button"
                }
                onClick={() => changeVideo("videos/brief_admin")}
              >
                <p>Admin Panel</p>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="secondary-flex-100">
        <DemoVideo autoPlay={false} loop={false} video={videoSrc} />
      </div>
      <div className="demo-bottom-wrapper">
        {page === "home" ? (
          <Button
            main_color="var(--dark-green)"
            secondary_color="var(--color-secondary)"
          />
        ) : (
          <Button
            text="Start Fundraising"
            link="/fundraiser-sign-up"
            main_color="var(--dark-green)"
            secondary_color="var(--color-secondary)"
          />
        )}
      </div>
    </div>
  );
};

export default Demo;
