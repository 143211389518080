import "../style.css";
import "./header.css";

import React from "react";

const CharityHeader = () => {
  return (
    <div className="charity-lp-header-container">
      <div className="charity-header-child-container">
        <div className="charity-header-text-wrapper">
          <h1>{`Fundraising`.toLocaleUpperCase()}</h1>
          <h3>Made Effortless & Continuous</h3>
        </div>
      </div>
    </div>
  );
};

export default CharityHeader;
