import "./graph.css";

import React, { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import Bar from "../../static/bar.jsx";
import BarGraph from "./BarGraph";
import Circle from "../../static/circle.jsx";
import CircleGraph from "./CircleGraph";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Hoc from "../../hoc/Hoc";
import Line from "../../static/line.jsx";
import LineGraph from "./LineGraph";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";

interface IGraph {
  getCharityTransaction: any;
  charity: any;
  login: any;
}
const Graph: React.FC<IGraph> = ({ getCharityTransaction, charity, login }) => {
  const today =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

  const [graph, setGraph] = useState("line");
  const [value, setValue] = useState<any>(null);
  const [dateDisplay, setDateDisplay] = useState<any>({ start: "", end: "" });

  const {
    transactions: { totals = [], influencer_totals, charity_totals },
  } = charity;

  const prefix =
    login &&
    login.user_info.charity &&
    login.user_info.charity.affiliate_id_prefix;

  useEffect(() => {
    const today = new Date();

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const formattedToday = today.toISOString();
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString();

    const isoStart =
      value === null
        ? formattedThirtyDaysAgo
        : value &&
          value[0] &&
          value[0].$d &&
          new Date(value[0].$d).toISOString();

    const isoEnd =
      value && value[1] && value[1].$d
        ? new Date(value[1].$d).toISOString()
        : formattedToday;

    const formatDateToMDY = (dateString: any) => {
      const date = new Date(dateString);
      return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    };

    const displayStart = formatDateToMDY(isoStart);
    const displayEnd = formatDateToMDY(isoEnd);

    setDateDisplay({ start: displayStart, end: displayEnd });
    getCharityTransaction(prefix, isoStart, isoEnd);
  }, [value]);

  useEffect(() => {
    if (value !== null) {
      const start =
        value === null
          ? today
          : value &&
            value[0] &&
            value[0].$d &&
            new Date(value[0].$d).toISOString();
      const end =
        value && value[1] && value[1].$d && new Date(value[1].$d).toISOString();
      const formatDateToMDY = (dateString: any) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
      };

      const displayStart = formatDateToMDY(start);
      const displayEnd = formatDateToMDY(end);
      setDateDisplay({ displayStart, displayEnd });
      getCharityTransaction(prefix, start, end);
    }
  }, [value]);

  return (
    <>
      <div
        style={{
          paddingLeft: "30px",
          width: "400px",
          margin: "0px 0px 20px 00px",
        }}
      >
        {graph !== "bar" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker"]}>
              <DateRangePicker
                onChange={(newValue: any) => setValue(newValue)}
                localeText={{ start: dateDisplay.start, end: dateDisplay.end }}
              />
            </DemoContainer>
          </LocalizationProvider>
        )}
      </div>
      <div className="graph-parent-container">
        <div className="graph-child--graph-container">
          {graph === "line" && <LineGraph totals={totals} />}
          {graph === "circle" &&
            influencer_totals &&
            influencer_totals.length > 0 && (
              <CircleGraph
                partner={influencer_totals}
                charity={charity_totals}
              />
            )}
          {graph === "bar" && <BarGraph />}
        </div>

        <div className="graph-child--buttons-container">
          <div className="graph-type-button" onClick={() => setGraph("line")}>
            <Line graph={graph} />
            Total Revenue
          </div>
          <div className="graph-type-button" onClick={() => setGraph("circle")}>
            <Circle graph={graph} />
            Revenue breakdown
          </div>
          <div className="graph-type-button" onClick={() => setGraph("bar")}>
            <Bar graph={graph} />
            Share Analytics
          </div>
        </div>
      </div>
    </>
  );
};

export default Hoc(Graph);
