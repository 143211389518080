import "./Sections.css";

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import ButtonLink from "../button/Button";
import ChildContainer from "../containers/ChildContainer";
import FirstSlide from "./how-to-svg/one";
import FourthSlide from "./how-to-svg/four";
import H2 from "../text/H2";
import H3 from "../text/H3";
import ParentContainer from "../containers/ParentContainer";
import SecondSlide from "./how-to-svg/two";
import ThirdSlide from "./how-to-svg/three";

const text = [
  {
    title: "How it works",
    description: [
      "Use our discounts, specials, and sales prior to making a purchase.",
      "We receive a commission based on your purchase.",
      "80% of the commission goes to your selected charities.",
    ],
  },
  {
    title: "Save stores",
    description:
      "Save the stores you frequent, and see the number of current discounts and sales available. Also, easily search for additional stores from this screen.",
  },
  {
    title: "Choose three",
    description:
      "Select three charities to allocate your affiliate commission money. Change them anytime!",
  },
  {
    title: "Many Hands",
    description: `The more people using the extension, the more money provided for charities. Please consider sharing. We would greatly appreciate it.`,
  },
];

const transformArrayZero = [
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-30deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-15deg) scaleX(1) scaleY(1)",
];

const transformArrayOne = [
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-15deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-30deg) scaleX(1) scaleY(1)",
];

const transformArrayTwo = [
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-30deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-15deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) scaleX(1) scaleY(1)",
];

const transformArrayThree = [
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-30deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-15deg) scaleX(1) scaleY(1)",
  "translateX(0px) translateY(0px) rotateX(0deg) rotateY(0deg) rotateZ(-0deg) scaleX(1) scaleY(1)",
];

const indexArrayZero = [10, 0, 8, 9];

const indexArrayOne = [9, 10, 0, 8];

const indexArrayTwo = [8, 9, 10, 7];

const indexArrayThree = [7, 8, 9, 10];

function Carousel({ visible_button = true }) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // clearTimeout()
    setTimeout(() => {
      setIndex(index === 3 ? 0 : index + 1);
    }, 7500);
  }, [index]);

  const zero = useSpring({
    zIndex: indexArrayZero[index],
    position: "absolute",
    transform: transformArrayZero[index],
    transformOrigin: "left bottom",
    config: {
      duration: 150,
      mass: 1,
      tension: 180,
      friction: 12,
    },
  });

  const one = useSpring({
    zIndex: indexArrayOne[index],
    position: "absolute",
    transform: transformArrayOne[index],
    transformOrigin: "left bottom",
    config: {
      duration: 150,
      mass: 1,
      tension: 160,
      friction: 10,
    },
  });

  const two = useSpring({
    zIndex: indexArrayTwo[index],
    position: "absolute",
    transform: transformArrayTwo[index],
    transformOrigin: "left bottom",
    config: {
      duration: 150,
      mass: 1,
      tension: 160,
      friction: 10,
    },
  });

  const three = useSpring({
    zIndex: indexArrayThree[index],
    position: "absolute",
    transform: transformArrayThree[index],
    transformOrigin: "left bottom",
    config: {
      duration: 150,
      mass: 1,
      tension: 160,
      friction: 10,
    },
  });

  return (
    <ParentContainer background="#dedede">
      <ChildContainer class_name={"webpage"}>
        <animated.div className="extension-card-container" style={zero}>
          <FirstSlide />
        </animated.div>

        <animated.div className="extension-card-container" style={one}>
          <SecondSlide />
        </animated.div>

        <animated.div className="extension-card-container" style={two}>
          <ThirdSlide />
        </animated.div>

        <animated.div className="extension-card-container" style={three}>
          <FourthSlide />
        </animated.div>
      </ChildContainer>

      <ChildContainer class_name={"step-one-text"}>
        <H2
          className="carousel-h2"
          text={text[index].title}
          text_main={"var(--color-secondary)"}
          text_secondary={"var(--dark-green)"}
        />

        {typeof text[index].description === "object" ? (
          <ul style={{ marginTop: "20px", flexDirection: "column" }}>
            {text[index].description.map((text, idx) => (
              <li key={idx} style={{ padding: "0px" }}>
                <H3 text={text} margin_top={"15px"} text_color={"#646464"} />
              </li>
            ))}
            <li></li>
          </ul>
        ) : (
          <H3 text={text[index].description} text_color={"#646464"} />
        )}
        {visible_button && (
          <ButtonLink
            color={"var(--white)"}
            background={"var(--color-secondary)"}
            border={"solid 2px #dedede"}
            boxShadow={"--dark-green"}
          />
        )}
      </ChildContainer>
    </ParentContainer>
  );
}
export default Carousel;
