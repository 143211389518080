import "./graph.css";

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";

interface IGraph {
  totals: any;
}
const LineGraph: React.FC<IGraph> = ({ totals }) => {
  const formattedGraphData = totals.map((data: any) => {
    const date =
      data && data.created_at ? data.created_at.split("T")[0].split("-") : "";
    return {
      created_at: date === "" ? "" : `${date[1]}-${date[2]}-${date[0]}`,
      revenue: Number(Math.round((data.revenue * 100) / 100).toFixed(2)),
    };
  });

  const sumAllRevenue = (arr: any) => {
    var sum = arr.reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue.revenue;
    }, 0);
    return sum;
  };

  const sumAllRevenueTest = (arr: any) => {
    const sum = sumAllRevenue(arr);
    const stripeFee = sum * (2.9 / 100) + 0.3;
    const netRevenue = sum - stripeFee;
    return netRevenue.toFixed(2);
  };

  return (
    <>
      <div className="graph-top-container">
        <div style={{maxWidth: '297px'}} className="graph-total-container total">
          {" "}
          Total: ${sumAllRevenueTest(formattedGraphData)}
        </div>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={formattedGraphData ?? []}
          margin={{
            left: -12,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" fill="#f8fafc" />
          <XAxis dataKey="created_at" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            animationDuration={3000}
            dataKey="revenue"
            stroke="#6b21a8"
            strokeWidth={3}
            dot={{ strokeWidth: 4 }}
            activeDot={{ stroke: "#00c7a4", strokeWidth: 4, r: 4 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineGraph;
