import React from "react";

interface Webpage {
  text_color: string;
  text?: string;
  margin?: string;
}

const H4: React.FC<Webpage> = ({ text_color, text, margin = "25px 0px 0px 0px" }) => {
  return <h4 style={{ color: text_color, margin: margin }}>{text}</h4>;
};

export default H4;
