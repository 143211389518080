import "./Button.css";

import React, { useState } from "react";

interface IButton {
  text?: string;
  link?: string;
  main_color?: string;
  secondary_color?: string;
}

const Button: React.FC<IButton> = ({
  text = "Get Extension",
  link = "https://chrome.google.com/webstore/detail/many-hands-charity-extens/nhenigdkimmdialnedpnckefaioddald",
  main_color,
  secondary_color,
}) => {
  const [hover, setHover] = useState(false);

  const onExit = {
    color: main_color,
    backgroundColor: "transparent",
    border: `solid 2px ${main_color}`,
  };

  const onHover = {
    color: main_color,
    backgroundColor: secondary_color,
    border: `solid 2px ${main_color}`,
  };

  const style = !hover ? onExit : onHover;
  return (
    <a
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      href={link}
      target="_blank"
      rel="noreferrer"
      className="button-ext-link-container"
      style={style}
    >
      <p>{text}</p>
    </a>
  );
};

export default Button;
