import "./Discounts.css";
import "swiper/swiper.min.css";

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { animated, useSpring } from "react-spring";

import { Autoplay } from "swiper";
import Button from "../../button/Button";

const SlideContent: React.FC<{
  word: string;
  idx: number;
  setIndex: Function;
}> = ({ word, idx, setIndex }) => {
  const swiperSlide = useSwiperSlide();
  const isActive = swiperSlide.isActive;
  useEffect(() => {
    if (isActive) {
      setIndex(idx);
    }
  }, [isActive]);
  return (
    <h2 style={isActive ? { color: "#000", opacity: "1" } : {}}>{word}</h2>
  );
};

interface Word {
  brand: string;
  discounts: number;
}

const Discounts: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [number, setNumber] = useState<number>(0);

  const words: Word[] = [
    { brand: "Walmart", discounts: 73 },
    { brand: "Target", discounts: 65 },
    { brand: "Best Buy", discounts: 123 },
    { brand: "Apple", discounts: 114 },
    { brand: "REI", discounts: 78 },
    { brand: "Wayfair", discounts: 129 },
    { brand: "Kroger", discounts: 119 },
    { brand: "BigCommerce", discounts: 114 },
    { brand: "Grubhub", discounts: 126 },
    { brand: "Nike", discounts: 122 },
    { brand: "Home Depot", discounts: 139 },
    { brand: "HP ", discounts: 131 },
    { brand: "Macy's", discounts: 429 },
    { brand: "Nordstrom", discounts: 89 },
    { brand: "Nordstrom Rack", discounts: 239 },
    { brand: "Kohl's", discounts: 40 },
    { brand: `Sam’s Club`, discounts: 36 },
    { brand: "Lulu's", discounts: 112 },
    { brand: "American Eagle", discounts: 144 },
  ];

  const props = useSpring({
    from: { number: words[index].discounts },
    to: { number },
    delay: 200,
    config: { mass: 1, tension: 100, friction: 25 },
  });

  useEffect(() => {
    setNumber(words[index].discounts);
  }, [words[index].discounts]);

  return (
    <div className="discount-scroll-container">
      <div className="discount-scroll-text-container">
        <div className="discount-scroll-text">
          <animated.h2 style={{ color: "#000" }}>
            {props.number.to((n) => n.toFixed(0))}
          </animated.h2>
          <h3>Discount count</h3>
          <Button
            main_color={"var(--dark-green)"}
            secondary_color={"var(--color-secondary)"}
          />
        </div>
      </div>
      <div className="discount-scroll-animation">
        <Swiper
          direction={"vertical"}
          autoplay={{ delay: 2500 }}
          slidesPerView={8}
          centeredSlides={true}
          loop={true}
          speed={500}
          loopFillGroupWithBlank={true}
          modules={[Autoplay]}
          // spaceBetween={65}
          autoHeight={true}
          // slidesOffsetBefore={-85}
          style={{ margin: "0px" }}
        >
          {words.map((word, idx) => (
            <SwiperSlide key={idx} className="animated-slide-container">
              <SlideContent word={word.brand} idx={idx} setIndex={setIndex} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Discounts;
