import "./graph.css";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect } from "react";

import Hoc from "../../hoc/Hoc";
import Tooltip from "@mui/material/Tooltip";

const BarGraph = ({ login, getCharityShareAnalytics, charity: analytics }) => {
  const {
    user_info: { charity },
  } = login;

  const data = analytics && analytics.share_analytics;

  useEffect(() => {
    getCharityShareAnalytics(charity.affiliate_id_prefix);
  }, []);

  const sumAllClicks = (arr) => {
    var sum = arr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.share_click;
    }, 0);
    return sum;
  };

  const sumAllDownloads = (arr) => {
    var sum = arr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.share_download;
    }, 0);
    return Number(sum);
  };

  const clicks =
    analytics &&
    analytics.share_analytics &&
    sumAllClicks(analytics.share_analytics);

  const downloads =
    analytics &&
    analytics.share_analytics &&
    sumAllDownloads(analytics.share_analytics);

  return (
    <>
      <div className="graph-top-container">
        <Tooltip title="Total number of link click" placement="top" arrow>
          <div className="graph-total-container total"> Clicks: {clicks}</div>
        </Tooltip>

        <Tooltip
          title="Total number of Extension downloads"
          placement="top"
          arrow
        >
          <div className="graph-total-container download">
            Downloads: {downloads}
          </div>
        </Tooltip>
      </div>
      <div style={{ width: "100%", height: "92%", marginBottom: "20px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={730} height={8000} data={analytics.share_analytics}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="title" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar name="Click" dataKey="share_click" fill="#00c7a4" />
            <Bar name="Download" dataKey="share_download" fill="#6b21a8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Hoc(BarGraph);
