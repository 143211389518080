import "./styles/home.css";

import React, { Fragment } from "react";

import Button from "../components/button/Button";
import H1 from "../components/text/H1";
import H2 from "../components/text/H2";
import H4 from "../components/text/H4";
import Hoc from "../hoc/Hoc";

function About(Props: any) {
  const arr: any = [
    {
      header: `Our Story`,
      text: `At Many Hands, we are a vibrant collective hailing from the tech industry, united by a shared desire for a more meaningful professional existence. Our journey began from a common frustration: working in environments that undervalued our contributions and engaging in projects that lacked a significant impact on the world. We yearned for our work to extend beyond the enrichment of a select few at the top, aspiring instead for it to have a genuine, positive effect on society. Fuelled by this collective discontent, we made a decisive leap towards change. Many Hands was born out of our ambition to infuse our work with purpose and to make a tangible difference in the lives of others.`,
    },
    {
      header: `Our Goal`,
      text: `Our primary mission is to support charitable organizations. By harnessing affiliate commissions generated through online shopping, we direct a substantial 80% of our earnings to various charities. We take pride in the system we've created, which not only supports but also amplifies the reach and impact of numerous commendable charities. Starting with a roster of nationally recognized charities, we are always eager to expand our support to include more organizations, guided by nominations from our community.`,
    },
    {
      header: `A Vision for the Future`,
      text: `As we forge ahead, our commitment to creating a positive societal impact remains unwavering. Through strategic partnerships with non-profits and a unique approach to funding, we are redefining the role of technology and commerce in supporting charitable causes. Many Hands stands as a testament to what is achievable when innovation is aligned with altruism, leading the way towards a future where technology not only advances but also heals and uplifts.`,
    },
    {
      header: `How You Can Contribute`,
      text: `Contributing to our cause is straightforward and effortless: simply download our Chrome Extension and continue shopping online as you normally would. This simple action can transform your shopping into an act of philanthropy, supporting non-profit organizations without any additional cost to you.`,
    },
  ];
  return (
    <Fragment>
      <div className="about-container">
        <div className="home-child-container about-text">
          <h1 style={{ color: "#fff" }}>About</h1>
        </div>
      </div>
      <div className="about-story-container">
        {arr.map((obj: any, idx: any) => (
          <Fragment key={idx}>
            <h2
              style={{
                color: "var(--dark-green)",
                margin: "30px 0px 20px 0px",
              }}
            >
              {obj.header}
            </h2>
            <p>{obj.text}</p>
          </Fragment>
        ))}
        <Button
          main_color={"var(--dark-green)"}
          secondary_color={"var(--color-secondary)"}
        />
      </div>
    </Fragment>
  );
}

export default Hoc(About);
