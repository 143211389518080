import "./styles/home.css";

import { Box, Tab, Tabs } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import EmailAnalytics from "../components/table/EmailAnalytics";
import EmailBuilder from "../components/table/EmailBuilder";
import H4 from "../components/text/H4";
import Hoc from "../hoc/Hoc";
import OrganizationEdit from "../components/table/OrganizationEdit";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IEmail {
  login: any;
  charity: any;
  getEmailAnalytics: any;
}
const AdminPage: React.FC<IEmail> = ({ login, charity, getEmailAnalytics }) => {
  const {
    user_info: { user },
  } = login;

  const { all_email_admin_data } = charity;

  useEffect(() => {
    // getEmailAnalytics(user && user.uuid);
  }, []);

  const [value, setValue] = React.useState(0);
  const [rows, setRows] = useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const columns = [
    "charity_name",
    "charity_type",
    "email_open",
    "extension_click",
    "website_click",
    "replay_date",
  ];

  return (
    <Fragment>
      <div className="charity-admin-container">
        <div className="home-child-container about-text">
          <H4
            text={"ADMIN PANEL"}
            text_color={"#fff"}
            margin="0px 0px 0px 0px"
          />
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ fontFamily: '"Roboto", sans-serif', color: "#00c7a4" }}
            textColor="inherit"
          >
            <Tab
              label="Analytics"
              style={{ fontSize: "18px", fontFamily: '"Roboto", sans-serif' }}
              {...a11yProps(0)}
            />
            <Tab
              label="Email Builder"
              style={{ fontSize: "18px", fontFamily: '"Roboto", sans-serif' }}
              {...a11yProps(1)}
            />
            <Tab
              label="Edit Or / Email"
              style={{ fontSize: "18px", fontFamily: '"Roboto", sans-serif' }}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EmailAnalytics columns={columns} rows={all_email_admin_data} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <EmailBuilder />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <OrganizationEdit />
        </CustomTabPanel>
      </Box>
    </Fragment>
  );
};

export default Hoc(AdminPage);
