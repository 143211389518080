import "./styles/home.css";

import AI from "../components/sections/AI-search/AI-Container";
import Banner from "../components/sections/banner/Banner";
import BrandSuggestion from "../components/sections/suggestions/BrandSuggestion";
import CharitySelect from "../components/sections/newSection/CharitySelectSection";
// import Carousel from "../components/sections/Carousel";
import CharitySlider from "../components/sections/non-profit-slider/CharitySlider";
import Demo from "../components/sections/charity-landing-page/demo/Demo";
import Description from "../components/sections/description/Description";
import Discount from "../components/sections/discounts/Discounts";
import HeaderIntro from "../components/sections/header/HeaderIntro";
import Hoc from "../hoc/Hoc";
// import HowTo from "../components/sections/HowTo";
import Merchant from "../components/sections/merchant/Merchant";
import React from "react";

// import Search from "../components/sections/Search";
// import Shop from "../components/sections/Shop";

function Home(Props: any) {
  return (
    <>
      <HeaderIntro />
      <Description
        text_one={`Many Hands is an AI-driven discount Chrome extension that donates 80% of
        its revenue to non-profit organizations of your choice.`}
        text_two={`Join us and become one of the Many Hands making a difference.`}
      />

      <AI />
      {/* Demo is new */}
      <Demo page={"home"} />
      {/* <BrandSuggestion /> */}
      <Discount />
      <CharitySelect />
      <Banner />
      <Merchant />
      <CharitySlider />
    </>
  );
}

export default Hoc(Home);
