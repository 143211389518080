import * as React from "react"
const SvgComponent = ({ createTab }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
  >
    <rect
      width={52.917}
      height={52.917}
      rx={7.889}
      ry={7.889}
      style={{
        fill: createTab === "email" ? "#BBBBBB" : "#e6e6e6",
        strokeWidth: 0.144,
      }}
    />
    <path
      d="M8.643 17.49v18.17c0 1.002.807 1.808 1.809 1.808h32.013a1.805 1.805 0 0 0 1.808-1.808V17.49L26.46 31.127z"
      style={{
        fill: "#6b21a8",
        fillOpacity: 1,
        strokeWidth: 0.383067,
      }}
    />
    <path
      d="M10.452 15.448c-.597 0-1.122.287-1.451.73L26.459 29.54l17.457-13.362a1.802 1.802 0 0 0-1.451-.73z"
      style={{
        fill: "#09baa5",
        strokeWidth: 0.383067,
      }}
    />
  </svg>
)
export default SvgComponent
