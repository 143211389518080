import "./component.css";

import React, { useState } from "react";
import { animated, useSpring } from "react-spring";

import H3 from "../components/text/H3";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IDropDown {
  obj: any;
}
const DropDown: React.FC<IDropDown> = ({ obj }) => {
  const [toggle, setToggle] = useState(false);

  const { marginTop, transform } = useSpring({
    marginTop: `${toggle ? 0 : 0}px`,
    transform: `rotate(${toggle ? 180 : 0}deg)`,
    config: { mass: 1, tension: 170, friction: 26 },
  });

  return (
    <div
      className="dropdown-container"
      onClick={() => setToggle((prevState) => !prevState)}
    >
      <div className="question-container">
        <div className="text-container">
          <H3
            text={`${obj.question}`}
            text_color={"#6F6F6F"}
            margin_top={"0px"}
          />
        </div>
        <div className="arrow-container">
          <animated.div
            className="arrow-wrapper"
            style={{ marginTop, transform }}
          >
            <KeyboardArrowUpIcon
              fontSize={"inherit"}
              className="arrow"
              style={{ fontSize: "45px" }}
            />
          </animated.div>
        </div>
      </div>
      {toggle && (
        <>
          <h4 style={{ color: "#6F6F6F", margin: "30px 10px 20px 10px" }}>
            {obj.answer}
          </h4>
          {obj.answerTwo && (
            <h4 style={{ color: "#6F6F6F", margin: "0px 10px 20px 10px" }}>
              {obj.answerTwo}
            </h4>
          )}
          {obj.answerThree && (
            <h4 style={{ color: "#6F6F6F", margin: "0px 10px 20px 10px" }}>
              {obj.answerThree}
            </h4>
          )}
        </>
      )}
    </div>
  );
};

export default DropDown;
