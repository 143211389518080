import "./Component.css";

import React from "react";

interface IContainer {
  background: string;
  children: React.ReactNode;
  height?: string;
}

const ParentContainer: React.FC<IContainer> = ({
  background,
  children,
  height = "100vh",
}) => {
  return (
    <div
      style={{ backgroundColor: background }}
      className="parent-container"
    >
      {children}
    </div>
  );
};

export default ParentContainer;
