import React, { useEffect } from "react";

import axios from "axios";

function Uninstall() {
  useEffect(() => {
    const location = window.location;
    const urlParams: any = new URLSearchParams(location.search);

    const user_cuid = urlParams.get("user_id");

    axios
      .delete(`https://api.manyhands.charity/users/${user_cuid}`)
      .then(() => {
        window.alert("You have been deleted");
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return <div></div>;
}

export default Uninstall;
