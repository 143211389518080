import { Dispatch, bindActionCreators, compose } from "redux";
import {
  createOrganizationInformationNoUuid,
  createOrganizationUsernamePassword,
} from "../store/actions/OrganizationSignUp";
import {
  patchOrganizationInformation,
  updateOrganizationError,
} from "../store/actions/charity";

import React from "react";
import { completeCreateOrganization } from "../store/actions/login";
import { connect } from "react-redux";

const mapStateToProps = (state: IOrganizationSignUp) => {
  const { OrganizationSignUp, login } = state;
  return {
    OrganizationSignUp,
    login,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      completeCreateOrganization: completeCreateOrganization,
      createOrganizationUsernamePassword: createOrganizationUsernamePassword,
      createOrganizationInformationNoUuid: createOrganizationInformationNoUuid,
      patchOrganizationInformation: patchOrganizationInformation,
      updateOrganizationError: updateOrganizationError,
    },
    dispatch
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Hoc = (OriginalComponent: any) => {
  const NewComponent: React.FC<Props> = ({ ...Props }) => {
    return <OriginalComponent {...Props} />;
  };
  return NewComponent;
};

const composeWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  Hoc
);

export default composeWrapper;
