import React from "react";

interface Webpage {
  text_color: string;
  text?: string;
  margin_top?: string;
}

const P: React.FC<Webpage> = ({ text_color, text, margin_top = "25px" }) => {
  return <p style={{ color: text_color, marginTop: margin_top }}>{text}</p>;
};

export default P;