import { Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import EmailDisplay from "./EmailDisplay";
import Hoc from "../../hoc/Hoc";
import { createEmailTemplate } from "./email-templates/emails";

interface IEmailTemplateSelector {
  postCharityShareAnalytics: (
    id: string,
    info: { title: string; description: string }
  ) => void;
  postCharityShareAnalyticsClear: Function;
  login: {
    user_info: { charity: { affiliate_id_prefix: string; title: string } };
  };
  charity: { post_share_link: { share_url: string }[] };
}

const EmailTemplateSelector: React.FC<IEmailTemplateSelector> = ({
  login,
  postCharityShareAnalytics,
  postCharityShareAnalyticsClear,
  charity,
}) => {
  const [serviceProvider, setServiceProvider] = useState("");
  const [template, setTemplate] = useState("");
  const [link, setLink] = useState("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [info, setInfo] = useState({ title: "", description: "" });

  

  useEffect(() => {
    if (charity.post_share_link?.length) {
      setOrganizationName(login.user_info.charity.title);
      setLink(charity.post_share_link[0].share_url);
      setTemplate(createEmailTemplate(link, organizationName, serviceProvider));
    }
  }, [serviceProvider, charity.post_share_link]);

  useEffect(() => {
    postCharityShareAnalyticsClear();
    setLink("");
    setTemplate("");
  }, []);

  useEffect(() => {
    if (link) {
      setTemplate(createEmailTemplate(link, organizationName, serviceProvider));
    }
  }, [link, serviceProvider]);

  useEffect(() => {
    setInfo((prevInfo) => ({
      ...prevInfo,
      description: `Email - ${serviceProvider}`,
    }));
  }, [serviceProvider]);

  const handleServiceProviderClick = (provider: any) => {
    setServiceProvider(provider);
  };

  const copyDesign = () => {
    const blob = new Blob([template], { type: "text/html" });
    const data = [new ClipboardItem({ "text/html": blob })];
    navigator.clipboard
      .write(data)
      .then(() =>
        alert(
          "HTML email design copied to clipboard! Ready to paste into Gmail."
        )
      )
      .catch((err) => console.error("Failed to copy HTML: ", err));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(template).then(
      () => alert("Template copied to clipboard!"),
      () => alert("Failed to copy text.")
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfo((prevInfo) => ({ ...prevInfo, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    postCharityShareAnalytics(
      login.user_info.charity.affiliate_id_prefix,
      info
    );
  };

  const isDisabled = !info.title || !info.description;

  return (
    <div className="email-builder-container">
      {!link && (
        <>
          <h2>Select Email Service Provider</h2>
          <div className="email-builder-button-container">
            <div
              className="email-builder-button"
              style={{
                backgroundColor:
                  serviceProvider === "MailChimp" ? "#BBBBBB" : "#e6e6e6",
              }}
              onClick={() => handleServiceProviderClick("MailChimp")}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/MailChimp.svg/800px-MailChimp.svg.png" />
            </div>
            <div
              className="email-builder-button"
              style={{
                backgroundColor:
                  serviceProvider === "ConstantContact" ? "#BBBBBB" : "#e6e6e6",
              }}
              onClick={() => handleServiceProviderClick("ConstantContact")}
            >
              <img src="https://www.constantcontact.com/blog/wp-content/uploads/2022/05/CTCT_Logo_H_Stack_FC_RGB.png" />
            </div>
            <div
              className="email-builder-button"
              style={{
                backgroundColor:
                  serviceProvider === "SendGrid" ? "#BBBBBB" : "#e6e6e6",
              }}
              onClick={() => handleServiceProviderClick("SendGrid")}
            >
              <img src="https://cdn.worldvectorlogo.com/logos/sendgrid-2.svg" />
            </div>
            <div
              className="email-builder-button"
              style={{
                backgroundColor:
                  serviceProvider === "Gmail" ? "#BBBBBB" : "#e6e6e6",
              }}
              onClick={() => handleServiceProviderClick("Gmail")}
            >
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/512px-Gmail_icon_%282020%29.svg.png" />
            </div>
          </div>
          <h3>Selected Provider: {serviceProvider}</h3>
          <TextField
            fullWidth
            margin="normal"
            label="Title for email  *Will not appear in email*"
            variant="outlined"
            name="title"
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="inherit"
            style={{
              color: isDisabled ? "grey" : "#00c7a4",
              margin: "18px 0px",
              backgroundColor: isDisabled ? "#d1d1d1" : "#6b21a8",
            }}
            onClick={() => handleSubmit()}
            disabled={isDisabled}
          >
            Submit
          </Button>
        </>
      )}

      {link && (
        <>
          <div>
            <div>
              {serviceProvider === "Gmail" ? (
                <Button
                  variant="contained"
                  color="inherit"
                  style={{
                    color: "#fff",
                    margin: "18px 0px",
                    backgroundColor: "#6b21a8",
                    width: "100%",
                  }}
                  onClick={copyDesign}
                >
                  <h3 style={{ color: "#fff" }}>COPY EMAIL</h3>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="inherit"
                  style={{
                    color: "#00c7a4",
                    margin: "18px 0px",
                    backgroundColor: "#6b21a8",
                    width: "100%",
                  }}
                  onClick={copyToClipboard}
                >
                  COPY EMAIL
                </Button>
              )}
            </div>
            <div className="link-child--graph-container">
              <EmailDisplay link={link} organizationName={organizationName} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Hoc(EmailTemplateSelector);
