import "./banner.css";

import React from "react";

class MovingText extends React.Component {
  render() {
    const textStyle: React.CSSProperties = {
      whiteSpace: "nowrap",
      display: "flex",
      animation: "marquee 20s linear infinite",
      color: "rgb(194 200 210)",
    };

    const keyframesStyle = `
      @keyframes marquee {
        from { transform: translateX(50%); }
        to { transform: translateX(-200%); }
      }
    `;

    const textContent = (
      <React.Fragment>
        <h2>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1)&nbsp;&nbsp;Saves user's 💵
        </h2>
        <h2>
          &nbsp;&nbsp;&nbsp;&nbsp;2)&nbsp;&nbsp;Helps organizations with
          fundraising ❤️&nbsp;
        </h2>
      </React.Fragment>
    );

    const elements = new Array(100).fill(textContent);
    return (
      <div className="banner-container">
        <div className="fixed-text-container">
          <img
            className="fixed-text-container-logo"
            src="https://king-prawn-app-kvphh.ondigitalocean.app/static/many-hands-assets/logo.svg"
            alt="logo"
          />
          <h2>Many Hands</h2>
        </div>

        <style>{keyframesStyle}</style>
        <div
          style={{ overflowX: "hidden", width: "100%", padding: "15px 0px" }}
        >
          <span style={textStyle}>
            {elements.map((element, index) => (
              <React.Fragment key={index}>{element}</React.Fragment>
            ))}
          </span>
        </div>
      </div>
    );
  }
}

export default MovingText;
