import "./Discounts.css";
import "swiper/swiper.min.css";

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiperSlide } from "swiper/react";
import { animated, useSpring } from "react-spring";

import { Autoplay } from "swiper";
import Button from "../../../button/SignUp";

const SlideContent: React.FC<{
  word: string;
  idx: number;
  setIndex: Function;
}> = ({ word, idx, setIndex }) => {
  const swiperSlide = useSwiperSlide();
  const isActive = swiperSlide.isActive;
  const stringMatch = isActive && word === "EVERY FUNDRAISER";
  useEffect(() => {
    if (isActive) {
      setIndex(idx);
    }
  }, [isActive]);
  return (
    <h3
      style={
        isActive
          ? {
              color: stringMatch ? "var(--dark-green)" : "#fff",
              opacity: "1",
              fontWeight: stringMatch ? "900" : "",
            }
          : { color: word === "EVERY FUNDRAISER" ? "#00c7a482" : "" }
      }
    >
      {word}
    </h3>
  );
};

interface Word {
  discounts: any;
}

const Discounts: React.FC = () => {
  const [index, setIndex] = useState<number>(0);
  const [number, setNumber] = useState<number>(0);

  const organizations = [
    "All Non-profits",
    "Schools Orgs",
    "Disaster Relief Orgs",
    "Animal Rescue Shelters",
    "Environmental Watchers",
    "Arts and Music Centers",
    "EVERY FUNDRAISER",
    "Alumni Scholarship Funds",
    "Rural Health Clinics",
    "Children's Hospitals",
    "Veteran Support Programs",
    "EVERY FUNDRAISER",
    "Historical Societies",
    "Cultural Exchange Orgs",
    "Educational Initiatives",
    "Recovery Support Groups",
    "EVERY FUNDRAISER",
    "Human Rights Activists",
    "Housing Developers",
    "Wildlife Conservation",
    "Tech Access Nonprofits",
    "EVERY FUNDRAISER",
    "Elder Care Centers",
    "Rare Disease Research",
    "Peacekeeping Missions",
    "National Parks Service",
    "EVERY FUNDRAISER",
    "Church Organizations",
    "Sports Teams",
  ];

  return (
    <div className="organization-scroll-container">
      <div className="organization-scroll-text-container">
        <div className="organization-scroll-text">
          <h3 style={{ color: "var(--dark-green)" }}>
            {" Many Hands is for".toUpperCase()}
          </h3>
          <Button
            text={`Start Fundraising`}
            link={"/fundraiser-sign-up"}
            main_color={"var(--dark-green)"}
            secondary_color={"var(--color-secondary)"}
          />
        </div>
      </div>
      <div className="organization-scroll-animation">
        <Swiper
          direction={"vertical"}
          autoplay={{ delay: 2500 }}
          slidesPerView={6.5}
          centeredSlides={true}
          loop={true}
          speed={500}
          loopFillGroupWithBlank={true}
          modules={[Autoplay]}
          autoHeight={true}
          style={{ margin: "0px" }}
        >
          {organizations.map((organization, idx) => (
            <SwiperSlide key={idx}>
              <SlideContent word={organization} idx={idx} setIndex={setIndex} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Discounts;
